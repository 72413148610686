import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddSupplierForm, AddSupplierSchema } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { Suppliers } from "../../../api/supplierService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { useNavigate } from "react-router-dom";
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { LoadingButton } from "@mui/lab";

const AddSuppliers = ({ close, UpdateSupplierList, open, toggleDrawer }) => {
  const [loading, setLoading] = useState(false);
  const { logout, auth } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();

  const fetchAddSupplier = async (formData) => {
    setLoading(true);
    let supplierService = new Suppliers();
    let response = await supplierService.postDataAuth(
      { ...formData, id: auth.user.pk },
      logout
    );
    setLoading(false);
    if (Number(response.status) < 300) {
      close();
      sendNotification(UseerrorOrsuccess(response));
      window.localStorage.setItem("menu", 18);
      navigate(`detail/${response.data.id}`);
    } else {
      sendNotification(UseerrorOrsuccess(response));
    }
    UpdateSupplierList();
    setLoading(false);
  };

  const formikAddSupplier = useFormik({
    initialValues: AddSupplierForm,
    validationSchema: Yup.object(AddSupplierSchema()),
    onSubmit: fetchAddSupplier,
  });

  return (
    <>
      <GenericDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        anchor={"right2"}
        drawerWidth="30%"
      >
        <Box p={"16px"}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "22px",
            }}
          >
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography fontSize={"20px"}>
                    <b>Agregar proveedor</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={close}>
                    <CloseSharpIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={"Nombre del proveedor"}
                variant={"outlined"}
                value={formikAddSupplier.values.business_name}
                onChange={(e) =>
                  formikAddSupplier.setFieldValue(
                    "business_name",
                    e.currentTarget.value
                  )
                }
                size="small"
                sx={{
                  width: "100%",
                  minWidth: 220,
                  height: "38px",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    component="div"
                    className="title-card"
                    sx={{ padding: "0", color: "#605D5D" }}
                  >
                    <p>Tipo de proovedor:</p>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      value={formikAddSupplier.values.type}
                      onChange={(e) => {
                        formikAddSupplier.setFieldValue("type", e.target.value);
                      }}
                      sx={{ height: "44px", color: "#605D5D" }}
                    >
                      <MenuItem value={1}>Empresa</MenuItem>
                      <MenuItem value={2}>Persona</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                loading={loading}
                fullWidth
                onClick={formikAddSupplier.handleSubmit}
                type="submit"
                variant="contained"
                sx={{ borderRadius: "40px", width: "88px" }}
              >
                Agregar
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </GenericDrawer>
    </>
  );
};

export default AddSuppliers;
