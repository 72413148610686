import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material";
import CategoryManages from "../../../../api/categoryManages";
import { useFormik } from "formik";
import { initValAddCategory } from "../../../../utils/formHelper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

export function Category(props) {
  const manageCategory = new CategoryManages();
  const [selectedOption, setSelectedOption] = useState("");
  const [showInput, setShowInput] = useState();
  const [table, setTable] = useState([]);
  const [upDate, setUpDate] = useState(false);
  const [formData, setFormData] = useState({ name: "" }); // Estado para manejar los datos del formulario
  const [formError, setFormError] = useState("");

  useEffect(() => {
    UpdateTable();
    if (props.article) {
      setSelectedOption(props.article.category?.id);
    }
  }, [upDate, props.article]);

  const UpdateTable = () => {
    (async () => {
      let response = await manageCategory.getAllAuth(props.logout);
      setTable(response?.data);
    })();
  };

  const handleCheckBox = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
    if (props.SetProductInfo && table) {
      props.SetProductInfo({ category: option });
    } else {
      props.setCategoryId(option);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!formData.name.trim()) {
      setFormError("El nombre de la categoría es obligatorio");
      return;
    }

    try {
      let response = await manageCategory.postDataAuth(formData, props.logout);

      setUpDate(!upDate);
      setFormData({ name: "" });
      setFormError("");
      setShowInput(false);
    } catch (error) {
      console.error("Error al enviar los datos", error);
    }
  };

  return (
    <>
      {table &&
        table?.map((data, index) => (
          <Grid key={index} display={"flex"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => handleCheckBox(data.id)}
                  checked={selectedOption === data.id}
                />
              }
            />
            <Typography>{data.name}</Typography>
          </Grid>
        ))}
      <Grid
        sx={{
          display: showInput ? "flex" : "none",
          alignItems: "center",
        }}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <FormControlLabel control={<Checkbox />} />
        <TextField
          size="small"
          margin="dense"
          label={"Agregar categoría"}
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          error={Boolean(formError)}
          helperText={formError}
          sx={{ mr: "8px" }}
        />
        <CloseIcon
          sx={{
            fontSize: 30,
            m: "0 8px",
            cursor: "pointer",
            color: "GrayText",
            ":hover": { color: "red" },
          }}
          onClick={() => setShowInput(false)}
        />
        <IconButton color="primary" type="submit">
          <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-start"} p={"8px 0 "}>
        <Button onClick={() => setShowInput(true)} type="submit" variant="text">
          + Crear Categoría
        </Button>
      </Grid>
    </>
  );
}
