import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import MainLogo from "../../../assets/new_main_logo.png";
import { OrderService } from "../../../api/pos/ordersService";
import ImageSize from "../../../components/pos/ImageSize";
import { useAuth } from "user-context-fran-dev";
import { useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

export const TicketPreview = (props) => {
  const newDate = new Date();
  const { data, change, cashAmount } = props;
  const { id } = useParams();
  const [orderData, setorderData] = useState("");
  const { logout } = useAuth();

  useEffect(() => {
    UpdateOrder();
  }, []);

  const UpdateOrder = () => {
    (async () => {
      let orderService = new OrderService();
      let response = await orderService.getAllByIdAuth(id, logout);
      setorderData(response.data);
    })();
  };

  return (
    <Grid>
      <Grid item xs={12}>
        {/* Header */}
        <Stack direction={"column"} alignItems="center">
          <Typography variant="h5">{data.ticket.business.name} </Typography>
          <ImageSize img={MainLogo} weight={1} />
          <Typography variant="caption">
            {" "}
            R.F.C. {data.ticket.business?.rfc}{" "}
          </Typography>
          <Typography variant="caption">
            {" "}
            Suc. {data.ticket.branch?.name}
          </Typography>
          <Typography variant="caption">
            {" "}
            {data.ticket.branch.address?.street},{" "}
            {data.ticket.branch.address?.ext_number}{" "}
          </Typography>
          <Typography variant="caption">
            {" "}
            Col {data.ticket.branch.address?.suburb}
          </Typography>
        </Stack>
      </Grid>
      <Divider></Divider>
      <Grid item xs={12}>
        {" "}
        {/* Titles table */}
        <Grid container alignContent={"end"}>
          <Grid item xs={2}>
            <Typography variant="caption">Cant</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">Artículo</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">Total</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {" "}
        {/* Table body */}
        {orderData.items &&
          orderData.items.map((article, index) => (
            <Grid container key={index}>
              <Grid item xs={1}>
                <Typography variant="subtitle2">{article.qty}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="caption">
                  {article.model.product.name}, {article.model.name},{" "}
                  {article.presentation.name}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  <CurrencyFormat
                    value={article.total}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Divider></Divider>
      <Grid container justifyContent="end">
        <Grid item xs={3}>
          <Typography variant="caption">IVA $</Typography>
        </Grid>
        <Grid item xs={4}>
          {orderData.iva ? (
            <Typography variant="caption">
              <CurrencyFormat
                value={orderData.iva}
                displayType={"text"}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          ) : (
            <Typography variant="caption">$0.00</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="end">
        <Grid item xs={3}>
          <Typography variant="subtittle1">Total M.N </Typography>
        </Grid>
        <Grid item xs={4}>
          {orderData.total ? (
            <Typography variant="caption">
              <CurrencyFormat
                value={orderData.total}
                displayType={"text"}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          ) : (
            <Typography variant="caption">0</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="end">
        <Grid item xs={3}>
          <Typography variant="caption">Efectivo $</Typography>
        </Grid>
        <Grid item xs={4}>
          {cashAmount ? (
            <Typography variant="caption">
              <CurrencyFormat
                value={cashAmount}
                displayType={"text"}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          ) : (
            <Typography variant="caption">0</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="end">
        <Grid item xs={3}>
          <Typography variant="caption">Cambio $</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">
            <CurrencyFormat
              value={change ? change : "$0.00"}
              displayType={"text"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Typography>
        </Grid>
      </Grid>

      <Divider></Divider>
      <Grid container direction={"column"} alignItems={"center"}>
        {/* <Typography>Le atendió: Victor Zamora</Typography> */}
        <Grid>
          <Typography variant="body2">{newDate.toLocaleString()} </Typography>
        </Grid>
        <Grid>
          <Typography variant="body2">SUC. {data.ticket.branch?.id}</Typography>
        </Grid>
        <Grid>
          <Typography variant="body2">Folio: 00000{data.id}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
