import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import GenericTable from "../../components/GenericTable";
import TableCell from "@mui/material/TableCell";
import { Avatar, Divider, Grid } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import CardHeader from "@mui/material/CardHeader";
import { Iniciales } from "../../utils/functions";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import Container from "../../layout/gridContainer";
import { useAuth } from "user-context-fran-dev";
import GetUserList from "../../api/userList";
import AddUsers from "./users/addUsers";
import UserRemove from "../../api/userRemove";
import { PaginationGeneric } from "react-generic-pagination";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../components/GenericEmptyTable";
import { ConfirmAction } from "../../components/ConfirmAction/ConfirmAction";
import { DateFormat } from "../../components/DateFormat";

export default function AdminUsers() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();

  const deleteUser = new UserRemove();

  //--------------------------------------
  const [open, setOpen] = useState();
  const [confirmationData, setConfirmationData] = useState(null);

  const handleOpen = (data) => {
    setConfirmationData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //---------------------------------------

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateUserList();
  }, [page]);

  const UpdateUserList = async () => {
    try {
      const irequestFilter = [
        { key: "page", value: `${page}` },
        { key: "items", value: `${totalPerPage}` },
      ];

      const getUsers = new GetUserList();
      const response = await getUsers.filter(irequestFilter, logout);

      if (response?.data) {
        const { pagination, ...tableData } = response.data;
        setTotalPages(pagination);
        setTable(tableData);
      }
    } catch (error) {
      console.error("Error al actualizar la lista de usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  /*   const UpdateUserList = () => {
      (async () => {
        let getUsers = new GetUserList();
        let response = await getUsers.getAllAuth(logout);
        setTable(response);
      })();
    }; */

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };

  const handleDeleteUser = async (id, formData) => {
    let response = await deleteUser.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    UpdateUserList();
  };

  /*   const handleChangePermits = (event) => {
      setPermits(event.target.value);
    }; */

  return (
    <Container>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        m={"24px 0 40px"}
        alignItems={"center"}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="title-card"
        >
          <strong>Usuarios</strong>
        </Typography>

        <AddUsers UpdateUserList={UpdateUserList} />
      </Grid>

      <>
        <GenericTable
          titles={[
            "Fecha de registro",
            "Nombre",
            "Correo electrónico",
            "Verificado",
          ]}
        >
          {loading && <GenericSkeletonTable />}
          {!loading && !table && <GenericEmptyTable />}
          {!loading &&
            table &&
            table?.data.map((data) => (
              <>
                <TableRow
                  key={data.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  hover
                  onDoubleClick={() => {
                    window.localStorage.setItem("menu", 8);
                    navigate(`detail/${data.id}`);
                  }}
                >
                  <TableCell align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <DateFormat beginDate={data?.date_joined} />
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontWeight: "bold",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.first_name} {data?.last_name}{" "}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ maxWidth: "350px" }} align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        minWidth: "50px",
                        maxWidth: "50vw",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.email}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ maxWidth: "350px" }} align="left">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{
                        minWidth: "50px",
                        maxWidth: "50vw",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mr: 1,
                      }}
                    >
                      {data?.email_confirmation === 0 ? "No" : "Si"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow />
              </>
            ))}
        </GenericTable>

        <Grid item xs={12} mt={2} mb={2}>
          <PaginationGeneric
            className="paginationStyle"
            page={page}
            total={totalpages}
            pageSetter={pageSetter}
          ></PaginationGeneric>
        </Grid>
      </>

      {confirmationData && (
        <ConfirmAction
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          title={`Remover accesos a usuario`}
          content={`¿Estás seguro que deseas remover accesos al usuario ${confirmationData.first_name} ${confirmationData.last_name}?`}
          disagreeText="Cancelar"
          agreeText="Remover"
          service={deleteUser}
          id={confirmationData.id}
          getCurrent={UpdateUserList}
          action={"edit"}
        />
      )}
    </Container>
  );
}
