import * as Yup from "yup";
import { SupplierType } from "../components/TablewithPagination";

const MAX_PRICE_COST = 999999.99;
const MIN_PRICE_COST = 0.01;
const FIX_PRICE_COST = 2;

const PATTER_PRICE_COST = /^\d+(\.\d{0,2})?$/;

const MAX_QUANTITY = 9999999.999;
const MIN_QUANTITY = 0.001;
const FIX_QUANTITY = 3;

const PATTER_QUANTITY = /^\d+(\.\d{0,3})?$/;

export const initValAddUser = () => {
  return {
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    is_staff: true,
  };
};

export const valSchAddUser = () => {
  return {
    email: Yup.string()
      .email("Correo no valido")
      .required("El correo es requerido"),
    password1: Yup.string()
      .max(128, "máximo de caracteres alcanzado")
      .min(8, "Usa una contraseña mayor a 8 caracteres.")
      .oneOf([Yup.ref("password2")], "Las contraseñas no coinciden")
      .required("Ingresa tu contraseña"),
    password2: Yup.string()
      .max(128, "máximo de caracteres alcanzado")
      .min(8, "Usa una contraseña mayor a 8 caracteres.")
      .oneOf([Yup.ref("password1")], "Las contraseñas no coinciden")
      .required("Confirmacion de contraseña es requerida"),
    first_name: Yup.string().required("El nombre es requerido"),
    last_name: Yup.string().required("El apellido es requerido"),
  };
};

export function initValAddCategory(data) {
  if (data) {
    return {
      name: data.name || "",
      is_active: data.is_active || false,
      display_on_store: data.display_on_store || false,
      display_on_pos: data.display_on_pos || false,
      display_on_catalog: data.display_on_catalog || false,
    };
  } else {
    return {
      name: "",
      is_active: false,
      display_on_store: false,
      display_on_pos: false,
      display_on_catalog: false,
    };
  }
}

export const valSchAddCategory = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
    is_active: Yup.boolean(),
    display_on_store: Yup.boolean(),
    display_on_pos: Yup.boolean(),
    display_on_catalog: Yup.boolean(),
  };
};

export const initValAddArticle = () => {
  return {
    name: "",

    /*       preparation_time: null,
          is_preparation_time: false, */
    category: "",
  };
};

export const valSchAddArticle = () => {
  return {
    name: Yup.string()
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    /*  .matches(
          /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
          'Insert only normal character'
        ) */ category: Yup.string().required("El campo es requerido"),
  };
};

export function initValAddBranch(data) {
  return {
    name: data.name || "",
    phone: data.phone || "",
  };
}

export const valSchAddBranch = () => {
  return {
    name: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    phone: Yup.string()
      .required("El campo es requerido")
      .max(20, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export function initValAddSupplier(id) {
  return {
    business_name: "",
    rfc: "",
    phone: "",
    email: "",
    user: id,
  };
}

export const valSchAddSupplier = () => {
  return {
    business_name: Yup.string()
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    phone: Yup.number()
      .typeError("Eso no parece un número de teléfono.")
      .positive("Un número de teléfono no puede comenzar con un signo menos")
      .integer("Un número de teléfono no puede incluir un punto decimal"),
    email: Yup.string()
      .email("correo inválido")
      .required("El campo es requerido"),
  };
};

export const AddSupplierForm = {
  business_name: "",
  type: 1,
};

export const AddSupplierSchema = () => {
  return {
    business_name: Yup.string()
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    type: Yup.mixed().oneOf(Object.keys(SupplierType).map(Number)),
  };
};

export const initValAddPurchases = () => {
  return {
    no_order: "",
    date_created: "",
    purchase: false,
    currency: 1,
    pay_form: 1,
    pay_method: 1,
    warehouse: "",
    quotation: false,
    supplier_id: "",
  };
};

export const valSchAddPurchases = () => {
  return {
    no_order: Yup.string()
      .required("El campo es requerido")
      .max(20, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    date_created: Yup.string().required("El campo es requerido"),
    warehouse: Yup.string().required("El campo es requerido"),
  };
};

export const initValAddSale = () => {
  return {
    no_order: "",
    date_created: "",
    sale: false,
    currency: 1,
    pay_form: 1,
    warehouse: "",
    pay_method: 1,
    client: "",
    quotation: false,
  };
};

export const valSchAddSale = () => {
  return {
    no_order: Yup.string()
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    date_created: Yup.string().required("El campo es requerido"),
  };
};

export const initSearchParameters = () => {
  return {
    name: "",
    category: "",
    type: "",
    supplier: "",
  };
};
export const valSearchparameters = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
    category: Yup.number(),
    type: Yup.string(),
  };
};

export const initPaymentConditions = (data) => {
  return {
    credit_days: data.credit_days || "",
    credit_limit: data.credit_limit || data?.supplier?.credit_limit || "",
    discount: data.discount || 0,
    is_discount: data.is_discount || true,
  };
};
export const valPaymentConditions = () => {
  return {
    credit_days: Yup.string().required("El campo es requerido"),

    credit_limit: Yup.string() /* .matches(/^[0-9]+$/, "Must be only digits") */
      .max(15, "Máximo 10 dígitos"),
    discount: Yup.string(),
  };
};

export const initComment = (commentData) => {
  return {
    comments: commentData?.comments ? commentData.comments : "",
    supplier: "",
    client: "",
    purchase_order: "",
    sale_order: "",
  };
};

export const valComment = () => {
  return {
    comments: Yup.string().max(250, "máximo de caracteres alcanzado"),
    supplier: Yup.string(),
    client: Yup.string(),
    purchase_order: Yup.string(),
    sale_order: Yup.string(),
  };
};

export const initValBusinessAddressInfoAdmin = (data) => {
  if (data.address) {
    return {
      street: data.address.street || "",
      ext_number: data.address.ext_number || "",
      int_number: data.address.int_number || "",
      postal_code: data.address.postal_code || "",
      suburb: data.address.suburb || "",
      state: data.address.state || "",
      city: data.address.city || "",
      street_between: data.address.street_between || "",
    };
  } else {
    return {
      street: "",
      ext_number: "",
      int_number: "",
      postal_code: "",
      suburb: "",
      state: "",
      city: "",
      street_between: "",
      business: data.id,
      supplier: "",
      branch: "",
      client: "",
    };
  }
};

export const valSchBusinessAddressAdmin = () => {
  return {
    street: Yup.string()
      .required("El campo es requerido")
      .max(200, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    ext_number: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    int_number: Yup.string().max(50, "máximo de caracteres alcanzado"),
    postal_code: Yup.string()
      .required("El campo es requerido")
      .max(11, "máximo de caracteres alcanzado"),
    suburb: Yup.string()
      .required("El campo es requerido")
      .max(300, "máximo de caracteres alcanzado"),
    state: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado"),
    city: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado"),
    street_between: Yup.string()
      .required("El campo es requerido")
      .max(500, "máximo de caracteres alcanzado"),
  };
};

export const initValBusinessAddressInfo = (data) => {
  if (data.address) {
    return {
      street: data.address.street || "",
      ext_number: data.address.ext_number || "",
      int_number: data.address.int_number || "",
      postal_code: data.address.postal_code || "",
      suburb: data.address.suburb || "",
      state: data.address.state || "",
      city: data.address.city || "",
      street_between: data.address.street_between || "",
      phone: data.address.phone || "",
      map_lat: data.address.map_lat || "",
      map_lon: data.address.map_lon || "",
    };
  } else {
    return {
      street: "",
      ext_number: "",
      int_number: "",
      postal_code: "",
      suburb: "",
      state: "",
      city: "",
      street_between: "",
      business: "",
      supplier: "",
      branch: "",
      client: "",
      phone: "",
      map_lat: "",
      map_lon: "",
    };
  }
};

export const valSchBusinessAddress = () => {
  return {
    street: Yup.string()
      .required("El campo es requerido")
      .max(29, "máximo de caracteres alcanzado 29")
      .min(2, "debe contener 1 o más caracteres"),
    ext_number: Yup.string()
      .required("El campo es requerido")
      .max(5, "máximo de caracteres alcanzado 5")
      .min(1, "debe contener 1 o más caracteres"),
    int_number: Yup.string()
      .max(5, "máximo de caracteres alcanzado 5")
      .min(1, "debe contener 1 o más caracteres"),
    postal_code: Yup.string()
      .required("El campo es requerido")
      .max(5, "máximo de caracteres alcanzado 5"),
    suburb: Yup.string()
      .required("El campo es requerido")
      .max(30, "máximo de caracteres alcanzado 30")
      .min(2, "debe contener 1 o más caracteres"),
    state: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado 50")
      .min(2, "debe contener 1 o más caracteres"),
    city: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado 50"),
    street_between: Yup.string()
      .required("El campo es requerido")
      .max(35, "máximo de caracteres alcanzado 35")
      .min(2, "debe contener 1 o más caracteres"),
    phone: Yup.string()
      .required("El campo es requerido")
      .max(10, "máximo de caracteres alcanzado 10"),
  };
};

export const initValPhonebook = (data) => {
  return {
    name: data?.name ?? "",
    email: data?.email ?? "",
    phone: data?.phone ?? "",
    supplier: "",
    client: "",
  };
};

export const valPhonebook = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
    email: Yup.string()
      .email()
      .max(50, "máximo de caracteres alcanzado")
      .required("El campo es requerido"),
    phone: Yup.string().max(10, "máximo de caracteres alcanzado"),
    /* value: Yup.string(), */
    supplier: Yup.number(),
    client: Yup.string(),
  };
};

export const initValBusinessProfile = (data) => {
  return {
    name: data.name || data.business_name || "",
    turn_business: data.turn_business || "",
    phone: data.phone || "",
    email: data.email || "",
  };
};

export const valSchBusinessProfile = () => {
  return {
    phone: Yup.string()
      .required("El campo es requerido")
      .max(20, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    email: Yup.string()
      .email("correo inválido")
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValBusinessSocialMedia = (data) => {
  return {
    facebook: data.facebook || "",
    instagram: data.instagram || "",
    youtube: data.youtube || "",
    whatsapp: data.whatsapp || "",
    linkedin: data.linkedin || "",
    tiktok: data.tiktok || "",
  };
};

export const valSchBusinessSocialMedia = () => {
  return {
    facebook: Yup.string()
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    instagram: Yup.string()
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    youtube: Yup.string()
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    whatsapp: Yup.string()
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    linkedin: Yup.string()
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    tiktok: Yup.string()
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValBusinessTax = (data) => {
  return {
    business_name: data.business_name || "",
    rfc: data.rfc || "",
  };
};

export const valSchBusinessTax = () => {
  return {
    business_name: Yup.string()
      .required("El campo es requerido")
      .max(250, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    rfc: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValBranchContact = (data) => {
  return {
    phone: data.phone || "",
  };
};

export const valSchBranchContact = () => {
  return {
    phone: Yup.string()
      .max(20, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValSchedule = (data, id) => {
  const today = new Date();
  if (data) {
    return {
      open_time: Date.parse(`${today.getFullYear()} ${data.open_time}`) || null,
      close_time:
        Date.parse(`${today.getFullYear()} ${data.close_time}`) || null,
      branch: id,
    };
  } else {
    return {
      open_time: "",
      close_time: "",
      branch: id,
    };
  }
};

export const valSchSchedule = () => {
  return {
    open_time: Yup.string()
      .required("El campo es requerido")
      .max(25, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    close_time: Yup.string()
      .required("El campo es requerido")
      .max(25, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValDataArticle = (data) => {
  return {
    name: data.name || "",
    category: data.category ? data.category.id : "",
    sku: data.sku || "",
    type: data.type || "",
    user: data.user || "",
    description: data.description ? data.description.id : "Escribe aqui",
    is_active: data.is_active || false,
  };
};

export const valSchDataArticle = () => {
  return {
    name: Yup.string()
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    category: Yup.string().required("El campo es requerido"),
  };
};

export const initValFilters = (data) => {
  return {
    category: data.category ? data.category.id : "",

    type: data.type || "",
  };
};

export const valSchFilters = () => {
  return {
    category: Yup.string().required("El campo es requerido"),
  };
};

export const initValDataArticleRules = (data) => {
  return {
    discount: data.discount || null,
    is_preparation_time: data.is_preparation_time || false,
    preparation_time: data.preparation_time || null,
    user: data.user || "",
    display_on_store: data.display_on_store || false,
    display_on_pos: data.display_on_pos || false,
    display_on_catalog: data.display_on_catalog || false,
    discount_pos: data.discount_pos || false,
    discount_store: data.discount_store || false,
  };
};

export const valSchDataArticleRules = () => {
  return {
    discount: Yup.number()
      .nullable(true)
      .min(1, "El descuento no puede ser menor que 1%")
      .max(100, "El descuento no puede ser mayor que 100%"),
  };
};

export const initValAddModel = () => {
  return {
    name: "",
    product: "",
  };
};

export const valSchAddModel = () => {
  return {
    name: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValAddPresentation = (data, modelId, productId, type) => {
  return {
    name: data ? data.name : type === 3 ? " " : "--",
    price: data ? data.price : "",
    cost: data ? data.cost : "",
    model: modelId ? modelId : modelId,
    barcode: data ? data.barcode : "",
    is_active: data ? data.is_active : false,
    product: productId,
    discount: data ? data.discount : 0,
    is_discount: data ? data.is_discount : false,
    discount_pos: data ? data.discount_pos : false,
    discount_store: data ? data.discount_store : false,
  };
};

export const valSchAddPresentation = () => {
  return {
    name: Yup.string()
      .required("El campo es requerido")
      .max(50, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
    cost: Yup.number()
      .max(MAX_PRICE_COST, "Máximo de caracteres alcanzado")
      .min(MIN_PRICE_COST, "Mínimo " + MIN_PRICE_COST)
      .required("El campo es requerido"),
    price: Yup.number()
      .required("El campo es requerido")
      .max(MAX_PRICE_COST, "Máximo de caracteres alcanzado")
      .min(MIN_PRICE_COST, "Mínimo " + MIN_PRICE_COST),
  };
};

export const initValArticleInfo = (data) => {
  const type = ["Almacenable", "Servicio", "Consumible"];
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(data.date_created);

  return {
    preparation_time: data.preparation_time
      ? data.preparation_time + " días"
      : "--" || "",
    type: type[data.type - 1] || "",
    date_created:
      `${Month[dia.getMonth()]} ${dia.getDate()} del ${dia.getFullYear()}` ||
      "",
    description: data.description || "",
  };
};

export const initValRecivedPurchase = (data, wh) => {
  return {
    warehouse: wh.id || wh,
    ordered: data.id,
    barcode: null,
    quantity: parseFloat(data.quantity_pending).toFixed(FIX_QUANTITY),
    cost: parseFloat(data.cost).toFixed(FIX_PRICE_COST),
  };
};

export const valSchRecivedPurchase = (data) => {
  return {
    quantity: Yup.number()
      .required("El campo es requerido")
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .max(data.quantity_pending, "Máximo " + data.quantity_pending)
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
    cost: Yup.number()
      .test("Es decimal", "Máximo dos dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_PRICE_COST.test(val);
        }
        return true;
      })
      .max(MAX_PRICE_COST, "Máximo alcanzado")
      .min(MIN_PRICE_COST, "Mínimo " + MIN_QUANTITY)
      .required("El campo es requerido"),
  };
};

export const initValRecivedTransfer = (data) => {
  let max = "0";
  if (data.pending_deliver > 0) {
    max = data.pending_deliver;
  } else if (data.pending_receive > 0) {
    max = data.pending_receive;
  }

  return {
    transfer_id: "",
    quantity: parseFloat(max).toFixed(FIX_QUANTITY),
  };
};

export const valSchRecivedTransfer = (data) => {
  let max = "0";
  if (data.pending_deliver > 0) {
    max = data.pending_deliver;
  } else if (data.pending_receive > 0) {
    max = data.pending_receive;
  }
  return {
    quantity: Yup.number()
      .required("El campo es requerido")
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .max(max, "El monto no puede ser mayor al pendiente")
      .min(MIN_QUANTITY, "mínimo uno "),
  };
};

export const initAddArticleToPurchaseOrder = (data, product) => {
  return {
    barcode: "",
    purchase: "",
    sale: "",
    adjustment_order: "",
    scrap_order: "",
    transfer_order: "",
    presentation: "",
    model: " ",
    warehouse: "",
    quantity: product?.quantity ? parseInt(product.quantity) : "",
    cost: data?.cost ? parseFloat(data?.cost).toFixed(FIX_PRICE_COST) : "",
    price: data?.price ? parseFloat(data?.price).toFixed(FIX_PRICE_COST) : "",
  };
};

export const valSchAddToPurchaseOrder = () => {
  return {
    purchase: Yup.string(),
    presentation: Yup.string(),
    model: Yup.string(),
    price: Yup.number()
      .test("Es decimal", "Máximo dos dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_PRICE_COST.test(val);
        }
        return true;
      })
      .max(MAX_PRICE_COST, "Máximo " + MAX_PRICE_COST)
      .min(MIN_PRICE_COST, "Mínimo " + MIN_PRICE_COST),
    cost: Yup.number()
      .test("Es decimal", "Máximo dos dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_PRICE_COST.test(val);
        }
        return true;
      })
      .max(MAX_PRICE_COST, "Máximo " + MAX_PRICE_COST)
      .min(MIN_PRICE_COST, "Mínimo " + MIN_PRICE_COST),
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(MAX_QUANTITY, "Máximo " + MAX_QUANTITY)
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initOrderPurchaseANDSales = (data, type) => {
  if (type === "purchase") {
    return {
      quantity: data?.quantity
        ? parseFloat(data?.quantity).toFixed(FIX_QUANTITY)
        : "",
      cost: data?.cost ? parseFloat(data?.cost).toFixed(FIX_PRICE_COST) : "",
      purchase: "",
    };
  } else {
    return {
      quantity: data?.quantity
        ? parseFloat(data?.quantity).toFixed(FIX_QUANTITY)
        : "",
      price: data?.price ? parseFloat(data?.price).toFixed(FIX_PRICE_COST) : "",
      sale: "",
    };
  }
};

export const valSchOrderPurchaseANDSales = (type, row) => {
  if (type === "purchase") {
    return {
      quantity: Yup.number()
        .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
          if (val !== undefined) {
            return PATTER_QUANTITY.test(val);
          }
          return true;
        })
        .required("El campo es requerido"),
    };
  } else {
    return {
      quantity: Yup.number()
        .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
          if (val !== undefined) {
            return PATTER_QUANTITY.test(val);
          }
          return true;
        })
        .required("El campo es requerido")
        .max(row.available, "La cantidad no puede ser mayor al pendiente"),
      price: Yup.number()
        .test("Es decimal", "Máximo dos dígitos después del punto", (val) => {
          if (val !== undefined) {
            return PATTER_PRICE_COST.test(val);
          }
          return true;
        })
        .required("El campo es requerido"),
    };
  }
};

export const initAddArticleToSalesOrder = (data) => {
  return {
    purchase: "",
    sale: "",
    presentation: "",
    model: "",
    quantity: "",
    cost: data?.cost,
  };
};

export const valSchAddToSalesOrder = () => {
  return {
    purchase: Yup.string(),
    presentation: Yup.string(),
    model: Yup.string(),
    cost: Yup.string().required("El campo es requerido"),
    quantity: Yup.number().required("El campo es requerido"),
  };
};

export const initAddScrapArticle = () => {
  return {
    scrap_order: "",
    available: "",
    quantity: "",
  };
};

export const valSchAddScrapArticle = () => {
  return {
    scrap_order: Yup.string(),
    available: Yup.string(),
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(MAX_QUANTITY, "Máximo " + MAX_QUANTITY)
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initAddTransferArticle = () => {
  return {
    transfer_order: "",
    available: "",
    presentation: "",
    model: "",
    quantity: "",
  };
};

export const valSchAddTransferArticle = () => {
  return {
    transfer_order: Yup.string(),
    available: Yup.string(),
    presentation: Yup.string(),
    model: Yup.string(),
    quantity: Yup.string().required(),
  };
};

export const initAddAdjustmentArticle = () => {
  return {
    available: "",
    quantity: "",
    adjustment_order: "",
  };
};

export const valSchAddAdjustmentArticle = () => {
  return {
    available: Yup.string(),
    quantity: Yup.string(),
    adjustment_order: Yup.string(),
  };
};

export const initValSalesDelivered = (article) => {
  return {
    reserved: article?.id,
    quantity: parseFloat(article?.quantity) || 0,
  };
};

export const valSchSalesDelivered = (available, quantity_pending) => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(
        parseFloat(quantity_pending) || available,
        "máximo alcanzado"
      ) /* La cantidad no puede ser mayor al disponible o al pendiente */
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initAddRecipe = (form, isEdit) => {
  if (form) {
    return {
      quantity: "",
      user: form.user,
      presentation: form.presentation,
      model: form.model,
      recipe_group: form.recipe_group,
    };
  } else {
    return {
      quantity: parseFloat(isEdit.quantity).toFixed(FIX_QUANTITY) || "",
      /*       user: isEdit.user ,
            presentation: isEdit.presentation,
            model: isEdit.model.id ,
            recipe_group: isEdit.recipe_group, */
    };
  }
};
export const valSchAddRecipe = () => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(MAX_QUANTITY, "Máximo " + MAX_QUANTITY)
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValProductionOrder = () => {
  return {
    no_order: "",
    warehouse: "",
    date_created: "",
  };
};
export const valSchProductionOrder = () => {
  return {
    no_order: Yup.string().required("El campo es requerido"),
    date_created: Yup.string().required("El campo es requerido"),
  };
};

export const initValProductionOrderRecipe = (data, recipe) => {
  return {
    quantity: "",
    production_order: data.data.id,
    recipe: recipe.id,
  };
};
export const valSchProductionOrderRecipe = () => {
  return {
    quantity: Yup.number("Numero")
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(MAX_QUANTITY, "máximo alcanzado")
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initTransfers = () => {
  const today = new Date();

  return {
    date: today || null,
    warehouse_source: "",
    warehouse_destiny: "",
    type: "1",
  };
};

export const valTransfers = () => {
  return {
    warehouse_source: Yup.string().required("El campo es requerido"),
    warehouse_destiny: Yup.string().required("El campo es requerido"),
    /* type: Yup.string() */
  };
};

export const initScrap = () => {
  return {
    justification: "",
    warehouse: "",
  };
};

export const ValScrap = () => {
  return {
    justification: Yup.string().required("El campo es requerido"),
    warehouse: Yup.string().required("El campo es requerido"),
  };
};

export const initAdjustment = () => {
  return {
    reference: "",
    warehouse: "",
  };
};

export const ValAdjustment = () => {
  return {
    reference: Yup.string().required("El campo es requerido"),
    warehouse: Yup.string().required("El campo es requerido"),
  };
};

export const initProductionReceived = (data, orden) => {
  return {
    warehouse: orden.warehouse.id || "",
    quantity: data?.quantity_pending
      ? parseFloat(data?.quantity_pending).toFixed(FIX_QUANTITY)
      : 0,
    production: data.id || "",
    done: "",
    barcode: "",
  };
};

export const ValProductionReceived = (data) => {
  return {
    quantity: Yup.number()
      .required("El campo es requerido")
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .max(
        data?.quantity_pending,
        "La cantidad no puede ser mayor al pendiente"
      ),
  };
};

export function initValAddWareHouse(data) {
  if (data) {
    return {
      name: data.name || "",
      description: data.description || "",
    };
  } else {
    return {
      name: "",
      description: "",
    };
  }
}
export const initAddBusiness = () => {
  return {
    name: "",
    turn_business: "",
    phone: "",
    email: "",
  };
};
export const ValAddBusiness = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
    turn_business: Yup.string().required("El campo es requerido"),
    phone: Yup.number().required("El campo es requerido"),
    email: Yup.string()
      .email("correo inválido")
      .required("El campo es requerido"),
  };
};

export const valSchAddWareHouse = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
    description: Yup.string().required("El campo es requerido"),
  };
};

export const initValProductionOrderEdit = (data) => {
  return {
    quantity: parseFloat(data).toFixed(FIX_QUANTITY) || "",
  };
};
export const valSchProductionOrderEdit = () => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(
        MAX_QUANTITY,
        "máximo alcanzado"
      ) /* La cantidad no puede ser mayor al disponible o al pendiente */
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValSettingsGeneric = (data) => {
  return {
    iva: data.iva,
    is_iva: data.is_iva,
  };
};
export const valSchSettingsGeneric = () => {
  return {
    iva: Yup.number("Numero")
      .typeError("Solo numeros")
      .integer("cantidad debe ser un número entero")
      .required("El campo es requerido"),
  };
};

export const initValDiscount = () => {
  return {
    type: "1",
    amount: "",
  };
};
export const valSchDiscount = () => {
  return {
    type: Yup.string().required("El campo es requerido"),
    amount: Yup.number("Numero")
      .min(1, "Mínimo 1")
      .typeError("Solo numeros")
      .integer("cantidad debe ser un número entero")
      .required("El campo es requerido"),
  };
};

export const initValTicket = (data) => {
  return {
    display_RFC_on_ticket: data.display_RFC_on_ticket || false,
    display_branch_address_on_ticket:
      data.display_branch_address_on_ticket || false,
    display_branch_name_on_ticket: data.display_branch_name_on_ticket || false,
    ticket_footnote: data.ticket_footnote || "",
  };
};
export const valSchTicket = () => {
  return {
    display_RFC_on_ticket: Yup.boolean(),
    display_branch_address_on_ticket: Yup.boolean(),
    display_branch_name_on_ticket: Yup.boolean(),
    ticket_footnote: Yup.string(),
  };
};

export const initValAdjustmentsAdd = (data, model) => {
  return {
    adjustment_order: data.id,
    quantity: "",
    barcode: "",
    presentation: "",
    warehouse: data.warehouse.id,
    model: model.id,
  };
};
export const valSchAdjustmentsAdd = () => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido"),
    presentation: Yup.string().required("El campo es requerido"),
  };
};

export const initValSettingContact = (data) => {
  return {
    email_host: data?.email_host ? data.email_host : "",
    email_notify: data?.email_notify ? data.email_notify : "",
    email_password: data?.email_password ? data.email_password : "",
    email_port: data?.email_port ? data.email_port : "",
  };
};

export const valSettingContact = () => {
  return {
    email_host: Yup.string().max(100, "máximo de caracteres alcanzado"),
    email_notify: Yup.string().email("correo inválido"),
    email_password: Yup.string().max(100, "máximo de caracteres alcanzado"),
    email_port: Yup.string().max(100, "máximo de caracteres alcanzado"),
  };
};

export const initValBranchName = (data) => {
  return {
    name: data?.name || "",
    phone: data?.phone || "",
  };
};

export const valSchBranchName = () => {
  return {
    name: Yup.string()
      .max(50, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValResetPass = (uid, token) => {
  return {
    new_password1: "",
    new_password2: "",
    uid: uid,
    token: token,
  };
};

export const valSchResetPass = () => {
  return {
    new_password1: Yup.string()
      .max(128, "máximo de caracteres alcanzado")
      .oneOf([Yup.ref("new_password2")], "Las contraseñas no coinciden")
      .required("Ingresa tu contraseña"),
    new_password2: Yup.string()
      .max(128, "máximo de caracteres alcanzado")
      .oneOf([Yup.ref("new_password1")], "Las contraseñas no coinciden")
      .required("Confirmar la contraseña"),
  };
};

export const initValSettingDomain = (data) => {
  return {
    domain: data?.domain ? data.domain : "",
  };
};

export const valSettingDomain = () => {
  return {
    domain: Yup.string().max(100, "máximo de caracteres alcanzado"),
  };
};

export const initValCallToAction = () => {
  return {
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    is_staff: true,
  };
};

export const valSchCallToAction = () => {
  return {
    email: Yup.string()
      .email("correo inválido")
      .required("El campo es requerido"),
    password1: Yup.string()
      .max(128, "máximo de caracteres alcanzado")
      .oneOf([Yup.ref("password2")], "Las contraseñas no coinciden")
      .required("Ingresa tu contraseña"),
    password2: Yup.string()
      .max(128, "máximo de caracteres alcanzado")
      .oneOf([Yup.ref("password1")], "Las contraseñas no coinciden")
      .required("Confirmar la contraseña"),
    first_name: Yup.string().required("El campo es requerido"),
    last_name: Yup.string().required("El campo es requerido"),
  };
};

export const initValCallToActionHome = (data) => {
  return {
    call_to_action_title: data?.call_to_action_title || "",
    call_to_action_url: data?.call_to_action_url || "",
    image: data?.image || "",
    subtitle: data?.subtitle || "",
    title: data?.title || "",
  };
};

export const valSchCallToActionHome = () => {
  return {
    title: Yup.string().required("El campo es requerido"),
    subtitle: Yup.string().required("El campo es requerido"),
  };
};

export const initValIntroLanding = (data) => {
  return {
    call_to_action_title: data?.call_to_action_title || "",
    call_to_action_url: data?.call_to_action_url || "",
    /* display: data?.display || "", */
    description: data?.description || "",
    title: data?.title || "",
  };
};

export const valSchIntroLanding = () => {
  return {
    title: Yup.string().required("El campo es requerido"),
    description: Yup.string().required("El campo es requerido"),
  };
};

export const initValTransferOrder = (data, row) => {
  return {
    transfer_order: data.id,
    quantity: row?.quantity
      ? parseFloat(row?.quantity).toFixed(FIX_QUANTITY)
      : "" /*  row.quantity */,
    available: row.available.id,
  };
};

export const valSchTransferOrder = () => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(MAX_QUANTITY, "Máximo " + MAX_QUANTITY)
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValStoreQuote = (data) => {
  return {
    description: "",
    contentValue: data?.total || 0,
    weight: "",
    length: "",
    height: "",
    width: "",
  };
};

export const valSchStoreQuote = () => {
  return {
    description: Yup.string()
      .required("El campo es requerido")
      .max(28, "máximo de caracteres alcanzado")
      .min(3, "debe contener 3 o más caracteres"),
    weight: Yup.number()
      .required("El campo es requerido")
      .positive("Error numeros negativos")
      .max(1000, "Máximo 1000")
      .min(1, "Mínimo 1"),
    length: Yup.number()
      .required("El campo es requerido")
      .positive("Error numeros negativos")
      .max(300, "Máximo 300")
      .min(1, "Mínimo 1"),
    height: Yup.number()
      .required("El campo es requerido")
      .positive("Error numeros negativos")
      .max(300, "Máximo 300")
      .min(1, "Mínimo 1"),
    width: Yup.number()
      .required("El campo es requerido")
      .positive("Error numeros negativos")
      .max(300, "Máximo 300")
      .min(1, "Mínimo 1"),
  };
};

export const initValStoreRulesDelivery = (data) => {
  return {
    is_shipping_paid_by_client: data.is_shipping_paid_by_client || false,
    is_free_shipping_activated: data.is_free_shipping_activated || false,
    free_shipping_threshold: data.free_shipping_threshold || "",
    fixed_shipping_cost: data.fixed_shipping_cost || "",
  };
};

export const valSchStoreRulesDelivery = () => {
  return {
    is_shipping_paid_by_client: Yup.boolean(),
    is_free_shipping_activated: Yup.boolean(),
    fixed_shipping_cost: Yup.number(),
    free_shipping_threshold: Yup.number(),
  };
};

export const initValEnvioclicKey = (data) => {
  return {
    envioclick_key: data.envioclick_key || "",
  };
};

export const valSchEnvioclicKey = () => {
  return {
    envioclick_key: Yup.string()
      .required("El campo es requerido")
      .max(255, "máximo de caracteres alcanzado"),
  };
};

export const initValClip = (data) => {
  return {
    clip_key: data.clip_key || "",
  };
};

export const valSchClip = () => {
  return {
    clip_key: Yup.string()
      .required("El campo es requerido")
      .max(255, "máximo de caracteres alcanzado"),
  };
};

export const initSecetKey = () => {
  return {
    secret_key: "",
  };
};

export const valSecetKey = () => {
  return {
    secret_key: Yup.string()
      .required("El campo es requerido")
      .max(100, "máximo de caracteres alcanzado"),
  };
};

export const initValBranchWarehouse = (data) => {
  return {
    name: data?.name || "",
    sell_products_online: data?.sell_products_online || false,
  };
};

export const valSchBranchWarehouse = () => {
  return {
    name: Yup.string()
      .max(100, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres"),
  };
};

export const initValDescription = (data) => {
  return {
    description: data.description || "",
  };
};

export const valSchDescription = () => {
  return {
    description: Yup.string()
      .max(160, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres")
      .required("El campo es requerido"),
  };
};

export const initValKeyWord = () => {
  return {
    name: "",
  };
};

export const valSchKeyWord = () => {
  return {
    name: Yup.string()

      .max(160, "máximo de caracteres alcanzado")
      .min(1, "debe contener 1 o más caracteres")
      .required("El campo es requerido"),
  };
};

export const initValWareHouseProduction = (data, row) => {
  return {
    quantity: data?.quantity_pending
      ? parseFloat(data.quantity_pending).toFixed(FIX_QUANTITY)
      : "",
    production: data.id,
    available: row.id,
  };
};

export const valSchWareHouseProduction = (data) => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(
        parseFloat(data.quantity_pending) || parseFloat(data.quantity),
        "máximo alcanzado"
      ) /* La cantidad no puede ser mayor al disponible o al pendiente */
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValInventoryAdjustment = (dataAdjustmentid, rowid) => {
  return {
    quantity: 0,
    adjustment_order: dataAdjustmentid,
    available: rowid,
  };
};

export const valSchInventoryAdjustment = () => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(MAX_QUANTITY, "máximo alcanzado")
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValInventoryTransfers = (data, row) => {
  return {
    quantity: "",
    transfer_order: data,
    available: row.id,
    presentation: row.presentation.id,
    model: row.model.id,
  };
};

export const valSchInventoryTransfers = (row) => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(
        parseFloat(row.quantity),
        "máximo alcanzado"
      ) /* La cantidad no puede ser mayor al disponible o al pendiente */
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValAddRaw = () => {
  return {
    quantity: "",
  };
};

export const valSchAddRaw = () => {
  return {
    quantity: Yup.number()
      .test("Es decimal", "Máximo tres dígitos después del punto", (val) => {
        if (val !== undefined) {
          return PATTER_QUANTITY.test(val);
        }
        return true;
      })
      .required("El campo es requerido")
      .max(
        MAX_QUANTITY,
        "máximo alcanzado"
      ) /* La cantidad no puede ser mayor al disponible o al pendiente */
      .min(MIN_QUANTITY, "Mínimo " + MIN_QUANTITY),
  };
};

export const initValCreatorSection = (req, edit) => {
  let list = {};

  for (var key in req) {
    if (key !== "type") {
      if (edit) {
        if (key === "image") {
          list[key] = edit[key] ? edit[key].id : null;
        } else {
          list[key] = edit[key];
        }
      } else {
        if (key.startsWith("link")) {
          list.link = 1;
        } else if (key.startsWith("label")) {
          list.label_active = false;
          list.label = "Etiqueta nueva";
          list.label_color = "#BFA8D8";
        } else if (key.startsWith("image")) {
          list.image_scale = 1;
          list.image_transform_x = 0;
          list.image_transform_y = 0;
        } else if (key.startsWith("button")) {
          list.button_active = false;
          list.button_action = 1;
          list.button_title = "Llamada a la acción";
        } else {
          list[key] = "";
        }
      }
    }
  }

  return list;
};

export const valSchCreatorSection = (req) => {
  let list = {};
  for (var key in req) {
    if (key !== "type") {
      list[key] = Yup.string().required("El campo es requerido");

      if (key === "image") {
        list[key] = Yup.string().required("El campo es requerido");
      }
      if (key === "title") {
        list[key] = Yup.string().required("El campo es requerido");
        // .max(50, "máximo de caracteres alcanzado")
      }
      if (key === "subtitle") {
        list[key] = Yup.string().required("El campo es requerido");
        // .max(500, "máximo de caracteres alcanzado")
      }
      if (key === "description") {
        list[key] = Yup.string().required("El campo es requerido");
        //  .max(2000, "máximo de caracteres alcanzado")
      }
      if (key === "button_title") {
        list[key] = Yup.string()
          .required("El campo es requerido")
          .max(50, "máximo de caracteres alcanzado")
          .default("Botón sin valor"); // Establecer valor predeterminado en Yup
      }
      if (key === "label") {
        list[key] = Yup.string()
          .required("El campo es requerido")
          .max(50, "máximo de caracteres alcanzado")
          .default("Etiqueta sin valor"); // Establecer valor predeterminado en Yup
      }
      if (key === "component") {
        list[key] = Yup.string();
      }

      /*      if(key == "image"){
             list[key] = Yup.string()
           } */
    }
  }

  return list;
};

export const initValBackground = (data) => {
  return {
    bg_image: data.component?.bg_image?.id || "",
    bg_image_overlay: data.component?.bg_image_overlay || false,
    bg_image_overlay_alpha: data.component?.bg_image_overlay_alpha || "0",
    bg_image_overlay_color: data.component?.bg_image_overlay_color || "#ffffff",
    bg_type: 3,
    justify_content: data.component?.justify_content || "left",
    title: data.component?.title || "",
  };
};

export const valSchBackground = () => {
  return {
    bg_image: Yup.string().required("La imagen es requerida"),
  };
};

export const initAddCashRegister = () => {
  return {
    branch: "",
    warehouse: "",
    amount: "",
    reason: "Caja Abierta",
    type: "1",
  };
};

export const ValAddCashRegister = () => {
  return {
    branch: Yup.string(),
    warehouse: Yup.string(),
    amount: Yup.string(),
    reason: Yup.string(),
    type: Yup.string(),
  };
};

export function initValAddFilter(data) {
  if (data) {
    return {
      name: data.name || "",
    };
  }
  return {
    name: "",
  };
}

export const valSchAddFilter = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
  };
};

export function initValAddLabel(data, id) {
  if (data) {
    return {
      name: data.name || "",
      filter: id,
    };
  }
  return {
    name: "",
    filter: id,
  };
}

export const valSchAddLabel = () => {
  return {
    name: Yup.string().required("El campo es requerido"),
  };
};
