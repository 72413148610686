import { BASE_PATH } from "../utils/constants";
import { BaseService } from "base-service-fran-dev";
import axios from "axios";
import { useAuth } from "user-context-fran-dev";

export class BusinessGroups extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "site/business_groups";
    super(baseUrl, endPoint);
  }
}

export class BusinessGroupsAllowed extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "site/business_allowed_groups";
    super(baseUrl, endPoint);
  }
}

export function AuxAxios(method, dt, endPoint, token) {
  /*  const {auth } = useAuth(); */
  /* const token = auth.token; */

  const URL = `${BASE_PATH}/${endPoint}`;
  const res = axios({
    method: method,
    url: URL,
    headers: {
      "Content-Type": "application/json",
      authorization: `jwt ${token}`,
    },
    data: dt,
  }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }
    console.error(error.config);
  });
}
