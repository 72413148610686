import { Typography, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { Grid2 } from "@mui/material";
import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import MainLogo from "../assets/new_main_logo.png";
import { useTheme } from "@mui/styles";

const styleFooter = {
  /*         backdropFilter: "blur(7px) saturate(180%)",
            webkitBackdropFilter: "blur(7px) saturate(180%)",
            backgroundColor: "rgba(209, 209, 209, 0.48)",
             */

  backgroundColor: "background.header",
  bottom: "0",
  width: "100%",
  height: "32px",
  marginTop: "32px",
};

const Footer = () => {
  const theme = useTheme();

  return (
    <>
      <footer
        style={{
          bottom: "0",
          width: "100%",
          backgroundColor: "#00aec7",
        }}
      >
        <Grid2
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ display: "flex", padding: "8px" }}
        >
          <Grid2>
            <img
              src={MainLogo}
              style={{ width: "24px", height: "24px", padding: "5px" }}
            ></img>
          </Grid2>
          <Grid2>
            <Grid2
              container
              justifyContent="center"
              alignItems="center"
              padding={"8px"}
            >
              <Typography color={"white"}>
                Powered by CodeNation Studio
              </Typography>
              <Typography color={"white"} paddingLeft="8px">
                Keskinube v1.0.0
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </footer>
    </>
  );
};

export default Footer;
