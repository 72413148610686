import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAuth } from "user-context-fran-dev";
import Switch from "@mui/material/Switch";
import {
  BusinessGroupsAllowed,
  BusinessGroups,
  AuxAxios,
} from "../../../api/businessGroups";

export default function Step2() {
  const [acces, setAcces] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const { logout, auth } = useAuth();

  const UpdateAppAcces = () => {
    (async () => {
      let getBranchs = new BusinessGroups();
      let response = await getBranchs.getAllAuth(logout);
      setAcces(response.data);
    })();
  };

  const GetCurrentUser = () => {
    (async () => {
      const getUser = new BusinessGroupsAllowed();

      const response = await getUser.getAllAuth(logout);
      setCurrentUser(response.data);
    })();
  };

  useEffect(() => {
    GetCurrentUser();
    UpdateAppAcces();
  }, []);

  return (
    <>
      {acces && currentUser && (
        <FormControl component="fieldset" variant="standard">
          {currentUser.map((data, index) => (
            <Switches data={acces} user={data} key={index} />
          ))}
        </FormControl>
      )}
    </>
  );
}

const Switches = ({ data, user }) => {
  const [state, setState] = useState(false);
  const { logout, auth } = useAuth();

  useEffect(() => {
    if (user.display_name) {
      const state =
        data.find((group) => user.id === group.id) !== undefined ? true : false;

      /* setHidden(auth.user.pk== user.id) */
      setState(state);
    }
  }, []);

  const SetGroup = async () => {
    let setGroup = new BusinessGroupsAllowed();
    let response = await setGroup.postDataAuth({ name: user.name }, logout);
  };
  const DeleteGroup = async () => {
    let response = AuxAxios(
      "delete",
      { name: user.name },
      "site/business_allowed_groups",
      auth.token
    );
  };

  const handleChanges = () => {
    if (!state) {
      SetGroup();
    } else {
      DeleteGroup();
    }
    setState(!state);
  };

  const list = [
    "Administracióssn",
    "Landing",
    "Catálogo",
    "Tienda",
    "Inventario",
    "Punto de venta",
    "Pos view",
  ];
  return (
    <FormControlLabel
      className="switch"
      sx={{ color: "text.primary" }}
      control={
        <Switch
          checked={state}
          onChange={() => {
            handleChanges();
          }}
          name=""
        />
      }
      label={list[user.id - 1]}
    />
  );
};
