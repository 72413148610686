import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValProductionOrder,
  valSchProductionOrder,
} from "../../../utils/formHelper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import WarehouseService from "../../../api/warehouseService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ProductionOrder } from "../../../api/productionOrder";
import { useAuth } from "user-context-fran-dev";
import CountWords from "../../../components/CountWords";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { formatISO } from "date-fns";

const AddTransformation = ({ open, setOpen, update, idOrder }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [warehouseList, setWarehouseList] = useState("");
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();

  /*   useEffect(()=>{
    return ()=>{
    }
  },[]); */
  useEffect(() => {
    warehouseListRequest();

    return () => {
      formik.resetForm();
    };
  }, [open]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const warehouseListRequest = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ key: "get_main", value: `true` });
      const warehouseService = new WarehouseService();
      const response = await warehouseService.filter(irequestFilter, logout);
      setWarehouseList(response.data);
    })();
  };

  const formik = useFormik({
    initialValues: initValProductionOrder(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchProductionOrder()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      formData.no_order = formData.no_order + idOrder.toString();

      formData.date_created = formatISO(formData.date_created);

      let addProduction = new ProductionOrder();
      let response = await addProduction.postDataAuth(formData, logout);
      sendNotification(UseerrorOrsuccess(response));
      if (response) {
        window.localStorage.setItem("menu", 15);
        navigate(`detail/${response.data.id}`);
        setOpen(false);
      }

      update();
      resetForm();
      setLoading(false);
    },
  });

  return (
    <>
      <BasicModal
        open={open}
        setOpen={setOpen}
        title={"Agregar orden de producción"}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                mt={"16px"}
                rowSpacing={"8px"}
                columnSpacing={"8px"}
              >
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="none"
                    size="small"
                    id="no_order"
                    label="Número de orden"
                    value={formik.values.no_order}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.no_order && Boolean(formik.errors.no_order)
                    }
                    helperText={
                      formik.touched.no_order ? formik.errors.no_order : ""
                    }
                  />
                  <CountWords
                    length={formik.values.no_order.length}
                    max="100"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoComplete="none"
                    size="small"
                    id="no_order"
                    label="Número Consecutivo"
                    value={idOrder}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-filled-label">
                      Almacén
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      name="warehouse"
                      label="Almacén"
                      value={formik.values.warehouse}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.warehouse)
                      }
                    >
                      {warehouseList &&
                        warehouseList.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.warehouse ? formik.errors.warehouse : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => (
                      <TextField fullWidth size="small" {...props} />
                    )}
                    label="Fecha"
                    value={formik.values.date_created}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "date_created",
                        Date.parse(newValue)
                      );
                    }}
                    helperText={
                      formik.touched.date_created
                        ? formik.errors.date_created
                        : ""
                    }
                    format="dd/MM/yyyy"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={6}
                      lg={3}
                      sx={{ marginTop: "15px" }}
                    >
                      <LoadingButton
                        loading={loading}
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ color: "#fff" }}
                      >
                        Agregar
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </LocalizationProvider>
      </BasicModal>
    </>
  );
};

export default AddTransformation;
