import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Grid, Box, Typography, IconButton } from "@mui/material";
import { initValPhonebook, valPhonebook } from "../../../utils/formHelper";
import BasicModal from "../../GenericModal";
import ContactService from "../../../api/contactService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import FormControl from "@mui/material/FormControl";
import { GenericDrawer } from "../../keskiCreator/genericDrawer";
import CloseIcon from "@mui/icons-material/Close";

const EditContact = ({
  data,
  toggleDrawer,
  setOpenEdit,
  open,
  UpdateInfo,
  target,
  close,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [contactData, setContactData] = useState(false);
  const id = data.id;
  const supplierService = new ContactService();

  useEffect(() => {
    if (data.id) {
      getContact();
    }
  }, [open]);

  const getContact = () => {
    (async () => {
      let response = await supplierService.getAllByIdAuth(id, logout);
      setContactData(response.data);
    })();
  };

  const formik = useFormik({
    initialValues: initValPhonebook(contactData),
    enableReinitialize: true,
    validationSchema: Yup.object(valPhonebook()),
    onSubmit: async (formData, { resetForm }) => {
      if (target == "supplier") {
        formik.values.supplier = contactData.supplier;
      } else {
        formik.values.client = contactData.client;
      }
      setLoading(true);
      let response = await supplierService.editDataAuth(id, formData, logout);
      if (Number(response.status) < 300) {
        sendNotification(UseerrorOrsuccess(response));
        UpdateInfo();
        resetForm();
        setLoading(false);
        close();
      } else {
        sendNotification(UseerrorOrsuccess(response));
        setLoading(false);
      }
    },
  });

  return (
    <GenericDrawer
      open={open}
      toggleDrawer={toggleDrawer}
      anchor={"right1"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "24px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Editar contacto</b>
          </Typography>

          <IconButton onClick={close}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth variant="outlined" size="small">
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="name"
                  id="name"
                  label="Nombre"
                  margin="dense"
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name ? formik.errors.name : ""}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  margin="dense"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="phone"
                  label="Telefono"
                  margin="dense"
                  size="small"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone ? formik.errors.phone : ""}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ marginTop: "15px" }}
                >
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff", borderRadius: 60 }}
                  >
                    Guardar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Box>
    </GenericDrawer>
  );
};

export default EditContact;
