import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterCash } from "../../api/posAdmin/CashRegisterManages";
import PosTable from "./cashRegisterDetails/PosTable";
import BranchManages from "../../api/branchManages";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useAuth } from "user-context-fran-dev";

const CashRegister = () => {
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(true);
  const [branchList, setBranchList] = useState();
  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const navigate = useNavigate();
  const [branch, setBranch] = useState("");
  const { logout, auth } = useAuth();

  const branchListRequest = () => {
    (async () => {
      const BranchList = new BranchManages();
      const response = await BranchList.getAllAuth(logout);
      setBranchList(response.data);
    })();
  };
  const setBranchChanges = async (id) => {
    await setBranch(id);
    setTable("");
    getCashRegisters(id);
  };

  const pageSetter = (n) => {
    setPage(n);
  };

  const getCashRegisters = (id) => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      if (id) irequestFilter.push({ key: "branch", value: `${id}` });
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getRegister = new RegisterCash();
      let response = await getRegister.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
    })();
  };

  useEffect(() => {
    navigate(`page=${page}`);

    getCashRegisters();
  }, [page]);

  useEffect(() => {
    branchListRequest();
  }, []);

  const GoDetail = (row) => {
    navigate(`detail/${row.id}`);
    window.localStorage.setItem("menu", 21);
  };

  return (
    <>
      <PosTable
        table={table}
        loading={loading}
        page={page}
        totalpages={totalpages}
        pageSetter={pageSetter}
        goTo={GoDetail}
      >
        {branchList && (
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="demo-simple-select-filled-label">
              Sucursales
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              label="Sucursales"
              value={branch}
              onChange={(e) => setBranchChanges(e.target.value)}
            >
              <MenuItem value={""}>Todo</MenuItem>
              {branchList.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </PosTable>
    </>
  );
};

export default CashRegister;
