import React, { useState, useEffect } from "react";
import { ProductModelAvailableService } from "../../../api/productService";
import DrawerArticle from "../DrawerArticle";
import { useAuth } from "user-context-fran-dev";
import DrawerArticleSales from "../../../pages/inventory/sales/details/drawerArticleSale";

export default function EditArticle(props) {
  const {
    openEdit,
    dataProduct,
    type,
    articleID,
    reloadDataOforder,
    orderData,
    toggleDrawer,
    dataPresentation,
  } = props;
  const [dataProductAva, setDataProduct] = useState("");
  /*   const [localDataProduct, setLocalDataProduct] = useState(""); */
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();
  console.log(dataProduct);


  useEffect(() => {
    getAvailabeProduct();
  }, [articleID]);

  const getAvailabeProduct = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "pres",
        value: `${dataProduct.presentation.id}`,
      });
      irequestFilter.push({ key: "wh", value: `${orderData.warehouse?.id ?? orderData.warehouse_source.id}` });
      let productInventory = new ProductModelAvailableService();
      let response = await productInventory.filter(irequestFilter, logout);
      setDataProduct(response?.data[0]);
      console.log(response);
      setLoading(false);
    })();
  };


  return (
    <>
      {(props.type == 'sales' || props.type === 'purchase') &&
        <DrawerArticleSales
          edit={true}
          openDrawer={openEdit}
          type={type}
          idArticleInOrder={articleID}
          toggleDrawer={toggleDrawer}
          reloadDataOforder={reloadDataOforder}
          dataProduct={dataProductAva}
          data={dataProduct}
          orderData={orderData}
          close={() => toggleDrawer("right1", false)}
        />
      }
      {(props.type === 'adjustment_order' || props.type === 'scrap_order' || props.type === 'transfer_order') &&
        <DrawerArticle
          openDrawer={openEdit}
          type={type}
          idArticleInOrder={articleID}
          toggleDrawer={toggleDrawer}
          Presentation={dataPresentation}
          reloadDataOforder={reloadDataOforder}
          dataProduct={dataProductAva}
          data={dataProduct}
          orderData={orderData}
          close={() => toggleDrawer("right1", false)}
        />
      }
    </>
  );
}