import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import { Button } from "@mui/material";
import { AdjustmentService } from "../../../../api/inventory/adjustmentService";
import AdjustmentTable from "./adjustmentTable";
import AddAdjustment from "./addAdjustment";
import CircularProgress from "@mui/material/CircularProgress";
import { GenericEmptyTable, GenericSkeletonTable } from "../../../../components/GenericEmptyTable";
import BasicTable from "../../../../components/GenericTable";
import { useTableFilters } from "../../../../hooks/searchParams";
import PageParam from "../../../../components/PageParam";
import { GenericSearchNew } from "../../../../components/GenericSearch/GenericSearch";

const Adjustment = () => {
  const [table, setTable] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [openDrawer, setOpenDrawer] = useState({});
  const [params, setParams, handleSearch, handleInputChange, UdateTable, handleChange] =
    useTableFilters(
      AdjustmentService,
      setTable,
      setLoading,
      null,
      "tab",
      500);

  return (
    <Grid >
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems="center"
        m={'24px 0 8px'}>
        <Grid >
          <h2>Ajustes de inventario</h2>
        </Grid>

        <Grid item xs={4} sm={3} md={2}>
          <Button
            fullWidth
            onClick={() => setOpenDrawer({ right: true })}
            variant="contained"
            sx={{ borderRadius: 60, }}
          >
            Crear
          </Button>
          <AddAdjustment
            open={openDrawer}
            toggleDrawer={() => { }}
            close={() => setOpenDrawer({ right: false })}
            UpdateAdjustment={UdateTable}
          />
        </Grid>

      </Grid>
      <p >
        Corrige discrepancias detectadas en el conteo físico,
        permitiendo agregar o restar unidades para mantener el stock preciso.
      </p>
      <GenericSearchNew
        params={params}
        setParams={setParams}
        handleChanges={handleInputChange}
        handleSearch={handleSearch}
        name="pattern"
        label={"Buscar por nombre"}
      />
      <Grid item xs={12}>
        <BasicTable
          className="marginTable"
          titles={[
            "Referencia",
            "Fecha",
            "Almacén",
            "Estado",
            "Productos",
            "Más"
          ]}
        >
          {table?.data?.length > 0 &&
            table.data.map((data, index) => (
              <AdjustmentTable key={index} data={data} />
            ))
          }
          {loading && !table && <GenericSkeletonTable />}
          {!table && !loading && (
            <GenericEmptyTable msg={"No hay datos en la tabla"} />
          )}
        </BasicTable>
        <div className="margin">
          <PageParam
            totalpages={table?.pagination || 1} // Ensure pagination data exists
            params={params}
            handleChange={handleChange}
          />
        </div>
      </Grid>

    </Grid>
  );
};

export default Adjustment;
