import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Typography, Link, IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import BasicTable from "../../../../components/GenericTable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TextField, MenuItem } from "@mui/material";
import { ProductService } from "../../../../api/productService";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../../components/GenericTable";
import CircleIcon from "@mui/icons-material/Circle";
import ModalPresentation from "./ModalPresentation";
import Menu from "@mui/material/Menu";
import { RawRecipe } from "../../../../api/recipeManages";
import RecipeModal from "./addRecipeModal";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import { GenericEmptyTable } from "../../../../components/GenericEmptyTable";
import ImageSize from "../../../../components/ImageSize";
import {
  GenericHeaderExpandable,
  GenericHeaderTitle,
} from "../../../../components/GenericHeader";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RecipeService } from "../../../../api/recipeManages";
import Tooltip from "@mui/material/Tooltip";
import SearchFilters from "../../../../components/inventario/searchFilters";

const RecipeTable = ({ recipeGroup, GetRecipeGroup }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <GenericHeaderTitle title="Recetas" />
        </Grid>
        {recipeGroup.recipe_product && (
          <Grid item xs={12} mt={"16px"}>
            {recipeGroup.recipe_product.map((data, index) => (
              <RecipeCard
                data={data}
                key={index}
                GetRecipeGroup={GetRecipeGroup}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

const RecipeCard = ({ data, GetRecipeGroup }) => {
  const [openCard, setOpenCard] = useState(false);
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [article, setArticle] = useState();
  const [openM, setOpenM] = useState(false);
  const [chanQuantity, setChanQuantity] = useState(false);
  const [checked, setChecked] = useState(true);
  const { auth, logout } = useAuth();

  const handleRecipeShow = async (event) => {
    let setRecipe = new RecipeService();
    let response = await setRecipe.editDataAuth(
      data.id,
      { is_active: event.target.checked },
      logout
    );

    GetRecipeGroup();
  };

  const Edit = (d) => {
    setArticle(d);
    setOpenM(true);
  };

  return (
    <>
      <Grid container sx={{ marginBottom: "8px" }}>
        {article && (
          <ModalPresentation
            open={openM}
            setOpen={setOpenM}
            article={article}
            edit={true}
            GetRecipeGroup={GetRecipeGroup}
            data={data}
          />
        )}
        <RecipeModal
          open={chanQuantity}
          setOpen={setChanQuantity}
          isEdit={data}
          update={GetRecipeGroup}
        />

        <Grid item xs={12}>
          <GenericHeaderExpandable
            title={
              <Typography variant="h6" component="div">
                {`${data.model.name}, ${data.presentation.name}`}
                <Typography
                  variant="caption"
                  display="inline"
                >{` (${data.quantity})`}</Typography>
              </Typography>
            }
            arrow={openCard}
            setArrow={setOpenCard}
          >
            <Grid container p={"8px"}>
              <Grid item xs={6}>
                Materia prima para
                <Tooltip title="Editar cantidad" arrow>
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => setChanQuantity(true)}
                    underline="none"
                  >
                    {` ${parseInt(data.quantity)} `}
                  </Link>
                </Tooltip>
                unidades
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => setAdd(!add)}
                    underline="none"
                  >
                    <Typography variant="subtitle1" component="div">
                      {!add ? "Agregar materia prima" : "Finalizar"}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={data.is_active}
                      onChange={handleRecipeShow}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Dejar de mostrar"
                />
              </Grid>
              <Grid item xs={12}>
                {data.raw_materials && (
                  <BasicTable
                    titles={[
                      "#",
                      "Cantidad",
                      "Nombre",
                      "Modelo",
                      "Presentación",
                      "Más",
                    ]}
                  >
                    {data.raw_materials.map((row, index) => (
                      <Row
                        key={index}
                        row={row}
                        index={index}
                        Edit={Edit}
                        GetRecipeGroup={GetRecipeGroup}
                      />
                    ))}
                  </BasicTable>
                )}
                {!data.raw_materials && <GenericEmptyTable />}
              </Grid>
              <Grid
                item
                xs={!add ? 1 : 12}
                sx={{ transition: "1s" }} /* sx={{border:'2px solid red'}} */
              >
                {add && (
                  <SearchMaterial
                    open={open}
                    setOpen={setOpen}
                    data={data}
                    GetRecipeGroup={GetRecipeGroup}
                  />
                )}
              </Grid>
            </Grid>
          </GenericHeaderExpandable>
        </Grid>
      </Grid>
    </>
  );
};

const Row = ({ row, index, Edit, GetRecipeGroup }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { auth, logout } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteRaw = (id) => {
    (async () => {
      let deleteRaw = new RawRecipe();
      let response = await deleteRaw.deleteAuth(id, logout);

      GetRecipeGroup();
    })();
  };
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {index + 1 + "-"}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {row.quantity}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {row.model.product.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "150px" }}>
        <Typography variant="subtitle1" component="div">
          {row.model.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          {row.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "100px" }}>
        <Typography variant="subtitle1" component="div">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ color: "text.secondary" }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                Edit(row);
              }}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                deleteRaw(row.id);
              }}
            >
              Eliminar
            </MenuItem>
          </Menu>
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const SearchMaterial = ({ GetRecipeGroup, open, setOpen, data }) => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState(false);
  const [table, setTable] = useState(null);
  const [msg, sendNotification] = useNotification();
  const [filters, setFilters] = useState("");
  const { logout } = useAuth();

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object({
      name: Yup.string().required("El campo es requerido"),
    }),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);

      getRecipes(formik.values.name);
      resetForm();
      setLoading(false);
    },
  });

  const getRecipes = (name) => {
    (async () => {
      let irequestFilter = [];
      if (filters) {
        for (let element in filters) {
          if (filters[element]) {
            irequestFilter.push({
              key: `${element}`,
              value: `${filters[element]}`,
            });
          }
        }
      }
      irequestFilter.push({ key: "pattern", value: `${name}` });
      irequestFilter.push({ key: "is_active", value: `true` });
      let getRecipe = new ProductService();
      let response = await getRecipe.filter(irequestFilter, logout);
      setTable(response.data);

      if (response) {
        //setOpenModal(false);
      } else {
        sendNotification(UseerrorOrsuccess(response));
      }
    })();
  };

  return (
    <Grid item xs={12} mt={4}>
      {article && (
        <ModalPresentation
          open={open}
          setOpen={setOpen}
          article={article}
          edit={false}
          GetRecipeGroup={GetRecipeGroup}
          data={data}
        />
      )}

      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container alignItems="center">
            <Grid item xs={9} sm={4} mr="16px">
              <TextField
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label="Buscar por nombre"
                /* margin="normal" */
                size="small"
                variant="outlined"
                /* className="space-text-field" */
                helperText={formik.touched.name ? formik.errors.name : ""}
              />
            </Grid>
            <Grid item xs={3} sm={2}>
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
              >
                Buscar
              </LoadingButton>
            </Grid>
            <SearchFilters filters={filters} setFilters={setFilters} />
          </Grid>
        </form>
      </Grid>

      {table && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          mt={1}
        >
          <Grid item xs={12}>
            <GenericTable
              titles={[
                "Imagen",
                "SKU",
                "Nombre",
                "Estatus",
                "Categoría",
                "Acción",
              ]}
            >
              {table.map((row, index) => (
                <RowRaw
                  row={row}
                  key={index}
                  doSomething={setArticle}
                  setOpen={setOpen}
                />
              ))}
            </GenericTable>
          </Grid>
        </Grid>
      )}
      {!table && <GenericEmptyTable msg={"Sin resultados"} />}
    </Grid>
  );
};

const RowRaw = ({ row, doSomething, setOpen }) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
      >
        <TableCell
          align="left"
          sx={{ maxWidth: "90px", minWidth: "90px", maxHeight: "90px" }}
        >
          <ImageSize
            img={row.main_model?.image ? row.main_model.image.url : ""}
            weight={1}
          />
          {/*               <img
                src={row.main_model?.image? row.main_model.image.url: ""}
                style={{ width: "80%", height: "auto" }}
              ></img> */}
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography variant="subtitle2" component="div">
            {row.sku}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "150px" }}>
          <Typography variant="subtitle2" component="div">
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "120px" }}>
          <Typography variant="subtitle2" component="div">
            {row.is_active ? (
              <CircleIcon sx={{ color: "green", fontSize: 15 }} />
            ) : (
              <CircleIcon sx={{ color: "red", fontSize: 15 }} />
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ maxWidth: "150px" }}>
          <Typography variant="subtitle2" component="div">
            {row.category.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            sx={{ cursor: "pointer" }}
            underline="none"
            onClick={() => {
              setOpen(true);
              doSomething(row);
            }}
          >
            <Typography variant="subtitle1" component="div">
              Seleccionar
            </Typography>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RecipeTable;
