import React, { useState } from "react";
import { Typography } from "@mui/material";
import GenericTable from "../../GenericTable";
import { CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { GenericEmptyTable } from "../../GenericEmptyTable";
import DropMenu from "../../../components/dropMenu";
import EditTransferArticle from "../editModals/editTransferArticle";
import EditArticle from "../editModals/editArticle";
import DrawerArticle from "../DrawerArticle";
const ArticlesListTrasnfer = (props) => {
  const { data, setOpenModal, setModalInfo, ...extraProp } = props;

  /* const [open, setOpen] = useState(false); */
  const [openEdit, setOpenEdit] = useState(false);
  const [articleToEdit, setIdToEdit] = useState("");
  const [articleID, setArticleId] = useState('');
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {

    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };


  return (
    <>
      {articleToEdit && data && (

        <EditArticle
          openEdit={openDrawer}
          toggleDrawer={toggleDrawer}
          dataProduct={articleToEdit}
          orderData={data}
          type={extraProp.type}
          articleID={articleID}
          reloadDataOforder={extraProp.updateInfo}
        />

      )}
      <div className="articles-table">
        <GenericTable
          titles={[
            "Nombre / #parte ",
            "Modelo / Presentación",
            "Barcode",
            "Cantidad",
            ...(data.status === 1 ? ["Más"] : []),
          ]}
        >
          {data.articles && data.articles.length > 0 ? (
            data.articles.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                {/* Nombre / #parte */}
                <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.model.product.name || "N/A"}
                      </Typography>
                    }
                    subheader={row?.model.product.sku || "N/A"}
                  />
                </TableCell>

                {/* Modelo / Presentación */}
                <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.model.name || "N/A"}
                      </Typography>
                    }
                    subheader={row?.presentation.name || "N/A"}
                  />
                </TableCell>

                {/* Barcode */}
                <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.barcode || "N/A"}
                      </Typography>
                    }
                  />
                </TableCell>

                {/* Cantidad */}
                <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                  <CardHeader
                    sx={{ m: 0, p: 0 }}
                    title={
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.quantity || 0}
                      </Typography>
                    }
                  />
                </TableCell>

                {/* Dropdown Menu */}
                {data.status == 1 && (
                  <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      item
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <DropMenu
                        row={row}
                        index={index}
                        service={extraProp.service}
                        id={row.id}
                        update={extraProp.updateInfo}
                        toggleDrawer={toggleDrawer}
                        setIdToEdit={setIdToEdit}
                        setOpenDrawer={setOpenDrawer}
                        setArticleId={setArticleId}
                      />
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <GenericEmptyTable msg={"No hay artículos agregados"} />
          )}
        </GenericTable>
      </div>

    </>
  );
};

export default ArticlesListTrasnfer;
