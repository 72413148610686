import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Container from "../../layout/gridContainer";
import AddSuppliers from "./suppliers/addSuppliers";
import { Suppliers } from "../../api/supplierService";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";
import { GenericEmptyTable } from "../../components/GenericEmptyTable";
import GridViewIcon from "@mui/icons-material/GridView";
import suppliersImages from "../../assets/suppliers";
import { StickyHeadTable } from "../../components/TablewithPagination";
import { PaginationGeneric } from "react-generic-pagination";
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import BasicTable from "../../components/GenericTable";

const InventorySuppliers = (props) => {
  const [searchString, setSearchString] = useState("");
  const { logout } = useAuth();
  const [table, setTable] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({});
  const [page, setPage] = useState(1);
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const totalPerPage = 10;
  const navigate = useNavigate();
  let getUsers = new Suppliers();

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateSupplierList();
  }, [page]);

  const UpdateSupplierList = async () => {
    setLoading(true);
    let irequestFilter = [];
    irequestFilter.push({ key: "page", value: `${page}` });
    irequestFilter.push({ key: "items", value: `${totalPerPage}` });
    if (searchString) {
      irequestFilter.push({ key: "pattern", value: `${searchString}` });
    }

    let response = await getUsers.filter(irequestFilter, logout);
    setTotalPages(response.data.pagination);
    setTable(response.data.data);
    setLoading(false);
  };

  const pageSetter = (page) => {
    setPage(page);
    navigate(`page=${page}`);
  };

  return (
    <>
      <Container>
        <Grid
          container
          display={'flex'}
          alignItems="center"
          justifyContent={"space-between"}
          m={'24px 0 8px'}
        >
          <Grid >
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              className="title-card"
            >
              <strong>Proveedores</strong>
            </Typography>

          </Grid>
          <Grid item xs={5} sm={4} md={2}>
            <Button
              onClick={() => setOpenDrawer({ right2: true })}
              fullWidth
              sx={{ borderRadius: "40px", maxWidth: "300px" }}
              variant="contained"
            >
              Agregar proveedor
            </Button>
            <AddSuppliers
              open={openDrawer}
              toggleDrawer={() => { }}
              logout={logout}
              close={() => setOpenDrawer({ right2: false })}
              type={"purchase"}
              UpdateSupplierList={UpdateSupplierList}
            />
          </Grid>
        </Grid>
        <p>
          Registra y administra la información de tus proveedores,
          incluyendo datos de contacto, condiciones de pago y productos que te suministran.
        </p>
        <Grid container spacing={0}>

          <GenericSearch
            Service={Suppliers}
            setData={setTable}
            updateInfo={UpdateSupplierList}
            setLoading={setLoading}
            label={"Buscar por nombre"}
          />

          <BasicTable
            titles={
              [
                "Nombre",
                "Teléfono",
                "Correo electrónico",
                "Tipo",
                "Más",
              ]
            }
          >
            <StickyHeadTable
              rows={table}
              UpdateInfo={UpdateSupplierList}
              loading={loading} />
          </BasicTable>



          <Grid item xs={12}>
            <>

              <div className="margin">
                <PaginationGeneric
                  className="paginationStyle"
                  pagina={1}
                  total={totalpages}
                  pageSetter={pageSetter}
                />
              </div>
            </>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InventorySuppliers;
