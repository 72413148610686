import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Grid2 from "@mui/material/Grid2";
import { CatalogPosService } from "../../../api/pos/warehouseService";
import ImgMediaCard from "../../../components/pos/productBox/productBox";
import { useAuth } from "user-context-fran-dev";
import GenericEmptyTable from "../../../components/pos/GenericEmptyTable";
import AddToPurchase from "./addProductToPurchase";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { BottonModalDiag } from "../../../components/GenericsModals";
import { Divider, IconButton } from "@mui/material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import * as Yup from "yup";
import { useFormik } from "formik";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CategoryManages from "../../../api/categoryManages";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { BarCodeService } from "../../../api/pos/barcodeService";
import Switch from "@mui/material/Switch";

const PosHome = ({ updateSalesList, openPurchaseList }) => {
  const [loading, setLoading] = useState("");
  const [productList, setProductList] = useState(null);
  const [salesList, setSaleslist] = useState("");
  const [open, setOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const { logout } = useAuth();
  const [model, setModel] = useState("");
  const [presentationToSearch, setPresentationToSearch] = useState("");
  const navigate = useNavigate();
  let { id, cr } = useParams();
  const [params, setParams] = useSearchParams();
  const [pattern, setPattern] = useState("");
  const [barcodeValue, setBarCodeValue] = useState("");
  const [categoryVal, setCategoryVal] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [barcodeinput, setBarcodeInput] = useState(false);
  const [scanBarcode, setScanBarcode] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (params.get("pattern")) {
      setPattern(params.get("pattern"));
    }
    if (params.get("category")) {
      setCategoryVal(params.get("category"));
    }

    if (cr === "undefined") {
      setHelpOpen(true);
    } else {
      UpdateProductList();
    }
    //updateSalesList()
  }, [id, cr, params]);

  useEffect(() => {
    updateSalesList();
  }, []);

  const deleteFilter = () => {
    setSelectedIndex("");
    setPattern("");
    setBarCodeValue("");
    params.delete("pattern");
    params.delete("category");
    params.delete("barcode");
    setParams(params);
    setScanBarcode(0);
  };

  const search = () => {
    setScanBarcode(0);
    if (pattern) {
      params.set("pattern", pattern);
      setParams(params);
      setPattern(pattern);
      //setPattern(null)
    } else {
      deleteParam();
      setPattern(null);
    }
  };

  const searchBarcode = () => {
    params.delete("pattern");
    setScanBarcode(1);
    setParams(params);
    (async () => {
      if (barcodeValue === "") {
        search();
      } else {
        setLoading(true);
        params.set("barcode", barcodeValue);
        setParams(params);
        let irequestFilter = [];
        irequestFilter.push({ key: "wh", value: id }); // auth.cashregister.wh //auth.cashregister.cr.warehouse
        irequestFilter.push({ key: "is_Active", value: "true" });
        irequestFilter.push({ key: "barcode", value: barcodeValue });
        let BarcodeService = new BarCodeService();
        let response = await BarcodeService.filter(irequestFilter, logout);
        if (response.status === 200) {
          setProductList(response.data);

          opent(response.data[0]);
          //setScanBarcode(false)
        }
        setLoading(false);
      }
    })();
  };

  const deleteParam = () => {
    params.delete("pattern");
    setParams(params);
    UpdateProductList();
    setScanBarcode(0);
  };

  const handleChange = (event) => {
    setPattern(event.target.value);
  };

  const handleBarcodeChange = (event) => {
    setBarCodeValue(event.target.value);
  };

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object({
      name: Yup.string().required("El campo es requerido"),
    }),
    onSubmit: async ({ resetForm }) => {
      setLoading(true);

      UpdateProductList(formik.values.name);
      resetForm();
      setLoading(false);
    },
  });

  const opent = (props) => {
    setModel(props);
    setOpen(true);
  };

  const toggleBarcodeSearch = (event) => {
    setChecked(event.target.checked);
    params.delete("barcode");
    setParams(params);
    setBarcodeInput(!barcodeinput);
    setScanBarcode(0);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        if (barcodeinput === true) {
          searchBarcode();
        } else {
          search();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [pattern, barcodeValue]);

  const UpdateProductList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      if (params.get("category")) {
        irequestFilter.push({ key: "category", value: params.get("category") });
      }
      irequestFilter.push({ key: "wh", value: id }); // auth.cashregister.wh //auth.cashregister.cr.warehouse
      irequestFilter.push({ key: "is_Active", value: "true" });
      //irequestFilter.push({'key':'display_on_pos', 'value': 'true' })
      if (params.get("pattern")) {
        irequestFilter.push({ key: "pattern", value: params.get("pattern") });
      }
      if (params.get("barcode")) {
        irequestFilter.push({ key: "barcode", value: params.get("barcode") });
      }
      let getCatalog = new CatalogPosService();
      let response = await getCatalog.filter(irequestFilter, logout);
      setProductList(response.data);

      setLoading(false);
    })();
  };
  return (
    <>
      <AddProduct open={open} setOpen={setOpen}>
        <AddToPurchase
          deleteFilter={deleteFilter}
          presentationToSearch={presentationToSearch}
          setSaleslist={setSaleslist}
          model={model}
          scanBarcode={scanBarcode}
          updateSalesList={updateSalesList}
          setOpen={setOpen}
        ></AddToPurchase>
      </AddProduct>

      <Grid2 container direction="column">
        <Grid2
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ height: "75px" }}
        >
          {!barcodeinput && (
            <>
              <Grid2 margin={1} marginLeft={3} xs={1} sm={3} md={3}>
                <Paper
                  component="form"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Buscar por nombre"
                    value={pattern}
                    inputProps={{ "aria-label": "name" }}
                    onChange={handleChange}
                    autoFocus
                  />

                  <IconButton
                    onClick={() => {
                      search();
                    }}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid2>
              <Grid2
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs
              >
                <Grid2 xs display="flex">
                  <SimpleListMenu
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                  ></SimpleListMenu>

                  <Button
                    variant="outlined"
                    sx={{ height: "44px", mt: "8px" }}
                    onClick={() => {
                      deleteFilter();
                    }}
                    startIcon={<DeleteIcon />}
                  >
                    Borrar
                  </Button>
                </Grid2>
              </Grid2>
            </>
          )}
          {barcodeinput && (
            <>
              <Grid2 xs>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    ml: "24px",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Codigo de barras"
                    value={barcodeValue}
                    inputProps={{ "aria-label": "name" }}
                    onChange={handleBarcodeChange}
                    autoFocus
                  />
                  <IconButton
                    onClick={() => {
                      searchBarcode();
                    }}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid2>
              <Grid2>
                <Button
                  variant="outlined"
                  sx={{ height: "44px", ml: "8px" }}
                  onClick={() => {
                    deleteFilter();
                  }}
                  startIcon={<DeleteIcon />}
                >
                  Borrar
                </Button>
              </Grid2>
            </>
          )}
          <Grid2 xs textAlign={"end"}>
            <Switch
              checked={checked}
              onChange={toggleBarcodeSearch}
              inputProps={{ "aria-label": "controlled" }}
            />

            {/* <Grid2 xs textAlign={'end'}>
                      <QrCode2Icon sx={{fontSize:'50px'}}/>
                    </Grid2> */}
          </Grid2>
        </Grid2>

        <Divider></Divider>

        <Grid2
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: "85vw" }}
        >
          <Grid2
            container
            spacing={{ xs: 3, md: 2, sm: 1 }}
            columns={openPurchaseList ? { xs: 6, sm: 16, md: 10 } : 16}
            sx={{
              width: openPurchaseList
                ? { xs: "100vw", sm: "68vw", md: "77vw" }
                : "100vw",
              mt: "1%",
              ml: "10px",
            }}
          >
            {productList ? (
              productList.map((data, index) => (
                <Grid2
                  item
                  xs={2}
                  sm={4}
                  md={2}
                  key={index}
                  onClick={() => opent(data)}
                >
                  <ImgMediaCard key={index} data={data}></ImgMediaCard>
                </Grid2>
              ))
            ) : (
              <Grid2 item sx={{ width: "90vw" }}>
                <Grid2 item className="add-product">
                  <GenericEmptyTable msg={"No se encontraron resultados"} />
                </Grid2>
                <Grid2
                  container
                  justifyContent="center"
                  alignItems="center"
                ></Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Grid2>

      {!loading && cr === "undefined" && (
        <Grid2 container justifyContent="center" alignItems="center">
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => {
              navigate("/pos/cash-register");
            }}
            type="submit"
          >
            Abrir Opciones de caja
          </LoadingButton>
        </Grid2>
      )}
    </>
  );
};

const AddProduct = ({ open, setOpen, children }) => {
  let title = "Seleccionar presentación";
  return (
    <BottonModalDiag
      open={open}
      setOpen={setOpen}
      title={{ text: title, align: "start" }}
      size="800px"
    >
      {children}
    </BottonModalDiag>
  );
};

export default PosHome;

const SimpleListMenu = ({ selectedIndex, setSelectedIndex }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [params, setParams] = useSearchParams();
  const [categories, setCategories] = React.useState(null);
  let getCategory = new CategoryManages();
  const { logout } = useAuth();

  async function getCategories() {
    const requestParams = [];
    requestParams.push({ key: "display_on_store", value: `true` });
    let categoriesApi = await getCategory.filter(requestParams, logout);
    setCategories(categoriesApi.data);
  }

  useEffect(() => {
    getCategories();

    if (params.get("category")) {
      let result = categories.findIndex(
        (category) => category.id === params.get("category")
      );

      setSelectedIndex(result);
    }
  }, []);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index);
    if (index >= 0) {
      params.set("category", option.id);
      setParams(params);
    } else {
      params.delete("category");
      setParams(params);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ mr: "16px", ml: "16px" }}
      >
        <Button
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          variant="contained"
          disableElevation
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            //primary="Categorias"
            //primary={selectedIndex}
            primary={
              selectedIndex ? categories[selectedIndex].name : "Categorias"
            }
          />
        </Button>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {categories &&
          categories.map((option, index) => (
            <MenuItem
              key={index}
              //disabled={index === 0}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index, option)}
            >
              {option.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
