import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Container from "../../../../layout/gridContainer";
import FormControl from "@mui/material/FormControl";
import { TextField, Button } from "@mui/material";
import GenericTable from "../../../../components/GenericTable";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import AddTransfer from "./addTransfer";
import { TransferService } from "../../../../api/inventory/transfersService";
import { GenericEmptyTable, GenericSkeletonTable } from "../../../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import TransferTable from "./transferTable";
import BasicTable from "../../../../components/GenericTable";
import { useTableFilters } from "../../../../hooks/searchParams";
import PageParam from "../../../../components/PageParam";
import { GenericSearchNew } from "../../../../components/GenericSearch/GenericSearch";

const Transfers = () => {
  const [openModal, setOpenModal] = useState();
  const [openDrawer, setOpenDrawer] = useState({});
  const [totalpages, setTotalPages] = useState();
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState();
  const [params, setParams, handleSearch, handleInputChange, UdateTable, handleChange] =
    useTableFilters(TransferService, setTable, setLoading, "", "tab");

  return (
    <Grid>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems="center"
        m={'24px 0 8px'}>
        <Grid >
          <h2>Transferencias</h2>
        </Grid>

        <Grid item xs={4} sm={3} md={2}>
          <Button
            fullWidth
            onClick={() => setOpenDrawer({ right: true })}
            variant="contained"
            sx={{ borderRadius: 60, }}
          >
            Crear
          </Button>
          <AddTransfer
            updateTransfers={UdateTable}
            open={openDrawer}
            toggleDrawer={() => { }}
            close={() => setOpenDrawer({ right: false })}
          />
        </Grid>
      </Grid>
      <p>
        Envía productos de un almacén a otro o de una sucursal a otra,
        manteniendo la trazabilidad y el control del movimiento de mercancía.
      </p>
      <GenericSearchNew
        params={params}
        setParams={setParams}
        handleChanges={handleInputChange}
        handleSearch={handleSearch}
        name="pattern"
        label={"Buscar por nombre"}
        width={'30%'}
      />
      <Grid item xs={12} className="margin-scrap-table">

        <>
          <BasicTable
            className="marginTable"
            titles={["Almacen Origen", "Almacen Destino", "Fecha", "Estado", "Más"]}
          >
            {table?.data?.length > 0 &&
              table.data.map((data, index) => (
                <TransferTable key={index} data={data} />
              ))
            }
            {loading && <GenericSkeletonTable />}
            {!table && !loading && <GenericEmptyTable />}
          </BasicTable>
          <div className="margin">
            {/* <PaginationGeneric className="paginationStyle" pagina={1} total={totalpages} pageSetter={pageSetter}></PaginationGeneric> */}
            <PageParam
              totalpages={table?.pagination || 1}
              params={params}
              handleChange={handleChange}
            />
          </div>
        </>

      </Grid>
    </Grid>
  );
};

export default Transfers;
