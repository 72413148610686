import React, { useEffect, useState } from "react";
import { GenericDrawer } from "../keskiCreator/genericDrawer";
import { useAuth } from "user-context-fran-dev";
import PurchaseService from "../../api/purchaseService";
import SalesService from "../../api/salesService";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import EmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import PlaceIcon from "@mui/icons-material/PlaceOutlined";
import {
  Alert,
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import CopyToClipboard from "react-copy-to-clipboard";

export default function DrawerDetails(props) {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const prop_path = ["phone", "email"];
  const credit_prop_path = ["credit_days", "credit_limit", "discount_amount"];
  const credit_label = ["Días de crédito:", "Descuento:", "Límite de crédito:"];
  const total_path = ["total", "discount", "iva_amount", "sub_total"];
  const total_label = ["TOTAL:", "Descuento (%):", "IVA (16%):", "Subtotal:"];
  const address_prop_path = [
    "street",
    "ext_number",
    "suburb",
    ,
    "postal_code",
    "state",
  ];
  const iconList = [PhoneIcon, EmailIcon];
  const statusLabels = {
    1: "Borrador",
    2: props.type === "sales" ? "Reservado" : "Ordenado",
    3: "Completado",
  };

  useEffect(() => {
    if (props.data) {
      fetchData();
    }
  }, []);

  const fetchData = () => {
    (async () => {
      const services = {
        purches: new PurchaseService(),
        sales: new SalesService(),
      };
      const service = services[props.type];
      let response = await service.getAllByIdAuth(props.data.id, logout);
      setData(response?.data);
    })();
  };

  const paths = prop_path.map((val, index) => ({
    path: val,
    icon: iconList[index], // Set value to the corresponding key
  }));

  const credit_paths = credit_label.map((val, index) => ({
    label: val,
    path: credit_prop_path[index], // Set value to the corresponding key
  }));

  const handleClick = () => {
    setOpen(true); // Muestra la alerta
  };

  const handleClose = () => {
    setOpen(false); // Cierra la alerta
  };

  return (
    <GenericDrawer
      open={props.open}
      toggleDrawer={props.toggleDrawer}
      anchor={"right"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Vista previa</b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h5">
            <b>{data?.no_order || "Numero de oreden"}</b>
          </Typography>

          <Chip
            label={statusLabels[data?.status] || "Cancelado"}
            variant="outlined"
            sx={{ p: 0 }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          p={"16px 0"}
        >
          <Typography>
            {dayjs(data.date_created).format("MMM DD, YYYY")}
          </Typography>

          <Typography p={"0 16px"}>
            {props?.type === "purches" ? "Compra" : "Venta"}
          </Typography>
        </Stack>
        <Typography>{data?.warehouse?.description}</Typography>

        {(data.client || data.supplier) && (
          <>
            <Divider sx={{ m: "16px 0" }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography variant="h5">
                <b>
                  {data?.client?.business_name || data?.supplier?.business_name}
                </b>
              </Typography>

              <Chip
                label={data?.type === "1" ? "Empresa" : "Persona"}
                variant="outlined"
                sx={{ p: 0 }}
              />
            </Stack>
            <Typography pb={"16px"}>
              {data?.client?.rfc || data?.supplier?.rfc}
            </Typography>
            {paths.map(({ path, icon: Icon }, index) => (
              <Grid
                display="flex"
                key={index}
                padding="8px 0"
                justifyContent="space-between"
              >
                <Grid display="flex">
                  <Icon style={{ marginRight: "10px" }} />
                  <Typography>
                    {data?.client?.[path] || data?.supplier?.[path]}
                  </Typography>
                </Grid>
                {path !== "address" && (
                  <CopyToClipboard text={data[path] || ""}>
                    <CopyIcon
                      fontSize="small"
                      onClick={handleClick}
                      sx={{
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                    />
                  </CopyToClipboard>
                )}
              </Grid>
            ))}
            {/* 
                        <Box display="flex" padding="8px 0">
                            <PlaceIcon />
                            <Grid container p="0 8px">
                                {address_prop_path.map((path, index) => (
                                    <Grid item xs={path === "state" ? 6 : 5.5} key={index}>
                                        <Typography>{data?.client.address?.[path] || "--"}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box> */}

            <Snackbar
              open={open}
              autoHideDuration={3000} // Duración de la alerta (en ms)
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                ¡Copiado!
              </Alert>
            </Snackbar>
          </>
        )}
        <Divider sx={{ m: "16px 0" }} />

        {credit_paths.map(({ label, path }) => (
          <Grid display="flex" p="5px 0" key={path}>
            <Typography mr={1}>{label}</Typography>
            <Typography>
              <strong>{data?.[path] ?? "0"}</strong>
            </Typography>
          </Grid>
        ))}
        <Divider sx={{ m: "16px 0" }} />
        {total_label.map((label, index) => (
          <Grid display="flex" p="5px 0" key={index}>
            <Typography mr={1}>{label}</Typography>
            <Typography>
              <strong>{data?.[total_path[index]] ?? "0"}</strong>
            </Typography>
          </Grid>
        ))}
        {data.articles && (
          <>
            <Divider sx={{ m: "16px 0" }} />
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Stack direction={"row"}>
                <Avatar
                  alt="Remy Sharp"
                  src={data?.articles[0]?.model?.image?.url}
                  sx={{ width: 56, height: 56, mr: 3 }}
                />

                <div>
                  <Typography>
                    {data?.articles[0]?.model?.product?.name}
                  </Typography>
                  <Typography>
                    {data?.articles[0]?.model.name}
                    {data?.articles[0]?.presentation?.name === "--"
                      ? ""
                      : ", " + data.articles[0]?.presentation?.name}
                  </Typography>
                </div>
              </Stack>
              <p>${data?.articles[0]?.price}</p>
            </Grid>
          </>
        )}
      </Box>
    </GenericDrawer>
  );
}
