import React, { useState, useEffect } from "react";
import MetaData from "../../components/landing/metaData";
import { useAuth } from "user-context-fran-dev";
import BusinessManages, {
  BusinessMetaData,
  BusinessSettings,
} from "../../api/businessManages";
import Container from "../../layout/gridContainer";
import { ModalRight } from "../../components/GenericsModals";
import { Grid, Grid2, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { useNavigate } from "react-router-dom";
import { ModalImages } from "./modalImages/modalImages";
import { GenericDrawer } from "../../components/keskiCreator/genericDrawer";

const LandingMetaData = ({ menu, view }) => {
  const [business, setBusiness] = useState("");
  const [styles, setStyles] = useState("");
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [openImages, setOpenImages] = useState(false); // Nuevo estado para ModalImages
  const [modalContext, setModalContext] = useState(""); // Nueva bandera

  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    UpdateBusinessInfo();
    UpdateStyles();
    window.localStorage.setItem("menu", menu);
  }, []);

  const UpdateBusinessInfo = () => {
    (async () => {
      let useBusines = new BusinessMetaData();
      let response = await useBusines.getAllById("1");
      setBusiness(response.data);
    })();
  };

  const getTurnBusiness = async () => {
    try {
      let useBusiness = new BusinessManages();
      let response = await useBusiness.getAllAuth(logout);
      return response.data.turn_business;
    } catch (error) {
      return null;
    }
  };

  const handleClickOpenModal = async () => {
    if (view === "Legal") {
      navigate("/landing/politics");
    } else if (view === "Libreria") {
      const turnBusiness = await getTurnBusiness();
      setBusiness((prev) => ({ ...prev, turn_business: turnBusiness }));
      setModalContext("Personalizar"); // Establecer el contexto
      setOpenImages(true);
    } else {
      toggleDrawer("right", true); // Cierra el drawer derecho
      //setModalContext("Remplazar"); // Establecer el contexto
    }
  };

  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      setStyles(response.data);
    })();
  };

  const handleCloseImages = () => {
    setOpenImages(false); // Cambia el estado a false para cerrar el modal
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClickOpenModal}
        sx={{
          color: "black",
          width: "95%",
          gap: "20px",
          display: "flex",
          justifyContent: "flex-start",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgba(186, 85, 211, 0.1)",
            color: "black",
          },
        }}
      >
        {view === "Estilo" && (
          <>
            <ColorLensOutlinedIcon /> Estilo{" "}
          </>
        )}
        {view === "Libreria" && (
          <>
            <LibraryBooksOutlinedIcon /> Librería{" "}
          </>
        )}

        {view === "Información" && (
          <>
            <InfoOutlinedIcon /> Información
          </>
        )}
        {view === "Legal" && (
          <>
            <PolicyOutlinedIcon /> Legal
          </>
        )}
      </Button>

      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor="right"
        drawerWidth="30%"
      >
        {business && (
          <MetaData
            data={business}
            update={UpdateBusinessInfo}
            updateStyles={UpdateStyles}
            styles={styles}
            view={view}
          />
        )}
      </GenericDrawer>
      {view === "Libreria" && openImages && (
        <Grid item xs={12} mt="16px">
          <ModalImages
            data={business.library} // Asegúrate de que los datos estén disponibles
            turnBusiness={business.turn_business}
            UpdateInfo={UpdateBusinessInfo}
            open={openImages}
            onClose={handleCloseImages}
            context={modalContext} // Pasar el contexto
          />
        </Grid>
      )}
    </>
  );
};

export default LandingMetaData;

//   const UpdateBusinessInfo = () => {
//     (async () => {
//       let useBusines = new BusinessMetaData();
//       let response = await useBusines.getAllById("1");
//       setBusiness(response.data);
//     })();
//   };
//   const handleClickOpenModal = () => {
//     setOpenModal(true);
//   };
//   const UpdateStyles = () => {
//     (async () => {
//       let useBusines = new BusinessSettings();
//       let response = await useBusines.getAllByIdAuth("1", logout);
//       setStyles(response.data);
//     })();
//   };
//   return (
//     <>
//       <Button
//         variant="text"
//         onClick={() => {
//           handleClickOpenModal();
//         }}
//         sx={{ width: "100%", color: "white", height: "100%" }}
//       >
//         Personalizar
//       </Button>
//       <ModalRight
//         open={openModal}
//         setOpen={setOpenModal}
//         title={
//           <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//             Estilos
//           </Typography>
//         }
//         size="sm"
//       >
//         {business && (
//           <MetaData
//             data={business}
//             update={UpdateBusinessInfo}
//             updateStyles={UpdateStyles}
//             styles={styles}
//           />
//         )}
//       </ModalRight>
//       <ModalImages />
//     </>
//   );
// };

// export default LandingMetaData;
