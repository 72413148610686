import { BASE_PATH } from "../utils/constants";
import { BaseService } from "base-service-fran-dev";

export class LoginApi extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "auth/login/";
    super(baseUrl, endPoint);
  }
}

export class RecoverPass extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "auth/password/reset";
    //BASE_PATH+endPoint
    //https://api.suajam.com/api/v1/auth/password/reset
    super(baseUrl, endPoint);
  }
}

export class ResetPass extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "auth/password/reset/confirm";
    super(baseUrl, endPoint);
  }
}

export class ChangesPass extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "auth/password/change";
    super(baseUrl, endPoint);
  }
}
