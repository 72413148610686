import React, { useEffect, useState } from "react";
import { Grid, Divider, TextField, Box, IconButton, Paper, Stack } from "@mui/material";
import BasicTable from "../../../components/GenericTable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { AvailablePresentation } from "../../../api/availablePresentation";
import { useAuth } from "user-context-fran-dev";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValRecivedPurchase,
  initValSalesDelivered,
  valSchSalesDelivered,
} from "../../../utils/formHelper";
import { OrderedDelivered, OrderedReceived } from "../../../api/orderedReceived";
import SalesOrder from "../../../api/salesOrder";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import ImageSize from "../../../components/ImageSize";
import { TableModal } from "../../../components/GenericsModals";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import { formatNumber } from "../../../utils/functions";

const SalesDelivered = ({
  article,
  open,
  toggleDrawer,
  sales,
  GetSalesDetail,
  setOpen,
  close }) => {
  const [table, setTable] = useState("");
  const idArticle = article.id;
  const idPresentation = article.presentation.id;
  const warehouse = sales.warehouse.id;

  const { logout } = useAuth();

  const [dataArticles, setDataArticles] = useState();
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (open) {
      UpdateModalInfo();
      getPresentation();
    }
  }, [update, article, open]);

  const getPresentation = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "wh",
        value: `${warehouse}`,
      }); /* sales.warehouse */
      irequestFilter.push({ key: "pres", value: `${idPresentation}` });
      let getPresentation = new AvailablePresentation();
      let response = await getPresentation.filter(irequestFilter, logout);
      setTable(response.data);
    })();
  };

  const formik = useFormik({
    initialValues: initValSalesDelivered(article),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData) => {
      setLoading(true);
      if (formik.values.barcode === "") {
        formik.values.barcode = null;
      }
      let setOrder = new OrderedDelivered();
      let response = await setOrder.postDataAuth(formData, logout);
      GetSalesDetail();
      sendNotification(UseerrorOrsuccess(response));
      setLoading(false);
      close();
    },
  });
  const UpdateModalInfo = () => {
    (async () => {
      let getSales = new SalesOrder();
      let response = await getSales.getAllByIdAuth(sales.id, logout);

      setDataArticles(
        response.data.articles.find((data) => data.id === idArticle)
      );
    })();
  };

  return (
    <GenericDrawer
      open={open}
      toggleDrawer={toggleDrawer}
      anchor={"right"}
      drawerWidth="30%"
    >
      <Box p={'16px'}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Por Entregar</b>
          </Typography>

          <IconButton onClick={close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        {dataArticles && (
          <Grid
            direction="row"
            justifyContent="space-around"
            m={'24px 0'}
          >
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <ImageSize
                    img={dataArticles?.model?.image?.url ?? ""}
                    weight={2}
                  />

                </Grid>
                <Grid item xs={10}>
                  <Grid item xs={12} className="">
                    <h3>{dataArticles?.model?.product?.name}</h3>
                  </Grid>
                  <Grid item xs={12} >
                    <p>{dataArticles?.model?.product?.sku}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider />

            <Grid display={'flex'} p={'8px 0'}>
              <Typography fontSize={'16px'}>Modelo: {dataArticles?.model?.name}</Typography>
            </Grid>


            <Divider />
            <Grid display={'flex'} p={'8px 0'}>
              <Typography fontSize={'16px'} mr={'5px'}>Variante: </Typography>
              <span>
                <Typography>
                  {dataArticles?.presentation?.name}
                </Typography>
                <Typography>
                  {dataArticles?.presentation?.barcode}
                </Typography>
                <Typography color={dataArticles?.presentation.is_active ? 'success' : 'error'}>
                  {dataArticles?.presentation?.is_active ? 'Activo' : "Inactivo"}
                </Typography>
              </span>
            </Grid>
            <Divider />
            <Stack
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'space-evenly'}
              p={'16px 0'} >
              <Grid display={'flex'} flexDirection={'column'} >
                <Typography align="center">
                  <h2>
                    {formatNumber(article?.quantity)}
                  </h2>
                </Typography>
                <h4>Ordenados</h4>
              </Grid>
              <Grid display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <h2>{formatNumber(article?.quantity_pending, 2)}</h2>
                <h4>Pendientes</h4>
              </Grid>
            </Stack>



            <Paper elevation={4} sx={{ backgroundColor: "background.default" }}>
              <p style={{ padding: "8px 16px" }}>Inventario:</p>
              <Grid container p={"8px 16px"}>

                <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <p>Ord: {table?.[0]?.inventory[0]?.ordered}</p>
                  <p>Dis: {table?.[0]?.inventory?.[0]?.available}</p>
                  <p>Res: {table?.[0]?.inventory?.[0]?.reserved}</p>
                </span>
                <Grid item xs={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} alignItems="center" >
                      <Grid item xs={5}>
                        <TextField
                          type="number"
                          fullWidth
                          name="quantity"
                          value={formik.values.quantity}
                          /* defaultValue={data.quantity_pending} */
                          onChange={formik.handleChange}
                          error={
                            formik.touched.quantity &&
                            Boolean(formik.errors.quantity)
                          }
                          helperText={
                            formik.touched.quantity ? formik.errors.quantity : ""
                          }
                          autoComplete="none"
                          label="Cantidad"
                          margin="dense"
                          size="small"
                          variant="outlined"
                        />
                        {/* <p>{new Intl.NumberFormat('en', { maximumSignificantDigits: 10 }).format(formik.values.cost)}</p> */}
                      </Grid>

                      <Grid item xs></Grid>

                      <Grid item xs={3}>
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{
                            color: "primary",
                            borderRadius: 60
                          }}
                        >
                          Entregar
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {!dataArticles && (
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'70vh'}
          >
            <CircularProgress />
          </Grid>
        )}
      </Box>
    </GenericDrawer>
  );
};

const RowInfo = ({
  row,
  setOpen,
  data,
  idArticle,
  GetSalesDetail,
  setUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const formik = useFormik({
    initialValues: initValSalesDelivered(idArticle, row),
    enableReinitialize: true,
    validationSchema: Yup.object(
      valSchSalesDelivered(row.available, data.quantity_pending)
    ),
    onSubmit: async (formData) => {
      setSalesDelivered(formData);
    },
  });

  const setSalesDelivered = async (formData) => {
    setLoading(true);
    let setOrder = new OrderedDelivered();
    let response = await setOrder.postDataAuth(formData, logout);

    setUpdate((prev) => !prev);
    GetSalesDetail();
    sendNotification(UseerrorOrsuccess(response));
    setLoading(false);
    /*     setOpen(false) */
  };


  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {data.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {data.presentation.barcode}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.inventory?.[0]?.available}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {/*           <TextField
            fullWidth
            name="first_name"
            values={cantidad}
            onChange={handleCantidad}
            error={error ? true : false}
            helperText={error ? error : ""}
            label="Cantidad"
            margin="dense"
            size="small"
            variant="outlined" 
          /> */}
          <form onSubmit={formik.handleSubmit}>
            <TextField
              /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
              type="number"
              fullWidth
              label="Cantidad"
              size="small"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity ? formik.errors.quantity : ""}
            />
          </form>
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <form onSubmit={formik.handleSubmit}>
          <LoadingButton
            loading={loading}
            fullWidth
            type="submit"
            variant="text"
            /* onClick={() => SentDelivered()} */
            sx={{ color: "primary" }}
          >
            Entregar
          </LoadingButton>
        </form>
      </TableCell>
    </TableRow>
  );
};

export default SalesDelivered;
