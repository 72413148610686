import React, { useState } from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import DropMenu from '../../components/dropMenu'
import CommentService from "../../api/commentService";
import EditGenericComments from "./editModals/editComments";
import GenericComments from '../NewComment'
import { Card } from "../../pages/catalog/articleDetail/articleData";
import CloseIcon from '@mui/icons-material/Close';


const Comments = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false)
  const [dense, /* setDense */] = React.useState(false);
  const [idToEdit, setIdToEdit] = useState('');
  const [openDrawer, setOpenDrawer] = useState({});
  const ScreenSize = window.matchMedia("(max-width: 1443px)").matches;

  return (

    <Card title={'Comentarios'}>

      <EditGenericComments
        data={idToEdit}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        UpdateInfo={UpdateInfo}
        business={true}
        target={target}
        open={openDrawer}
        toggleDrawer={() => { }}
        close={() => setOpenDrawer({ right: false })}
      />


      <Grid item xs sx={{ maxHeight: '300px', overflowY: 'scroll', scrollbarWidth: "thin" }}>
        <List dense={dense}>
          {data.comments && data.comments.map((comment, index) => (
            <Grid display={'flex'} justifyContent={'space-between'} sx={{ p: '8px 0' }}>
              <div style={{ padding: '8px 0', display: 'flex' }}>
                <Avatar sx={{ width: 35, height: 35 }}>
                  <CommentIcon sx={{ fontSize: 25 }} />
                </Avatar>

                <Grid
                  sx={{ p: '0 16px', alignItems: 'center' }}
                  key={index}
                >
                  <Typography

                    sx={{
                      width: ScreenSize ? '200px' : '100%',
                      display: 'block',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                      margin: 0,
                      maxWidth: '100%',
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      p: 0
                    }}
                  >
                    {comment.comments}
                  </Typography>
                </Grid>
              </div>
              <DropMenu
                service={CommentService}
                row={comment}
                id={comment.id}
                update={UpdateInfo}
                open={open}
                setOpenDrawer={setOpenDrawer}
                setIdToEdit={setIdToEdit}
              />

            </Grid>

          ))}

        </List>
        <GenericComments data={data} UpdateInfo={UpdateInfo} business={true} target={target} />
      </Grid>
    </Card>

  );
}

export default Comments;