import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IconButton, Paper, TextField, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import "../scss/loginRecover.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuth } from "user-context-fran-dev";
import { useLocation } from "react-router-dom";
import { initValResetPass, valSchResetPass } from "../utils/formHelper";
import { ResetPass } from "../api/userLogin";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { useSnackbar } from "notistack";

const ResetPassword = ({ theme }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  /*     const [uid, setUid] = useState("")
    const [token, seToken]= useState("") */
  const [msg, sendNotification] = useNotification();
  const uid = new URLSearchParams(location.search).get("uid");
  const token = new URLSearchParams(location.search).get("token");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!uid || !token) {
      navigate("/login");
    }
  }, []);

  const formik = useFormik({
    initialValues: initValResetPass(uid, token),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchResetPass()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);

      let setPass = new ResetPass();
      let response = await setPass.postData(formData);

      if (response) {
        if (response.name !== "Error" || response.name !== "Network Error") {
          enqueueSnackbar(
            "La contraseña se ha restablecido con la nueva contraseña.",
            {
              variant: "success",
            }
          );
        } else {
          sendNotification(UseerrorOrsuccess(response));
        }
      }

      resetForm();
      setLoading(false);
    },
  });

  return (
    <Box
      sx={{
        px: 3,
        bgcolor: "background.default",
      }}
      className={`box-container `} /* img-${theme} */
    >
      <Paper
        elevation={12}
        sx={
          theme === "light"
            ? { bgcolor: "rgba(255, 255, 255, 0.627)" }
            : { bgcolor: "rgba(16, 16, 16, .9)" }
        }
        className="paperForm formSizeResetP"
      >
        <Grid item className="grid-container">
          <Grid item md={12}>
            <AccountCircleIcon sx={{ fontSize: 60 }} />
          </Grid>
          <Grid item md={12}>
            <Typography variant="h5" className="title">
              <strong>Restablecer contraseña</strong>
            </Typography>
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Grid item md={12}>
              <TextField
                fullWidth
                name="new_password1"
                values={formik.values.new_password1}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password1 &&
                  Boolean(formik.errors.new_password1)
                }
                label="Contraseña"
                margin="dense"
                variant="outlined"
                size="small"
                autoComplete="none"
                helperText={
                  formik.touched.new_password1
                    ? formik.errors.new_password1
                    : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                name="new_password2"
                values={formik.values.new_password2}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password2 &&
                  Boolean(formik.errors.new_password2)
                }
                label="Verificar contraseña"
                margin="dense"
                variant="outlined"
                autoComplete="none"
                size="small"
                helperText={
                  formik.touched.new_password2
                    ? formik.errors.new_password2
                    : ""
                }
              />
            </Grid>

            <Grid item md={12} sx={{ marginTop: "15px" }}>
              <LoadingButton
                fullWidth
                type="submit"
                loading={loading}
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Enviar
              </LoadingButton>
            </Grid>

            <Grid item md={12} sx={{ marginTop: "15px" }}>
              <IconButton onClick={() => navigate("/")}>
                <Typography variant="subtitle2" color="text.primary">
                  Iniciar sesión
                </Typography>
              </IconButton>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
