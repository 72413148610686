import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/GenericModal";
import { Grid, Divider, TextField, Box, IconButton, Stack, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValRecivedTransfer,
  valSchRecivedTransfer,
} from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import {
  TransferDeliver,
  TransferReceivedApi,
} from "../../../api/transferService";
import ImageSize from "../../../components/ImageSize";
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { formatNumber } from "../../../utils/functions";
import { ProductModelAvailableService } from "../../../api/productService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";

const TransferReceived = ({
  open,
  setOpen,
  data,
  article,
  GetPurchaseDetail,
  toggleDrawer,
  close
}) => {
  const [msg, sendNotification] = useNotification();
  const pending = data.pending_deliver > 0
  const labelQuantity = ["Transferir", "Pendiente entregar", "Pendiente recibir"]
  const prop_path = ["quantity", "pending_deliver", "pending_receive"]
  const [loading, setLoading] = useState(false);
  const [dataAvalible, setDataAvalible] = useState(false);
  const { logout } = useAuth();

  useEffect(() => {
    if (open.right === true) {
      getAvailabeProduct();
    }
  }, [open])

  const getAvailabeProduct = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "pres",
        value: `${data.presentation.id}`,
      });
      irequestFilter.push({ key: "wh", value: `${article.warehouse_source.id}` });
      let productInventory = new ProductModelAvailableService();
      let response = await productInventory.filter(irequestFilter, logout);
      setDataAvalible(response?.data[0]);
    })();
  };

  const formik = useFormik({
    initialValues: initValRecivedTransfer(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchRecivedTransfer(data)),
    onSubmit: async (formData) => {
      setLoading(true);
      let setOrder = "";
      formik.values.transfer_id = data.id;
      if (data.pending_deliver > 0) {
        setOrder = new TransferDeliver();
      } else {
        setOrder = new TransferReceivedApi();
      }
      const response = await setOrder.postDataAuth(formData, logout);

      sendNotification(UseerrorOrsuccess(response));

      GetPurchaseDetail();
      setLoading(false);
      close();
    },
  });

  return (
    <GenericDrawer
      open={open}
      toggleDrawer={toggleDrawer}
      anchor={"right"}
      drawerWidth="30%"
    >
      <Box p={'16px'} >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Vista previa</b>
          </Typography>

          <IconButton onClick={close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          direction="row"
          justifyContent="space-around"
          m={'24px 0'}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <ImageSize
                  img={data?.model?.image?.url ? data?.model?.image?.url : ""}
                  weight={2}
                />
              </Grid>
              <Grid item xs={10}>
                <Grid item xs={12} className="">
                  <h3>{data?.model?.product?.name}</h3>
                </Grid>
                <Grid item xs={12} >
                  <p>{data?.model?.product?.sku}</p>
                </Grid>
                <Typography color={data?.model?.product?.is_active ? 'success' : 'error'}>
                  {data?.model?.product?.is_active ? 'Activo' : "Inactivo"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid display={'flex'} p={'8px 0'}>
            <Typography
              mr={'8px'}
              fontSize={'16px'}>
              Modelo:
            </Typography>
            <Stack>
              <Typography>
                {data?.model?.name}
              </Typography>
              <Typography color={data?.model?.is_active ? 'success' : 'error'}>
                {data?.model?.is_active ? 'Activo' : "Inactivo"}
              </Typography>
            </Stack>
            <Typography>

            </Typography>
          </Grid>
          <Divider />
          <Grid display={'flex'} p={'8px 0'}>
            <Typography fontSize={'16px'} mr={'5px'}>Variante: </Typography>
            <span>
              <Typography>
                {data?.presentation?.name}
              </Typography>
              <Typography>
                {data?.presentation?.barcode}
              </Typography>
              <Typography color={data.presentation.is_active ? 'success' : 'error'}>
                {data?.presentation?.is_active ? 'Activo' : "Inactivo"}
              </Typography>
            </span>
          </Grid>
          <Divider />
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            p={'16px 0'} >
            {labelQuantity.map((val, index) => (
              <Grid
                key={index}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'} >
                <Typography
                  align='center'
                  sx={{
                    color:
                      !pending && val === labelQuantity[1] ? 'gray'
                        : pending && val === labelQuantity[2] ? 'grey'
                          : 'black',
                    fontWeight:
                      pending && val === labelQuantity[1] ? 1000
                        : !pending && val === labelQuantity[2] ? 1000
                          : 500,
                    fontSize: '24px'
                  }}
                >
                  {formatNumber(data?.[prop_path[index]])}
                </Typography>
                <Typography
                  align='center'
                  sx={{
                    color:
                      !pending && val === labelQuantity[1] ? 'gray'
                        : pending && val === labelQuantity[2] ? 'grey'
                          : 'black',
                    fontWeight:
                      pending && val === labelQuantity[1] ? 1000
                        : !pending && val === labelQuantity[2] ? 1000
                          : 500,
                    fontSize: '16px',
                    m: '0 8px'
                  }}
                >
                  {val}
                </Typography>
              </Grid>
            ))}
          </Stack>
          <Paper elevation={4} sx={{ backgroundColor: "background.default" }}>
            <p style={{ padding: "8px 16px" }}>Inventario:</p>
            <Grid container p={"8px 16px"}>

              <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p>Ord: {dataAvalible?.inventory?.[0]?.ordered}</p>
                <p>Dis: {dataAvalible?.inventory?.[0]?.available}</p>
                <p>Res: {dataAvalible?.inventory?.[0]?.reserved}</p>
              </span>
              <Grid
                component={'form'}
                alignItems="center"
                display={'flex'}
                justifyContent={'space-between'}
                onSubmit={formik.handleSubmit}
                width={'100%'}
              >
                <TextField
                  type="number"
                  name="quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.quantity &&
                    Boolean(formik.errors.quantity)
                  }
                  helperText={
                    formik.touched.quantity ? formik.errors.quantity : ""
                  }
                  autoComplete="none"
                  label="Cantidad"
                  margin="dense"
                  size="small"
                  variant="outlined"
                />
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "primary",
                    borderRadius: 60,
                    ml: 1
                  }}
                >
                  <p>
                    {pending ? "Entregadar" : "Recibido"}
                  </p>
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </GenericDrawer>
  );
};

export default TransferReceived;
