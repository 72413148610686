import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import ArticlesListScrap from "../../../../components/inventario/tables/ArticlesListScrap";
import HeaderGeneric from "../../../../components/inventario/HeaderGeneric";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import ScrapService from "../../../../api/inventory/scrapService";
import SearchAddScrapArticle from "./addScrapArticle/searchAddScrapArticle";
import { STATUSSCRAP, STATUSSCRAPCOLOR } from "../../../../utils/constants";

const ScrapDetails = ({ setReload }) => {
  const { id } = useParams();
  const { logout } = useAuth();
  const [data, setData] = useState();
  const [reload, setReloadPage] = useState(false);
  const getScrap = new ScrapService();
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);

  const reloadDataOforder = () => {
    (async () => {
      let response = await getScrap.getAllByIdAuth(id, logout);
      setData(response.data);
      setReload(true);
      setLoading(false);
    })();
  };

  useEffect(() => {
    (async () => {
      let response = await getScrap.getAllByIdAuth(id, logout);
      window.localStorage.setItem("menu", 16);
      setData(response.data);
    })();
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 5);
    };
  }, [reload]);

  const cancel = () => {
    (async () => {
      setLoading(true);
      let response = await getScrap.editDataAuth(id, { status: 4 }, logout);

      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
    })();
  };

  const finishOrder = () => {
    (async () => {
      setLoading(true);
      let response = await getScrap.editDataAuth(id, { status: 2 }, logout);
      //.log(response.data)
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
    })();
  };

  return (
    <>
      {data && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={11} className="details-view-header">
            <HeaderGeneric
              leftTitle={{
                status: STATUSSCRAP[data.status],
                color: STATUSSCRAPCOLOR[data.status],
              }} //titulo del header izquierdo
              cancelButton={{ handleClick: cancel, loading: loading }} //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
              titleButton={{
                tittle: "Finalizar",
                enable: data.scrap ? true : false,
              }} // el boton a lado derecho de cancelar este solo es para el titulo
              handleButton={{ handleClick: finishOrder, loading: loading }} //la funcion del boton
              rightTitle="Orden de desecho"
              name={`${data.user.first_name} ${data.user.last_name}`}
              noOrder={data}
              date={data.date_created}
              general={data.warehouse.name}
              reloadDataOforder={reloadDataOforder}
              type={"scrap"}
              tate={{ type: "scrap" }}
            ></HeaderGeneric>
          </Grid>

          {data.status === 1 && (
            <Grid item xs={11} className="article-expandable-view">
              <SearchAddScrapArticle
                type="scrap_order"
                data={data}
                reloadDataOforder={reloadDataOforder}
              ></SearchAddScrapArticle>
            </Grid>
          )}
          <Grid item xs={11} className="details-view">
            <ArticlesListScrap data={data}></ArticlesListScrap>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ScrapDetails;
