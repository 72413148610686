import BusinessManages, { BusinessSettings } from "../businessManages";
import { DomainList } from "../landing/domainList";

export const CreateMenu = (logout) => {
  let newData = [];

  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessManages();
      let response = await useBusines.getAllByIdAuth("1", logout);
      GetListPages(response.data.unique_id);
    })();
  };

  const GetListPages = async (unique_id) => {
    let irequestFilter = [];
    irequestFilter.push({ key: "business", value: `${unique_id}` }); // TODO:  test.74-208-117-143.plesk.page ${domain}
    let getArticle = new DomainList();
    let response = await getArticle.filter(irequestFilter);

    for (const key in response.data) {
      if (response.data[key].is_active) {
        newData.push({
          text: response.data[key].name,
          url: response.data[key].link.replace("/", ""),
        }); //{text: element.name, url: element.replace("/", "")}
      }
    }
  };

  UpdateStyles();
  return newData;
};

export const ChatHistory = (logout) => {
  let newData = [];

  const UpdateStyles = () => {
    (async () => {
      let useBusines = new BusinessSettings();
      let response = await useBusines.getAllByIdAuth("1", logout);
      GetListPages(response.data.domain);
    })();
  };

  const GetListPages = async (domain) => {
    let irequestFilter = [];
    irequestFilter.push({ key: "domain", value: `${domain}` }); // TODO:  test.74-208-117-143.plesk.page ${domain}
    let getArticle = new DomainList();
    let response = await getArticle.filter(irequestFilter);

    for (const key in response.data) {
      if (response.data[key].is_active) {
        newData.push({
          text: response.data[key].name,
          url: response.data[key].link.replace("/", ""),
        }); //{text: element.name, url: element.replace("/", "")}
      }
    }
  };

  UpdateStyles();
  return newData;
};
