import React, { useEffect, useState } from "react";
import { Grid, Divider, TextField, Paper, Box, IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValRecivedPurchase,
  valSchRecivedPurchase,
} from "../../../utils/formHelper";
import { OrderedReceived } from "../../../api/orderedReceived";
import { useAuth } from "user-context-fran-dev";
import ImageSize from "../../../components/ImageSize";
import { formatNumber } from "../../../utils/functions";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { ProductModelAvailableService } from "../../../api/productService";

const PurchaseRecieved = ({
  openDrawer,
  toggleDrawer,
  setOpen,
  data,
  close,
  article,
  GetPurchaseDetail,
}) => {

  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [dataAvalible, setDataAvalible] = useState(false);

  useEffect(() => {
    getAvailabeProduct();
  }, [])

  const formik = useFormik({
    initialValues: initValRecivedPurchase(data, article.warehouse),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchRecivedPurchase(data)),
    onSubmit: async (formData) => {
      setLoading(true);
      if (formik.values.barcode === "") {
        formik.values.barcode = null;
      }
      let setOrder = new OrderedReceived();
      let response = await setOrder.postDataAuth(formData, logout);
      GetPurchaseDetail();
      sendNotification(UseerrorOrsuccess(response));
      setLoading(false);
      close();
    },
  });

  const getAvailabeProduct = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "pres",
        value: `${data.model.id}`,
      });
      irequestFilter.push({ key: "wh", value: `${article.warehouse.id}` });
      let productInventory = new ProductModelAvailableService();
      let response = await productInventory.filter(irequestFilter, logout);
      setDataAvalible(response?.data[0]);
      setLoading(false);
    })();
  };

  return (
    <GenericDrawer
      open={openDrawer}
      toggleDrawer={toggleDrawer}
      anchor={"right"}
      drawerWidth="30%">
      <Box p={'16px'}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Variantes</b>
          </Typography>

          <IconButton onClick={close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          direction="row"
          justifyContent="space-around"
          m={'24px 0'}
        >
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <ImageSize
                  img={data?.model?.image?.url ? data?.model?.image?.url : ""}
                  weight={2}
                />

              </Grid>
              <Grid item xs={10}>
                <Grid item xs={12} className="">
                  <h3>{data?.model?.product?.name}</h3>
                </Grid>
                <Grid item xs={12} >
                  <p>{data?.model?.product?.sku}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid display={'flex'} p={'8px 0'}>
            <Typography fontSize={'16px'}>Modelo: {data?.model?.name}</Typography>
          </Grid>


          <Divider />
          <Grid display={'flex'} p={'8px 0'}>
            <Typography fontSize={'16px'} mr={'5px'}>Variante: </Typography>
            <span>
              <Typography>
                {data?.presentation?.name}
              </Typography>
              <Typography>
                {data?.presentation?.barcode}
              </Typography>
              <Typography color={data.presentation.is_active ? 'success' : 'error'}>
                {data?.presentation?.is_active ? 'Activo' : "Inactivo"}
              </Typography>
            </span>
          </Grid>
          <Divider />
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'center'}
            p={'16px 0'} >
            <Grid display={'flex'} flexDirection={'column'} >
              <h2>{formatNumber(data?.quantity)}</h2>
              <h4>Ordenados</h4>
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <h2>{formatNumber(data?.quantity_pending, 2)}</h2>
              <h4>Pendientes</h4>
            </Grid>
          </Stack>


          {data.status !== 3 ? (
            <Paper elevation={4} sx={{ backgroundColor: "background.default" }}>
              <p style={{ padding: "8px 16px" }}>Inventario:</p>
              <Grid container p={"8px 16px"}>

                <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <p>Ord: {dataAvalible?.inventory?.[0]?.ordered}</p>
                  <p>Dis: {dataAvalible?.inventory?.[0]?.available}</p>
                  <p>Res: {dataAvalible?.inventory?.[0]?.reserved}</p>
                </span>
                <Grid item xs={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} alignItems="center" >

                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          name="cost"
                          value={formik.values.cost} /* formatNumber */
                          /* defaultValue={data.quantity_pending} */
                          onChange={formik.handleChange}
                          placeholder={formatNumber(data?.cost)}
                          type="number"
                          /* onDoubleClick={()=>{formik.values.cost = data.cost}} */
                          error={
                            formik.touched.cost && Boolean(formik.errors.cost)
                          }
                          helperText={
                            formik.touched.cost ? formik.errors.cost : ""
                          }
                          autoComplete="none"
                          label="Costo"
                          margin="dense"
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          type="number"
                          fullWidth
                          name="quantity"
                          value={formik.values.quantity}
                          /* defaultValue={data.quantity_pending} */
                          onChange={formik.handleChange}
                          error={
                            formik.touched.quantity &&
                            Boolean(formik.errors.quantity)
                          }
                          helperText={
                            formik.touched.quantity ? formik.errors.quantity : ""
                          }
                          autoComplete="none"
                          label="Cantidad"
                          margin="dense"
                          size="small"
                          variant="outlined"
                        />
                        {/* <p>{new Intl.NumberFormat('en', { maximumSignificantDigits: 10 }).format(formik.values.cost)}</p> */}
                      </Grid>

                      <Grid item xs></Grid>

                      <Grid item xs={3}>
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{
                            color: "primary",
                            borderRadius: 60
                          }}
                        >
                          Recibir
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Grid container justifyContent="center">
              {" "}
              <Typography variant="h5">Recibido</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </GenericDrawer>
  );
};

export default PurchaseRecieved;
