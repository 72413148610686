import { useSnackbar } from "notistack";

import { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import Button from "@mui/material/Button";

export const useNotification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar /* closeSnackbar */ } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (conf.custom) {
      enqueueSnackbar(conf.custom, {
        variant: conf.variant,
        //autoHideDuration: 1000,
        //action
      });
    }
    if (conf.msg?.status == 500) {
      enqueueSnackbar("Ocurrió un error interno en el servidor", {
        variant: "error",
      });
    } else {
      if (conf.msg && conf.variant) {
        if (conf.variant == "success") {
          enqueueSnackbar(conf.msg, {
            variant: "success",
            autoHideDuration: 4000,
            action,
          });
        } else if (conf.msg.data?.error) {
          enqueueSnackbar(conf.msg.data.error, {
            variant: "error",
            //autoHideDuration: 1000,
            //action
          });
          if (conf.msg.data?.error_code) {
            for (var key in conf.msg.data.error_code) {
              conf.msg.data.error_code[key].forEach(function (elemento) {
                enqueueSnackbar(elemento, {
                  variant: "error",
                  //autoHideDuration: 1000,
                  //action
                });
              });
            }
          }
        } else {
          for (var key in conf.msg?.data) {
            if (Array.isArray(conf.msg.data[key])) {
              //Comprobamos si lo que regresa es un arreglo de datos
              conf.msg.data[key].forEach(function (elemento) {
                enqueueSnackbar(elemento, {
                  variant: "error",
                  //autoHideDuration: 1000,
                  //action
                });
              });
            } else {
              enqueueSnackbar(conf.msg.data[key], {
                //Sino, mostrara directamente el snackbar
                variant: "error",
                //autoHideDuration: 1000,
                //action
              });
            }
          }
        }
      }
    }
  }, [conf]);

  return [conf, setConf];
};

export const UseerrorOrsuccess = (response) => {
  if (response.status >= 400) {
    return { msg: response, variant: "error" };
  } else if (response.status >= 300 && response.status < 400) {
    return { msg: response, variant: "warning" };
  } else {
    return { msg: "Operacion exitosa", variant: "success" };
  }
};
