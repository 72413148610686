import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TableInfo from "./tableInfo";
import { useAuth } from "user-context-fran-dev";
import PurchaseService from "../../../api/purchaseService";
import { STATUS, STATUSCOLOR } from "../../../utils/constants";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import CurrencyFormat from "react-currency-format";
import { DateFormat } from "../../../components/DateFormat";
import Chip from "@mui/material/Chip";
import { DrawerSupplier } from "../../../components/inventario/DrawerSupplier";
import OptionButton from "../../../components/inventario/optionButton";
import DrawerDetails from "../../../components/inventario/DrawerDetails";

const PurchasesTable = (props) => {
  const { data, UpdateInfo } = props;
  const beginDate = data.date_created;
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <TableRow
        hover
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        style={{ height: 10 }}
      >
        <TableCell align="left" >
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle2"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.no_order}
              </Typography>
            }
            subheader={data.warehouse.name}

          />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.supplier?.business_name ?? "--"}
              </Typography>
            }

          />
        </TableCell>
        {/*ESTE ES PARA EL TIPO DE ORDEN */}
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.type == 1 ? "Compra" : "Cotización"}
              </Typography>
            }
            subheader={DateFormat({ beginDate })}
          />
        </TableCell>

        <TableCell align="left">
          <Chip
            label={STATUS[data?.status]}
            color={STATUSCOLOR[data?.status]}
            className="chip-with"
            variant="outlined"
          />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <CurrencyFormat
                  value={data?.total}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            }
          />
        </TableCell>
        <TableCell>
          <OptionButton
            menu={11}
            id={data?.id}
            openDrawer={() => setOpenDrawer({ right: true })}
            UpdateInfo={UpdateInfo}
            type={"purches"}
          />
        </TableCell>
      </TableRow>
      {openDrawer && (
        <DrawerDetails
          open={openDrawer}
          toggleDrawer={() => { }}
          data={data}
          close={() => setOpenDrawer({ right: false })}
          type={"purches"}
        />
      )}
    </>
  );
};

export default PurchasesTable;
