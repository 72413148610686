import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import InicioCard from "./CarImg";
import { getAppsCars, getAppsPos } from "../../api/data/inicioData";
import "../../scss/home.scss";
import { BusinessGroups } from "../../api/businessGroups";
import { useAuth } from "user-context-fran-dev";
import UserManages from "../../api/userManages";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Homenotifications from "./homeNotifications";
import { Notifications } from "../../api/common/notification";
import { BottonModalDiag, ModalRight } from "../../components/GenericsModals";
import { Grid, Divider, Button, Modal, List, IconButton } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import googleplay from "./../../assets/GooglePlay.png";
import appstore from "./../../assets/Appstore.png";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import { BusinessSubscription } from "../../api/businessManages";
import BasicModal from "../../components/ModalPlans";

const InicioApps = ({ admin }) => {
  const [appsGroup, setAppsGroup] = useState("");
  const { logout, auth } = useAuth();
  const [user, setUser] = useState("");
  const [noty, setNoty] = useState("");
  const [open, setOpen] = useState(false);
  const [openModalPos, setOpenModalPos] = useState(false);
  const [type, setType] = useState();
  const [view, setView] = React.useState(1);
  const [params, setParams] = useSearchParams("");
  //Modal right state
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("initial");
  const [id, setId] = useState(0);
  const [showId, setShowId] = useState(0);
  const [activeData, setActiveData] = useState(0);
  const data = getAppsCars();
  const [loading, setLoading] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [subscription, setSubscription] = useState("");
  const navigate = useNavigate();

  const [trigger, setTrigger] = useState({
    drawer1: false,
    drawer2: false,
    drawer3: false,
  });

  const [openDrawerPlans, setOpenDrawerPlans] = useState({
    top: false,
    right: false,
    right2: false,
    right3: false,
    right4: false,
    right5: false,
  });
  const toggleDrawerPlans = (anchor, open, drawer) => (event) => {
    setOpenDrawerPlans({ ...openDrawerPlans, [anchor]: open });
    setTrigger((prevState) => ({
      ...prevState,
      [drawer]: !prevState[drawer],
    }));
  };

  {
    /* POSIntegration This section has been added to have the pos integrated in the admin project */
  }
  const posData = getAppsPos();

  const size = {
    maxWidth: 295,
    minWidth: 295,
    maxHeight: 300,
    margin: "10px",
  };

  useEffect(() => {
    setParams(params);
    UpdateAppAcces();
    GetCurrentUser();
    GetNotifications();
    GetBusinessSub();
  }, []);

  const GetNotifications = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ key: "is_active", value: "true" });
      const getNoti = new Notifications();
      const response = await getNoti.filter(irequestFilter, logout);
      setNoty(response.data);
    })();
  };

  const GetCurrentUser = () => {
    (async () => {
      const getUser = new UserManages();
      const response = await getUser.getAllByIdAuth(auth.user.pk, logout);
      setUser(response.data);
    })();
  };

  const UpdateAppAcces = () => {
    (async () => {
      let getBranchs = new BusinessGroups();
      let response = await getBranchs.getAllAuth(logout);
      setAppsGroup(response.data);
    })();
  };

  const GetBusinessSub = () => {
    (async () => {
      const getSub = new BusinessSubscription();
      try {
        const response = await getSub.getAllAuth(logout);
        setSubscription(response.data);  // Establece los datos de la suscripción
      } catch (error) {
        console.error("Error al obtener la suscripción:", error);
        // Aquí podrías manejar el error si es necesario
      } finally {
        setLoadingPlan(false);  // Finaliza la carga, independientemente de si hubo error
      }
    })();
  };

  useEffect(() => {
    // Simula el retraso en la carga de datos (2 segundos en este caso)
    setTimeout(() => {
      setLoadingPlan(false);  // Cambia el estado a "cargado" después de 2 segundos
    }, 2000);  // Aquí puedes ajustar el tiempo de simulación
  }, []);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handleChangeData = (data) => {
    setActiveData(data);
  };

  const TypeModal = () => {
    setOpenModalPos(true);
  };

  const handleCloseModal = () => {
    setOpenModalPos(false);
  };

  const handleChange = (event) => {
    params.set("tab", `${event.target.value}`);
    setView(event.target.value);
    setParams(params);
  };

  const handleOpenPlans = () => {
    toggleDrawerPlans();
  };

  const item = [
    {
      title: "Prompts personalizados",
      icon: <ChatBubbleOutlineIcon />,
      id: 1,
      url: "/pos/login/",
    },
    {
      title: "Inventario",
      icon: <AssignmentTurnedInOutlinedIcon />,
      id: 2,
      url: "/pos/login/",
    },
    {
      title: "Tienda en línea",
      icon: <StorefrontOutlinedIcon />,
      id: 3,
      url: "/pos/login/",
    },
    {
      title: "Punto de venta",
      icon: <LocalGroceryStoreOutlinedIcon />,
      id: 4,
      url: "/pos/login/",
    },
  ];
  const item2 = [
    {
      title: "Analizar las tebdencias de la industria",
      id: 1,
    },
    {
      title: "Análisis competitivo",
      id: 2,
    },
    {
      title: "Análisis de mercado",
      id: 3,
    },
    {
      title: "Análisis de la cadena de valor",
      id: 4,
    },
  ];
  const item3 = [
    {
      id: 1,
      title: "Analizar las tebdencias de la industria",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat,",
    },
  ];

  return (
    <div className="contenedor-apps">
      {/* <HelperModal open={open} setOpen={setOpen} type={type} />*/}
      <ModalPos
        openModalPos={openModalPos}
        setOpenModalPos={setOpenModalPos}
        handleCloseModal={handleCloseModal}
      ></ModalPos>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ md: "start" }}
        alignItems="center"
        mb="16px"
      >
        <Grid md={4} sx={{ marginBottom: "16px", mr: "16px" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ color: "text.primary" }}
          >
            <strong>Tus aplicaciones</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Grid
          sx={{ paddingTop: "15px" }}
          container
          justifyContent={{ xs: 'center', md: 'flex-start' }}
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 4, md: 12 }}
          xs={12}
          md={9}
        >
          {appsGroup && user ? (
            data.map((apps, index) => (
              <Grid key={index} padding={{ xs: "10px", md: "16px" }}>
                <InicioCard
                  data={apps}
                  TypeModal={TypeModal}
                  buy={
                    appsGroup.find((data) => apps.id === data.id) !== undefined
                  }
                  access={
                    user.groups
                      ? user.groups.find((data) => apps.id === data.id)
                      : false
                  }
                />
              </Grid>
            ))
          ) : (
            <Box
              sx={{ position: "absolute", top: "40%", left: "50%", mt: "16px" }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <Box>
            <Paper elevation={3}>
              <Grid
                container
                sx={{
                  padding: "16px",
                }}
              >
                <Stack spacing={2} width={"100%"}>
                  {loadingPlan ? (
                    // Si está cargando, muestra el indicador de carga
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontWeight: "bold", color: "#60269E" }}
                      >
                        {subscription?.membership?.plan ?? "Sin plan"}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        Resumen de tu suscripción
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          Usuarios
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {subscription?.membership?.user === null ? "Ilimitado" : `${subscription?.user ?? 0}/${subscription?.membership?.user}`}
                        </Typography>
                      </Box>
                      {subscription?.user !== null && (
                        <Divider
                          sx={{
                            height: 4,
                            backgroundColor: subscription?.user < subscription?.membership?.user ? '#67C6D6' : '#FFCD29',
                            marginY: 2,
                          }}
                        />)}
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Sucursales
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {subscription?.membership?.user === null ? "Ilimitado" : `${subscription?.branch ?? 0}/${subscription?.membership?.branch}`}
                        </Typography>
                      </Box>
                      {subscription?.branch !== null && (
                        <Divider
                          sx={{
                            height: 4,
                            backgroundColor: subscription?.branch < subscription?.membership?.branch ? '#67C6D6' : '#FFCD29',
                            marginY: 2,
                          }}
                        />)}
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Productos
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {subscription?.membership?.product === null ? "Ilimitado" : `${subscription?.product ?? 0}/${subscription?.membership?.product}`}
                        </Typography>
                      </Box>
                      {subscription?.product !== null && (
                        <Divider
                          sx={{
                            height: 4,
                            backgroundColor: subscription?.product < subscription?.membership?.product ? '#67C6D6' : '#FFCD29',
                            marginY: 2,
                          }}
                        />
                      )}
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Cajas
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {subscription?.membership?.user === null ? "Ilimitado" : `${subscription?.cash_register ?? 0}/${subscription?.membership?.cash}`}
                        </Typography>
                      </Box>
                      {subscription?.cash_register !== null && (
                        <Divider
                          sx={{
                            height: 4,
                            backgroundColor: subscription?.cash_register < subscription?.membership?.cash ? '#67C6D6' : '#FFCD29',
                            marginY: 2,
                          }}
                        />)}
                      <Typography sx={{ paddingTop: '1em' }} variant="subtitle1" gutterBottom>
                        {subscription?.message ? "" : "Te estás quedando sin usuarios y sucursales en tu plan gratuito. Actualiza tu subscripción para que tu negocio siga creciendo sin límites."}
                      </Typography>

                      <div style={{ marginTop: "2em" }}>
                        <LoadingButton
                          loading={loading}
                          type="submit"
                          variant="contained"
                          size="small"
                          onClick={toggleDrawerPlans("top", true)}
                          sx={{
                            color: "#fff",
                            borderRadius: "1em",
                            alignSelf: "flex-start",
                            padding: "4px 12px",
                            width: "130px",
                            fontSize: "15px",
                          }}
                        >
                          Ver planes
                        </LoadingButton>
                        <BasicModal
                          toggleDrawerPlans={toggleDrawerPlans}
                          openDrawerPlans={openDrawerPlans}
                          close={toggleDrawerPlans("top", false)}
                          trigge={trigger}
                        />
                      </div>
                    </>
                  )}
                </Stack>
              </Grid>
            </Paper>
          </Box>
        </Grid>

      </Grid>

      <Homenotifications noty={noty} />
    </div>
  );
};

const ModalPos = ({ openModalPos, setOpenModalPos, handleCloseModal }) => {
  return (
    <ModalRight
      title={<strong>Punto de venta</strong>}
      open={openModalPos}
      setOpen={setOpenModalPos}
    >
      <Grid container>
        <Grid item xs={12} sx={{ paddingTop: "30px" }}>
          <a
            href="https://pos.keskinube.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button endIcon={<OpenInNewIcon />}>Abrir en el navegador</Button>
          </a>
        </Grid>
        <Grid item xs={12} mt="26px">
          <Typography sx={{ paddingLeft: "7px" }}>Descargar la app:</Typography>
          <Grid item xs={12} mt="16px">
            <a
              href="https://apps.apple.com/us/app/keski-pos/id1661375259"
              target="_blank"
            >
              <img
                src={appstore}
                style={{ width: "200px", height: "80px", cursor: "pointer" }}
              ></img>
            </a>
          </Grid>
          <Grid item xs={12} mt="">
            <a
              href="https://play.google.com/store/apps/details?id=com.codenationstudio.app.keski_pos"
              target="_blank"
            >
              <img
                src={googleplay}
                style={{
                  width: "185px",
                  height: "72px",
                  cursor: "pointer",
                  paddingLeft: "8px",
                }}
              ></img>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          sx={{
            position: "absolute",
            bottom: 50,
            left: { xs: 250, sm: 300 }, // 250 for xs devices, 300 for sm and larger
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: "100%" }}
            onClick={handleCloseModal}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </ModalRight>
  );
};

export default InicioApps;
