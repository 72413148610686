import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  styled,
  Paper,
  Stack,
  TextField,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { InventoryService, InventoryServiceSalesOrder } from "../../../../api/inventoryService";
import { GenericDrawer } from "../../../../components/keskiCreator/genericDrawer";
import { UseerrorOrsuccess, useNotification } from "../../../../hooks/snackBarResponse";
import ImageSize from "../../../../components/ImageSize";
import { initAddArticleToPurchaseOrder } from "../../../../utils/formHelper";

const Item = styled(Paper)(({ theme }) => ({
  margin: "16px 0",
}));

export default function DrawerArticleSales(props) {
  console.log(props.dataProduct);
  const { id } = useParams();
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const labels = ["Ord:", "Dis:", "Res:"];
  const keys = ["ordered", "available", "reserved"];
  const order_data = labels.map((label, index) => ({
    label,
    key: keys[index],
  }));
  const productName =
    props.dataProduct?.model?.product?.name ||
    props.dataProduct?.product?.name ||
    props.data?.model?.product?.name ||
    props.data?.product?.name;

  const firstFilter = props.type === "purchase"
    ? { filter: "cost", label: "Costo" }
    : { filter: "price", label: "Precio" };


  const FormData = [
    firstFilter,
    { filter: "quantity", label: "Cantidad" },
  ];


  function isNull(element) {
    return element === null;
  }

  const Form = ({ data }) => {
    const availabel = data.inventory?.[0].available
    const [loading, setLoading] = useState(false);
    const [modified, setModified] = useState(false);

    const formik = useFormik({
      initialValues: initAddArticleToPurchaseOrder(
        data, props.data || props.dataProduct
      ),
      validationSchema: false,


      onSubmit: async (formData, { resetForm }) => {
        setLoading(true);
        formik.values.presentation = data?.inventory[0].presentation;
        formik.values.model =
          (data?.inventory[0].model ?? props.dataProduct.model?.id ?? props.dataProduct?.model?.[0].id)
        formik.values.warehouse = data?.inventory[0].warehouse
        resetForm();
        const serviceMap = {
          sales: InventoryServiceSalesOrder,
          purchase: InventoryService,
        };
        const ServiceClass = serviceMap[props.type];
        const service = new ServiceClass();
        const key = props.type
        formData[key] = Number(id);
        let response = ''
        formik.values.sale = id

        if (props.orderData?.articles[0].presentation) {
          response = await service.editDataAuth(props.idArticleInOrder, formData, logout)
        }
        else {
          response = await service.postDataAuth(formData, logout)
        }

        if (Number(response.status) < 300) {
          sendNotification(UseerrorOrsuccess(response));
          props.close();
        } else {
          sendNotification(UseerrorOrsuccess(response));
        }
        formik.values.presentation = null
        formik.values.model = null
        props.reloadDataOforder();
        setLoading(false);
        setModified(false);
      },
    });

    return (
      <Stack
        component="form"
        direction={"row"}
        onSubmit={formik.handleSubmit}
        onChange={() => {
          setModified(true);
        }}
        justifyContent={"space-between"}
        p={"8px"}
      >
        {FormData.map((field) => (
          <TextField
            key={field.filter}
            label={field.label}
            variant="outlined"
            size="small"
            type={props.type === "cost" || field.filter === "price" ? "text" : "number"}
            name={field.filter}
            value={formik.values[field.filter] || ""}
            onChange={formik.handleChange}
            error={
              formik.touched[field.filter] &&
              Boolean(formik.errors[field.filter])
            }
            helperText={
              formik.touched[field.filter] && formik.errors[field.filter]
            }
            sx={{ width: "30%" }}
            inputProps={{
              ...(field.filter === "price"
                ? { readOnly: true }
                : {}),
              ...(field.filter !== "price" ? { min: 0 } : {}),
            }}
            required={field.filter !== "cost"}
          />
        ))}
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          disabled={!modified}
          sx={{ borderRadius: "60px", textTransform: "none" }}

        >
          {props.dataProduct?.presentations ? "Agregar" : "Actualizar"}
        </LoadingButton>
      </Stack>
    );
  };

  const ProductAtribute = ({ presentation }) => {

    return (
      <>
        {presentation ? (
          <Item elevation={4}>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              p={"8px 8px 0 8px"}
            >
              <Typography>
                <strong>{presentation?.name}</strong>
              </Typography>
              <Typography color={presentation?.is_active ? "green" : "red"}>
                {presentation?.is_active ? "Activo" : "Desactivado"}
              </Typography>
            </Stack>

            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              p={"8px"}
            >
              {order_data?.map((data) => (
                <Typography key={data?.key}>
                  {data?.label} {presentation?.inventory?.[0][data?.key] ?? "0"}{" "}
                  {/* Usar nullish coalescing (??) en lugar de || para verificar solo null o undefined */}
                </Typography>
              ))}
            </Stack>

            <Divider />
            <Form data={presentation} />
          </Item>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No hay presentación disponible.
          </Typography>
        )}
      </>
    );
  };
  return (
    <GenericDrawer
      open={props.openDrawer}
      toggleDrawer={props.toggleDrawer}
      anchor={"right1"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>
              {props.dataProduct?.presentations
                ? "Variante"
                : "Editar producto"}
            </b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid display={"flex"} p={"16px 0"}>
          <div style={{ marginRight: '16px' }}>
            <ImageSize
              img={
                props.dataProduct?.model?.image?.url
                || props.data?.model?.image?.url
                || (Array.isArray(props.dataProduct?.model) ? props.dataProduct?.model[0]?.image?.url : undefined)
              }
              weight={2}
            />
          </div>
          <div>
            <Typography>
              <strong>{productName}</strong>
            </Typography>
            <Typography color="gray">
              {props.dataProduct?.model?.name
                || props.data?.model?.name
                || (Array.isArray(props.dataProduct?.model) ? props.dataProduct.model[0]?.name : "")}
            </Typography>
          </div>
        </Grid>
        {props.dataProduct?.presentations ? (
          <>
            {(props.dataProduct?.presentations || []).map((val, index) => (
              <div key={index}>
                <ProductAtribute presentation={val} />
              </div>
            ))}
          </>
        ) : (
          <ProductAtribute presentation={props.dataProduct} />
        )}
      </Box>
    </GenericDrawer>
  );
}