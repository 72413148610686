import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Grid, Button } from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import { ProductModelService } from "../../api/productService";
import { GenericHeaderExpandable } from "../GenericHeader";
import ImageSize from "../ImageSize";
import { InventoryServicePresentations } from "../../api/inventoryService";
import { ArticleTableSearcher } from "./ArticleSearcher";
import { useNavigate } from "react-router-dom";
import DetailsDrawer from "../../pages/catalog/articleDetail/components/detailsDrawer";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import OptionButton from "./optionButton";

export function Row(props) {
  const {
    row,
    toggleDrawer,
    setDataProduct,
    wh,
    display,
    updateList,
    setDrawerProduct,
  } = props;
  const prop_path = ["name", "sku", "category.name", "is_active"];
  const [open, setOpen] = useState(false);
  const [dataPresentation, setDataResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getinfo(row.id);
  }, []);

  async function getinfo(id) {
    setLoading(true);
    let irequestFilter = [];
    irequestFilter.push({ key: "product", value: `${id}` }); //Modifique el endpoint que se utiliza
    irequestFilter.push({ key: "is_active", value: `true` });

    let products = new ProductModelService();
    let dataRes = await products.filter(irequestFilter, logout);
    setDataResponse(dataRes?.data);
    setLoading(false);
  }

  async function getAvailableDataModal(idmodel, product, model) {
    let irequestFilter = [];
    setDrawerProduct(product);
    irequestFilter.push({ key: "product", value: `${product.id}` });
    irequestFilter.push({ key: "mdl", value: `${idmodel}` });
    irequestFilter.push({
      key: "wh",
      value: `${wh?.warehouse?.id ? wh.warehouse.id : wh}`,
    });

    let serviceInstance = new InventoryServicePresentations();
    let response2 = await serviceInstance.filter(irequestFilter, logout);

    setDataProduct({
      product: row,
      presentations: response2?.data?.[0]?.presentations,
      model: model,
    });
  }

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, key) => acc && acc[key], obj);
  };

  return (
    <>
      <TableRow
        sx={{
          display: display === false ? " " : "none",
          cursor: "pointer",

        }}
        hover
      >
        <TableCell
          align="left"
          sx={{ maxWidth: '200px', display: wh ? "none" : "" }}
        >
          <Grid ml={"8px"}>
            <ImageSize img={row.main_model?.image?.url ?? null} weight={1} />
          </Grid>
        </TableCell>

        {prop_path.map((val, index) => (
          <TableCell
            align="left"
            key={index}
            sx={{
              maxWidth: '200px',
              p: "6px 16px",
            }}
          >
            {val === "is_active" ? (
              <Typography>
                <strong style={{ color: row.is_active ? "green" : "red" }}>
                  {row.is_active ? "Activo" : "Inactivo"}
                </strong>
              </Typography>
            ) : (
              <Typography>{getNestedValue(row, val)}</Typography>
            )}
          </TableCell>
        ))}
        {!wh && (
          <TableCell sx={{ maxWidth: '200px' }}>

            <Typography>
              {row.type === 1
                ? "Simple"
                : row.type === 2
                  ? "Modelos"
                  : " Modelos con variantes"}
            </Typography>
          </TableCell>
        )}
        <TableCell sx={{ maxWidth: '200px', display: wh ? " " : "none" }}>
          <IconButton
            onClick={() => setOpenDrawer({ right: true })}
            aria-label="delete"
            sx={{ m: "0 13px" }}
            color="primary"
          >
            <EyeOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            display: wh ? "" : "none",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ m: "0 16px" }}
            color="primary"
            onClick={() => {
              if (row.type === 1) {
                toggleDrawer();
                getAvailableDataModal(
                  dataPresentation?.[0]?.id,
                  row,
                  dataPresentation
                );
              } else {
                setOpen(!open);
              }
            }}
          >
            {row.type === 1 ? (
              <LastPageOutlinedIcon />
            ) : open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            display: wh ? "none" : " ",
          }}
        >
          <OptionButton
            menu={10}
            id={row.id}
            openDrawer={() => setOpenDrawer({ right: true })}
            type={"product"}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: "0 32px" }} colSpan={12}>
          <Collapse in={open} timeout="auto">
            {dataPresentation && (
              <Box sx={{ margin: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  p={0}
                >
                  {dataPresentation.map((model, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Button
                        onClick={() => {
                          toggleDrawer();
                          getAvailableDataModal(model.id, row, model);
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          p: "7px 0",
                          width: "80%",
                        }}
                        variant="outlined"
                      >
                        <ImageSize img={model?.image?.url} weight={2} />
                        <Typography
                          width={"100%"}
                          fontSize={"18px"}
                          align="center"
                          m={"0 16px"}
                        >
                          {model.name}
                        </Typography>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <DetailsDrawer
        openDrawer={openDrawer}
        toggleDrawer={() => { }}
        close={() => setOpenDrawer({ right: false })}
        product={row}
        updateList={updateList}
      />
    </>
  );
}

export function ArticleExpandableView(props) {
  const { type, reloadDataOforder, wh } = props;
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div>
      <GenericHeaderExpandable
        title="Agregar productos"
        arrow={expanded}
        setArrow={setExpanded}
      >
        <ArticleTableSearcher
          type={type}
          reloadDataOforder={reloadDataOforder}
          wh={wh}
        />
      </GenericHeaderExpandable>
    </div>
  );
}
