import BasicTable from "../../../components/GenericTable";
import TableRow from "@mui/material/TableRow";
import { Box, Divider, Paper, Stack } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ImageSize from "../../../components/ImageSize";
import React, { useEffect } from "react";
import { ModalRight } from "../../../components/GenericsModals";
import { InventoryServicePresentations } from "../../../api/inventoryService";
import { useAuth } from "user-context-fran-dev";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";


const InventoryTable = ({ table, params }) => {
  return (
    <Grid sx={{ p: "5px" }}>
      <BasicTable
        titles={["Nombre ", "Modelo", "variantes", "Ord.", "Dis.", "Apart." /* , "Ver" */]}
      >
        {table.map((data, index) => (
          <ProductsRow data={data} key={index} params={params} />
        ))}
      </BasicTable>
    </Grid>
  );
};

const ProductsRow = ({ data, params }) => {
  return (
    <>
      {data.models &&
        data.models.map((model, index) => (
          <Row data={data} model={model} key={index} params={params} />
        ))}
    </>
  );
};

const Row = ({ data, model, params }) => {
  const [open, setOpen] = useState(false);

  const ToOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {open && (
        <ModalEdit
          setOpen={setOpen}
          open={open}
          data={data}
          model={model}
          params={params}
        />
      )}
      <TableRow hover  >
        <TableCell
          align="left"
          hover
        >
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.name}
          </Typography>
        </TableCell>

        <TableCell
          align="left"
        >
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.name}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
        >
          <div>
            {
              data.type === 3
                ? <LastPageOutlinedIcon
                  color='primary'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    ToOpen();
                  }}
                />
                :
                <Typography pl={'5px'}>
                  --
                </Typography>}
          </div>
        </TableCell>

        <TableCell align="left" sx={{ maxWidth: "50px", minWidth: "60px" }}>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.inventory[0].ordered}
          </Typography>
        </TableCell>
        <TableCell align="left" >
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.inventory[0].available}
          </Typography>
        </TableCell>
        <TableCell align="left" >
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {model.inventory[0].reserved}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const TablePresentation2 = ({ model, data }) => {
  const titles = ["Presentación ", "Ord.", "Disp.", "Apart."];
  return (
    <>
      <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
        <TableContainer /* component={Paper} */>
          <Table
            /* sx={{ minWidth: 250 }} aria-label="simple table" size="small" */ size="small"
          >
            <TableHead>
              <TableRow>
                {titles.map((title) => (
                  <TableCell key={title} align="left">
                    <strong>{title}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {model?.length > 0 && model[0]?.presentations?.length > 0 ? (
                model[0].presentations.map((presentation, index) => (
                  <TableRow
                    key={index} // Agrega una key única
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      m: "0",
                      p: "0",
                    }}
                    hover
                  >
                    <TableCell align="left">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {presentation?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {presentation?.inventory?.[0]?.ordered || "0"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {presentation?.inventory?.[0]?.available || "0"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "100px", minWidth: "80px" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {presentation?.inventory?.[0]?.reserved || "0"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No hay variante disponibles
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        </TableContainer>
        <br />
      </div>
    </>
  );
};

const ModalEdit = ({ setOpen, open, data, model, params }) => {
  const [table, setTable] = useState("");
  const { logout } = useAuth();

  useEffect(() => {
    getModelPresentations();
  }, [open]);

  const getModelPresentations = async () => {
    let irequestFilter = [];
    irequestFilter.push({ key: "mdl", value: `${model.id}` });
    irequestFilter.push({ key: "product", value: `${model.product}` });
    if (params.get("wh")) {
      irequestFilter.push({ key: "wh", value: `${params.get("wh")}` });
    }
    let serviceInstance = new InventoryServicePresentations();
    let response = await serviceInstance.filter(irequestFilter, logout);
    setTable(response.data);
  };

  return (
    <ModalRight
      setOpen={setOpen}
      open={open}
      title="Inventario por variante"
      size="xs"
    >
      <Box sx={{ display: "flex", m: '24px 0 ' }}>
        <ImageSize
          img={data?.main_model?.image?.url ? data?.main_model?.image.url : ""}
          weight={2}
        />
        <Typography variant="h6" sx={{ margin: "8px 16px", fontWeight: "bold" }} >
          {data?.name}
          <Typography variant="body1">
            {data?.sku || "SKU"}
          </Typography>
          <Typography color={data?.is_active ? 'success' : 'error'} variant="body1" sx={{ fontWeight: "bold" }}>
            {data?.is_active ? "Activo" : "inactivo"}
          </Typography>
        </Typography>

      </Box>
      <Divider />
      <Grid display={'flex'}>
        <Typography mr={'16px'}>
          Modelo:
        </Typography>
        <Stack>
          <Typography>
            {model.name}
          </Typography>
          <Typography color={model.is_active ? 'success' : "error"} variant="body1" sx={{ fontWeight: "bold" }} >
            {model.is_active ? 'Activo' : 'Inactivo'}
          </Typography>
        </Stack>

      </Grid>
      <Grid
        container
        mt="32px" /* sx={{height: "100%", alignContent: "center"}} */
      >
        {table && <TablePresentation2 model={table} data={data} />}
      </Grid>
    </ModalRight>
  );
};

export default InventoryTable;
