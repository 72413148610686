import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import GenericTable from "../GenericTable";
import TableCell from "@mui/material/TableCell";
import { TableRow } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "user-context-fran-dev";
import { GenericEmptyTable } from "../GenericEmptyTable";
import { TransferItemsService } from "../../api/inventory/transfersService";
import { AvailablePresentationInventory } from "../../api/availablePresentation";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import ImageSize from "../ImageSize";
import { TableModal } from "../GenericsModals";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValInventoryTransfers,
  valSchInventoryTransfers,
} from "../../utils/formHelper";

export default function TransferModal(props) {
  const {
    open,
    setOpen,
    title,
    reloadDataOforder,
    dataProduct,
    dataTransfer,
    modelToFind,
    warehouse,
  } = props;

  const { logout } = useAuth();
  const [dataProductList, SetdataProductList] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (open) {
      getAvailableDataModal();
    }
  }, [modelToFind, open]);

  async function getAvailableDataModal() {
    let irequestFilter = [];
    irequestFilter.push({ key: "wh", value: `${warehouse}` });
    irequestFilter.push({ key: "mdl", value: `${dataProduct.id}` });
    let productInventory = new AvailablePresentationInventory();
    let response = await productInventory.filter(irequestFilter, logout);

    SetdataProductList(response.data);
  }

  return (
    <TableModal
      open={open}
      setOpen={setOpen}
      title={title}
      className="table-adjustment"
    >
      <Grid container>
        <Grid item xs={9}>
          <Grid container>
            <ImageSize
              img={dataProduct.image?.url ? dataProduct.image.url : ""}
              weight={2}
            />
            <Grid item ml={{ xs: "8px", md: "32px" }}>
              <h3>{modelToFind.name}</h3>
              <h4>{dataProduct.name} </h4>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            justifyContent={"start"}
            alignItems={"end"}
            flexDirection="column"
            sx={{ height: "64px" }}
          >
            <h4> {modelToFind.sku} </h4>
            <h4>
              {" "}
              {modelToFind.is_active ? (
                <Typography sx={{ color: "green", fontSize: 15 }}>
                  Activo
                </Typography>
              ) : (
                <Typography sx={{ color: "red", fontSize: 15 }}>
                  Desactivado
                </Typography>
              )}{" "}
            </h4>
          </Grid>
        </Grid>
        <div className="presentation-select">
          <Grid
            xs={12}
            item
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <a>Origen: {dataTransfer.warehouse_source.name} </a>
            Destino: {dataTransfer.warehouse_destiny.name}
            <Grid xs={12} item className="">
              {dataProductList ? (
                <GenericTable
                  titles={[
                    "Presentación",
                    "Barcode",
                    "Disponible",
                    "Cantidad",
                    "Acción",
                  ]}
                >
                  {dataProductList.map((rowList, indexList) =>
                    rowList.items
                      .filter((n) => n.available > 0)
                      .map((row, index) => (
                        <RowInfo
                          row={row}
                          key={index}
                          setOpen={setOpen}
                          dataProduct={dataProduct.data}
                          getAvailableDataModal={getAvailableDataModal}
                          //idArticle={article.id}
                          dataTransfer={dataTransfer}
                          reloadDataOforder={reloadDataOforder}
                          setError={setError}
                        />
                      ))
                  )}
                </GenericTable>
              ) : (
                <GenericEmptyTable msg={"No hay datos en la tabla"} />
              )}
            </Grid>
          </Grid>
          {error && (
            <Grid sx={{ marginTop: 2, textAlign: "center", color: "red" }}>
              <h4>{error}</h4>
            </Grid>
          )}
        </div>
      </Grid>
    </TableModal>
  );
}

const RowInfo = ({
  row,
  setOpen,
  dataProduct,
  idArticle,
  dataTransfer,
  reloadDataOforder,
  getAvailableDataModal,
  setError,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [quantity, setQuantity] = useState("");
  const [msg, sendNotification] = useNotification();

  const addArticleTransfer = async (formData) => {
    setLoading(true);

    let products = new TransferItemsService();
    let dataRes = await products.postDataAuth(formData, logout);
    sendNotification(UseerrorOrsuccess(dataRes));

    reloadDataOforder();
    getAvailableDataModal();
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initValInventoryTransfers(dataTransfer.id, row),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchInventoryTransfers(row)),
    onSubmit: async (formData) => {
      addArticleTransfer(formData);
    },
  });

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.presentation.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          {row.barcode}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <Typography variant="subtitle2" component="div">
          <a className="lineUp">{row.available}</a>
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
            type="number"
            fullWidth
            label="Cantidad"
            size="small"
            name="quantity"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            helperText={formik.touched.quantity ? formik.errors.quantity : ""}
          />
        </form>
      </TableCell>

      <TableCell align="left" sx={{ maxWidth: "200px" }}>
        <form onSubmit={formik.handleSubmit}>
          <LoadingButton
            loading={loading}
            fullWidth
            variant="text"
            disabled={!formik.values.quantity}
            type="submit"
            sx={{ color: "primary" }}
          >
            Transferir
          </LoadingButton>
        </form>
      </TableCell>
    </TableRow>
  );
};
