import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import PurchaseService from "../../api/purchaseService";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";

const HeaderGeneric = (props) => {
  const {
    //primer header----------------------------------------------------------------------------------->
    leftTitle, //titulo del header izquierdo
    cancelButton, //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
    titleButton, // el boton a lado derecho de cancelar este solo es para el titulo
    handleButton, //la funcion del boton
    //segundo header-------------------------------------------------------------------------------->
    rightTitle,
    name,
    noOrder,
    date,
    general,
    supplier,
    print,
    type,
    ...extraProp
  } = props;
  const stepperStatae = leftTitle.status;
  console.log(leftTitle.status);


  const Item = ({ value }) => (
    <Grid container sx={{ justifyContent: "flex-end" }}>
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Typography fontSize={"16px"} p={"5px 0"}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Grid display={"flex"}>
          <OrderStepper
            state={stepperStatae}
            type={type}
            transType={extraProp.transfer?.type} />
          <Link
            style={{
              textDecoration: "none",
              marginLeft: "8px",
              display:
                stepperStatae === "Completado" ||
                  stepperStatae === "Reservado" ||
                  stepperStatae === "Confirmado"
                  ? "flex" : "none",
            }}
            to={print?.to}
            target={"_blank"}
          >
            <IconButton>
              <LocalPrintshopOutlinedIcon />
            </IconButton>
          </Link>
        </Grid>

        <Grid
          mt={{ xs: 1, md: 0 }}
          display={stepperStatae === "Cancelado" || stepperStatae === "Reservado" ? 'none' : ''}
        >
          {cancelButton && stepperStatae != "Completado" && (
            <CancelButton
              handleClick={cancelButton}
              data={noOrder}
              stateSteps={stepperStatae}
            />
          )}
          {handleButton && titleButton && stepperStatae != "Completado" && (
            <MainButton
              text={titleButton.tittle}
              disable={titleButton}
              handleClick={handleButton}
              data={noOrder}
              stateSteps={stepperStatae}
              type={type}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        sx={{ mt: "24px" }}
        display="flex"
        justifyContent="flex-end"
        flexDirection="column"
      >
        {rightTitle && (
          <Typography align="right" variant="h2" fontSize={"24px"}>
            {rightTitle}
          </Typography>
        )}
        {name && <Item value={name} />}
        {noOrder && (
          <Typography
            display={
              noOrder.no_order || noOrder.justification || noOrder.reference
                ? "display"
                : "none"
            }
            variant="h4"
            sx={{ fontSize: "30px", p: "8px 0", textAlign: "right" }}
          >
            {noOrder.no_order || noOrder.justification || noOrder.reference}
          </Typography>
        )}
        {supplier && (
          <>
            <Item value={supplier.rfc} />
            <Item value={supplier.phone} />
            <Item value={supplier.email} />
          </>
        )}
        {date && (
          <Grid container justifyContent="flex-end">
            <Dia text={date} />
          </Grid>
        )}
        {general && (
          <Typography fontSize="16px" sx={{ textAlign: "right" }}>
            Almacén {general}
          </Typography>
        )}
        {extraProp.transfer && (
          <>
            <Item
              value={
                extraProp.transfer.type === 1 ? "Inmediata" : "Con confirmación"
              }
            />
            <Item value={<Typography>Transferir: {extraProp?.transfer?.warehouse_source.name}</Typography>} />
            <Item value={<Typography>Al: {extraProp?.transfer?.warehouse_destiny?.name}</Typography>} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

const CancelButton = ({ handleClick, stateSteps }) => {
  return (
    <LoadingButton
      loading={handleClick?.loading}
      onClick={async () => {
        handleClick.handleClick();
      }}
      sx={{ borderRadius: "60px", m: "0 8px " }}
      variant="outlined"
    >
      Cancelar
    </LoadingButton>
  );
};

const MainButton = ({ text, handleClick, stateSteps, data, type }) => {

  const Labes = {
    transfer: "Ordenado",
    sales: "Reservado",
    adjustment: "Completado",
    scrap: "Completado",
    purchases: "Confirmado",
  }
  const currentLabel = Labes[type]
  return (
    <LoadingButton
      loading={handleClick.loading}
      disabled={!data.articles}
      onClick={() => {
        handleClick.handleClick();
      }}
      sx={{
        borderRadius: "60px",
        ml: "8px",
        display: stateSteps === currentLabel ? "none" : "",
      }}
      variant="contained"
    >
      {" "}
      {text}
    </LoadingButton>
  );
};

const Dia = ({ text }) => {
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  return (
    <Typography fontSize={"16px"} p={"5px"}>
      {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
    </Typography>
  );
};

const OrderStepper = ({
  state,
  type,
  transType
}) => {
  const cancelSteps = ["Borrador", "Cancelado"];
  const transferStep = transType && transType !== 1
    ? ["Borrador", "Entregar", "Recibir", "Completado"]
    : ["Borrador", "Completado"]
  const labelStep = {
    transfer: transferStep,
    sales: ["Borrador", "Reservado", "Completado"],
    adjustment: ["En Progreso", "Completado"],
    scrap: ["En Progreso", "Completado"],
    purchases: ["Borrador", "Ordenado", "Completado"],
  }
  const steps = labelStep[type] || [];
  const getStepIndex = (currentState) => {
    const stepList = currentState === "Cancelado" ? cancelSteps : steps;
    const index = stepList.indexOf(currentState);
    return index !== -1 ? index
      : state === "Borrador" ? 0
        : state === "Entregado" ? 2
          : state === "Confirmado" || "Reservado" ? 1
            : 0
      ;
  };

  const [activeStep, setActiveStep] = useState(getStepIndex(state));

  useEffect(() => {
    setActiveStep(getStepIndex(state));
  }, [state, type]);

  const displayedSteps = state === "Cancelado" ? cancelSteps : steps;

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        bgcolor: "#F2EEF7",
        p: "5px 16px",
        borderRadius: "60px",
        mr: "30px",
      }}
    >
      {displayedSteps.map((label, index) => (
        <React.Fragment key={label}>
          <Step>
            <StepLabel>
              <Typography color={index <= activeStep ? "#60269e" : "grey"}>
                {index === activeStep ? <b>{label}</b> : label}
              </Typography>
            </StepLabel>
          </Step>
          {index < displayedSteps.length - 1 && (
            <Typography variant="h6" mx={1}>
              {">"}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default HeaderGeneric;
