import React, { useState } from "react";
import { Button, Paper, Typography, Divider } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../scss/adminBusiness.scss";
import Link from "@mui/material/Link";
import { GenericAddressDrawer } from "./GenericAddAddress";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValBusinessAddressInfo,
  valSchBusinessAddress,
} from "../utils/formHelper";
import EditIcon from "@mui/icons-material/Edit";
import { GenericDrawer } from "./keskiCreator/genericDrawer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const GenericAddress = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };
  const formik = useFormik({
    initialValues: initValBusinessAddressInfo(data, true),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessAddress()),
    onSubmit: async (formData) => {},
  });

  return (
    <>
      <GenericAddressDrawer
        data={data}
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        UpdateInfo={UpdateInfo}
        target={target}
        close={() => toggleDrawer("right", false)}
      />
      <Paper elevation={3} sx={{ m: "0 0 24px" }}>
        <Grid
          p={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          bgcolor={"#f7f7f7"}
        >
          <Typography color="#605D5D">
            <strong>Dirección</strong>
          </Typography>
          <EditOutlinedIcon
            sx={{ cursor: "pointer" }}
            onClick={() => toggleDrawer("right", true)}
          />
        </Grid>
        <Divider />
        <Grid p={"16px 24px"}>
          {data.address ? (
            <>
              <Typography fontSize={"18px"}>{data?.address?.street}</Typography>
              <Typography fontSize={"18px"}>
                {data?.address?.suburb} C.P {data.address?.postal_code}
              </Typography>
              <Typography fontSize={"18px"}>
                {data?.address?.city}, {data?.address?.state}
              </Typography>
            </>
          ) : (
            <Typography align="center">
              No hay información registrada
            </Typography>
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default GenericAddress;
