import React, { useEffect, useState } from "react";
import "../../scss/adminBusiness.scss";
import {
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Iniciales } from "../../utils/functions";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Container from "../../layout/gridContainer";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import UserRemove from "../../api/userRemove";
import { useAuth } from "user-context-fran-dev";
import BranchManages from "../../api/branchManages";
import { BusinessGroups } from "../../api/businessGroups";
import CreateUserGroups from "../../api/createUserGroups";
import DeleteUserGroupfrom from "../../api/deleteUserGroup";
import UserManages from "../../api/userManages";
import UserBranch from "../../api/userBranch";
import { useNavigate } from "react-router-dom";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import { useParams } from "react-router-dom";
import { CardActionArea } from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import { Box, minHeight, minWidth, Stack } from "@mui/system";
import { ModalRight } from "../../components/GenericsModals";
import { ConfirmAction } from "../../components/ConfirmAction/ConfirmAction";
import { CheckBox } from "@mui/icons-material";
import { Card } from "../catalog/articleDetail/articleData";

const UserDetails = ({ setReload }) => {
  const navigate = useNavigate();
  const { logout, auth } = useAuth();
  const [selectBranch, setSelectBranch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [table, setTable] = useState("");
  const [acces, setAcces] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [branch, setBranch] = useState("");
  const [msg, sendNotification] = useNotification();
  const { id } = useParams();

  const addBranchService = new UserBranch();

  const [open, setOpen] = useState();
  const [confirmationData, setConfirmationData] = useState(null);

  const handleOpen = (id) => {
    setConfirmationData(id);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  {
    /*} useEffect(() => {
    if (!open) {
      setAnchorEl(null);
    }
  }, [open]);*/
  }

  /*------------------------------- -------------------------*/

  const handleChangeBranch = (event) => {
    setSelectBranch(event.target.value);
    if (event.target.value != "") {
      addBranch(event.target.value);
      event = null;
    }
  };

  const addBranch = async (data) => {
    let response = await addBranchService.postDataAuth(
      {
        branch: data,
        user: currentUser.id,
      },
      logout
    );
    sendNotification(UseerrorOrsuccess(response));
    GetCurrentUser();
  };

  const handleDeleteUser = async (id, formData) => {
    let deleteUser = new UserRemove();
    let response = await deleteUser.editDataAuth(id, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    navigate(-1); //volver atras
  };
  const UpdateBranchList = () => {
    (async () => {
      let getBranchs = new BranchManages();
      let response = await getBranchs.getAllAuth(logout);
      setTable(response.data);
    })();
  };

  const UpdateAppAcces = () => {
    (async () => {
      let getBranchs = new BusinessGroups();
      let response = await getBranchs.getAllAuth(logout);
      setAcces(response.data);
    })();
  };
  const GetCurrentUser = () => {
    (async () => {
      const getUser = new UserManages();

      const response = await getUser.getAllByIdAuth(id, logout);
      setCurrentUser(response.data);
      setBranch(response.data.branch);
    })();
  };
  useEffect(() => {
    GetCurrentUser();
    UpdateBranchList();
    UpdateAppAcces();

    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 1);
    };
  }, []);

  //For the ModalRight about "Accesos"
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const accessInfo = [
    {
      type: "Catálogo",
      explication:
        "Al desactivar el botón del catálogo, se bloqueará automáticamente el acceso a la sección de agregar artículos y categorías.",
      //gif: `<img src="${catalogo_disabled}" alt="Descripción del GIF">`
    },
    {
      type: "Inventario",
      explication:
        "Al desactivar el botón del inventario, se bloqueará automáticamente el acceso a la gestion del inventario y sus ajustes.",
    },
    {
      type: "Landing",
      explication:
        "Al desactivar el botón de landing, se bloqueará automáticamente el acceso al creador del sitio web",
    },
    {
      type: "Punto de venta",
      explication:
        "Al desactivar el boton de punto de venta, se bloqueará automáticamente el acceso al punto de venta para abrir caja",
    },
    {
      type: "Gestor de punto de venta",
      explication:
        "Al desactivar el boton de punto de venta, se bloqueará automáticamente el acceso al gestor del punto de venta",
    },
    {
      type: "Administración",
      explication:
        "Al desactivar el boton de administración, se bloqueará automáticamente el acceso a la sección de administrador donde manejas usuarios, negocio, sucursales y sus permisos",
    },
    {
      type: "Tienda",
      explication:
        "Al desactivar el boton de gestor de tienda en linea, se bloqueará automáticamente el acceso al gestor de tienda en linea ",
    },
  ];

  return (
    <>
      {currentUser && (
        <Container>
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            m={"24px 0 16px "}
            alignItems={"center"}
          >
            <Typography variant="h5" gutterBottom component="div">
              <strong>Perfil</strong>
            </Typography>
            <IconButton
              sx={{ display: currentUser.is_superuser === 0 ? " " : "none" }}
              onClick={handleClick}
              size="large"
            >
              <MoreHorizIcon
                fontSize="inherit"
                sx={{ color: "text.secondary" }}
              />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleDeleteUser(id, "");
                  handleClose();
                }}
              >
                Eliminar
              </MenuItem>
            </Menu>
          </Grid>
          <Stack
            alignItems={"center"}
            direction={"row"}
            spacing={1}
            m={"16px 0 "}
          >
            <Typography variant="h6">Accesos</Typography>
            <IconButton color="primary" onClick={handleOpenModal}>
              <HelpIcon />
            </IconButton>
          </Stack>

          <ModalRight
            title="Funciones de cada acceso"
            size="xs"
            open={openModal}
            setOpen={setOpenModal}
          >
            <>
              <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div>
                  <br />
                  {accessInfo.map((access) => (
                    <div key={access.type}>
                      <h4>
                        <strong>{access.type}</strong>
                      </h4>
                      <p>{access.explication}</p>
                      <br />
                    </div>
                  ))}
                </div>
              </Box>
            </>
          </ModalRight>

          <Grid
            container
            spacing={3}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Grid item xs={12} md={7}>
              <Card title={"Datos del usuario"} p={"0"}>
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  padding={"24px 16px"}
                >
                  <CardHeader
                    sx={{ p: "0" }}
                    avatar={
                      <Avatar aria-label="recipe">
                        {Iniciales(
                          currentUser.first_name,
                          currentUser.last_name
                        )}
                      </Avatar>
                    }
                    title={`${currentUser.first_name} ${currentUser.last_name}`}
                    subheader={<> {currentUser.email} </>}
                  />
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ color: "text.green" }}
                  >
                    {currentUser.is_superuser
                      ? "Super User"
                      : currentUser.is_staff
                      ? "Staff"
                      : ""}
                  </Typography>
                </Grid>
                <Divider />
                {(auth.user.pk == id || auth.user.is_superuser) && (
                  <Box p={"8px 0"}>
                    <CardActionArea
                      onClick={() => {
                        navigate("/changes-password");
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          padding: "4px 16px",
                        }}
                      >
                        Cambiar contraseña
                      </Typography>
                    </CardActionArea>
                    <CardActionArea onClick={() => {}}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          padding: "4px 16px",
                        }}
                      >
                        Subir foto de perfil
                      </Typography>
                    </CardActionArea>
                  </Box>
                )}
              </Card>
            </Grid>

            <Grid item xs={12} md={5}>
              {acces && currentUser.is_superuser === 0 && (
                <Card p={" 16px"} title={"Accesos a las aplicaciones"}>
                  <Stack direction={"column"} spacing={1} p={"0"}>
                    {acces.map((data, index) => (
                      <Switches data={data} user={currentUser} index={index} />
                    ))}
                  </Stack>
                </Card>
              )}
              <Card sx={{ minHeight: "100px" }} title="Acceso a las sucursales">
                {table && (
                  <TextField
                    id="demo-simple-select-filled"
                    value={selectBranch}
                    onChange={handleChangeBranch}
                    fullWidth
                    label={"Sucursal"}
                    select
                  >
                    <MenuItem value="">
                      <em>Surcursal</em>
                    </MenuItem>
                    {table &&
                      table.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                {!table && (
                  <Grid display={"flex"} justifyContent={"center"}>
                    <Typography>
                      <strong> No hay sucursales registardas </strong>
                    </Typography>
                  </Grid>
                )}

                {branch && (
                  <List>
                    {confirmationData && (
                      <ConfirmAction
                        open={open}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                        title={`Eliminar sucursal ${confirmationData.branch.name}?`}
                        content={`¿Estás seguro que deseas eliminar la sucursal ${confirmationData.branch.name}?`}
                        disagreeText="Cancelar"
                        agreeText="Eliminar"
                        service={addBranchService}
                        id={confirmationData.id}
                        getCurrent={GetCurrentUser}
                      />
                    )}
                    {branch.map((data, index) => (
                      <div key={index}>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemText primary={data.branch.name} />
                          <Link
                            edge="end"
                            component="button"
                            variant="body2"
                            underline="none"
                            onClick={() => handleOpen(data)}
                          >
                            Eliminar
                          </Link>
                        </ListItem>
                      </div>
                    ))}
                  </List>
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

const Switches = ({ data, user, index }) => {
  const [state, setState] = useState(false);
  const [hidden, setHidden] = useState(true);
  const { logout, auth } = useAuth();

  useEffect(() => {
    if (user?.groups) {
      const state =
        user?.groups.find((group) => data.name === group.name) !== undefined
          ? true
          : false;

      setHidden(user.is_superuser);
      setState(state);
    }
  }, []);

  const SetGroup = async () => {
    let setGroup = new CreateUserGroups();
    let response = await setGroup.postDataAuth(
      {
        group: data.name,
        user: user.id,
      },
      logout
    );
  };
  const DeleteGroup = async () => {
    let deleteGroup = new DeleteUserGroupfrom();
    let response = await deleteGroup.postDataAuth(
      {
        group: data.name,
        user: user.id,
      },
      logout
    );
  };

  const handleChanges = () => {
    if (!state) {
      SetGroup();
    } else {
      DeleteGroup();
    }
    setState(!state);
  };

  const list = [
    "Catálogo de productos",
    "Gestor de Inventario",
    "Web Studio",
    "Punto de venta",
    "Gestor de punto de venta",
    "Administración",
    "Gestor tienda en línea",
  ];
  return (
    <FormControlLabel
      key={index}
      control={<Checkbox checked={state} onChange={handleChanges} />}
      label={list[index]}
    />
  );
};

export default UserDetails;
