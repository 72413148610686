import React, { useState, useEffect } from "react";
import Container from "../../../layout/gridContainer";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  RegisterCash,
  CashOrder,
  RegisterCashPreview,
} from "../../../api/posAdmin/CashRegisterManages";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import BasicTable, { CustomTable } from "../../../components/GenericTable";
import CircularProgress from "@mui/material/CircularProgress";
import { TableCell, TableRow } from "@mui/material";
import { STATUSPOSORDER } from "../../../utils/constants";
import OrderModal from "./posOrderModal";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import { formatMoney, formatNumber } from "../../../utils/functions";
import { BottonModalDiag } from "../../../components/GenericsModals";

const CashRegisterDetail = ({ setReload }) => {
  const [data, setData] = useState();
  const { id } = useParams();
  const { logout, auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState("");
  const [open, setOpen] = useState(false);
  const [registe, setRegiste] = useState();
  const [preview, setPreview] = useState();
  const [openb, setOpenb] = useState(false);

  const GetRegisterCash = () => {
    (async () => {
      let getRegister = new RegisterCash();
      let response = await getRegister.getAllByIdAuth(id, logout);
      setData(response.data);
      if (response.data.status == "1") {
        GetRegisterCashPreview();
      } else {
        setPreview("empty");
      }
    })();
  };

  const GetRegisterCashPreview = () => {
    (async () => {
      let getRegister = new RegisterCashPreview();
      let response = await getRegister.getAllByIdAuth(id, logout);
      setPreview(response.data);
    })();
  };

  const getCashRegisters = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "cash_register", value: id });
      let getRegister = new CashOrder();
      let response = await getRegister.filter(irequestFilter, logout);
      setTable(response.data);
      setLoading(false);
    })();
  };

  useEffect(() => {
    GetRegisterCash();
    getCashRegisters();
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 7);
    };
  }, []);

  const OpenModal = (x) => {
    setRegiste(x);
    setOpen(true);
  };

  return (
    <Container>
      <>
        {registe && <OrderModal open={open} setOpen={setOpen} data={registe} />}
        {data && (
          <HeaderPview
            data={data}
            preview={preview}
            noOrdenes={table.length}
            setOpen={setOpenb}
          />
        )}
        <OrderTable table={table} loading={loading} setOpen={OpenModal} />
        {data && (
          <CashMoves
            setOpen={setOpenb}
            open={openb}
            data={preview && (data.status == 1 ? preview.cash : data.cash_list)}
          />
        )}
      </>
    </Container>
  );
};

const OrderTable = ({ table, loading, setOpen }) => {
  return (
    <>
      <Grid item xs={12} mt={"32px"}>
        {table && (
          <BasicTable
            titles={[
              "# orden",
              "Fecha",
              "Estatus",
              "Artículos",
              "Subtotal",
              "Total",
            ]}
          >
            {table.map((row, index) => (
              <Row row={row} key={index} goTo={setOpen} />
            ))}
          </BasicTable>
        )}
        {loading && !table && (
          <Grid container justifyContent="center" mt={5}>
            <CircularProgress />
          </Grid>
        )}
        {!table && !loading && <GenericEmptyTable />}
      </Grid>
    </>
  );
};

const Row = ({ row, goTo }) => {
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
        onDoubleClick={() => {
          goTo(row);
        }}
      >
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
            {row.id}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Dia text={row.date_created} />
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {STATUSPOSORDER[row.status]}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.articles_total}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          {formatMoney(row.subtotal)}
        </TableCell>
        <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formatMoney(row.total)}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const Header = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="h5"
          sx={{ color: "text.secondary" }}
          gutterBottom
          component="div"
        >
          Caja
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Typography
                variant="h5"
                sx={{ color: "primary.main" }}
                gutterBottom
                component="div"
              >
                <strong>{data.branch.name}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Typography
            variant="h7"
            sx={{ color: "text.green" }}
            gutterBottom
            component="div"
          >
            {`${data.user.first_name} ${data.user.last_name} `}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={4} md={7}>
            <Grid container justifyContent="flex-end">
              <Typography
                variant="h7"
                component="div"
                sx={{ textAlign: "right" }}
              >
                Abierto:
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={8} md={5}>
            <Grid container justifyContent="flex-end">
              <Dia text={data.date_created} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} md={7}>
            <Grid container justifyContent="flex-end">
              <Typography
                variant="h7"
                component="div"
                sx={{ textAlign: "right" }}
              >
                Cerrado:
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={8} md={5}>
            <Grid container justifyContent="flex-end">
              {data.date_closed && <Dia text={data.date_closed} />}
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4} md={7}>
            <Grid container justifyContent="flex-end">
              <Typography
                variant="h7"
                component="div"
                sx={{ textAlign: "right" }}
              >
                Total:
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={8} md={5}>
            <Grid container justifyContent="flex-end">
              <Typography variant="h7" component="div">
                {`$ ${data.total}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HeaderPview = ({ data, preview, noOrdenes, setOpen }) => {
  const isOpen = preview && data.status == 1;

  return (
    <Grid container>
      {data && (
        <>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ color: "text.secondary" }}
                  gutterBottom
                  component="div"
                >
                  <Chip
                    label={isOpen ? "Abierto" : "Cerrado"}
                    variant="outlined"
                    sx={{ width: "100px" }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} ml="8px">
                <Typography
                  variant="h5"
                  sx={{ color: "primary.main" }}
                  gutterBottom
                  component="div"
                >
                  <strong>{data.branch.name}</strong>
                </Typography>
                <Typography variant="h7" gutterBottom component="div">
                  Abierto: <Dia text={data.date_created} />
                </Typography>
                <Typography variant="h7" gutterBottom component="div">
                  Cerrado: <Dia text={data.date_closed} />
                </Typography>
                <Typography variant="h7" gutterBottom component="div">
                  N. órdenes: {isOpen ? preview.total_order : noOrdenes}
                  {/*   Cerrado: {isOpen? preview.} */}
                </Typography>

                <Button
                  sx={{ marginTop: "8px" }}
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  Movimientos de caja
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Typography variant="h5" gutterBottom component="div">
                    <strong>Caja</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Typography
                variant="h7"
                sx={{ color: "text.green" }}
                gutterBottom
                component="div"
              >
                {`${data.user.first_name} ${data.user.last_name} `}
              </Typography>
            </Grid>
            {
              <>
                <Grid container>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography
                        variant="h7"
                        component="div"
                        sx={{ textAlign: "right" }}
                      >
                        Tarjeta:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ maxWidth: "100%" }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatMoney(isOpen ? preview.total_bank : data.bank)}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography
                        variant="h7"
                        component="div"
                        sx={{ textAlign: "right" }}
                      >
                        Efectivo:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      {formatMoney(isOpen ? preview.total_cash : data.cash)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography
                        variant="h7"
                        component="div"
                        sx={{ textAlign: "right" }}
                      >
                        Total:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ maxWidth: "100%" }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatMoney(isOpen ? "" : data.total)}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography
                        variant="h7"
                        component="div"
                        sx={{ textAlign: "right" }}
                      >
                        IVA:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ maxWidth: "100%" }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatMoney(isOpen ? "" : data.iva)}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4} md={7}>
                    <Grid container justifyContent="flex-end">
                      <Typography
                        variant="h7"
                        component="div"
                        sx={{ textAlign: "right" }}
                      >
                        Descuentos:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Grid container justifyContent="flex-end">
                      <Typography variant="h7" component="div">
                        {isOpen ? "" : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const Dia = ({ text }) => {
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  if (!text) {
    return "--";
  }
  return (
    <>{`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}</>
  );
};

const CashMoves = ({ open, setOpen, data }) => {
  return (
    <BottonModalDiag
      setOpen={setOpen}
      open={open}
      title={{ text: "Movimientos de caja", align: "left" }}
    >
      {data && (
        <Grid container>
          <Grid item xs={12}>
            <CustomTable
              titles={[
                { title: "Razón", align: "left" },
                { title: "Día", align: "center" },
                { title: "Tipo", align: "right" },
                { title: "Cantidad", align: "right" },
              ]}
            >
              {data &&
                data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    hover
                  >
                    <TableCell
                      align="left"
                      sx={{ minWidth: "20px", maxWidth: "5vw" }}
                    >
                      <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
                        {row.reason}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: "20px", maxWidth: "5vw" }}
                    >
                      <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
                        <Dia text={row.date_created} />
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ minWidth: "20px", maxWidth: "5vw" }}
                    >
                      <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
                        {row.type == 1 ? "Abono" : "Retiro"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ minWidth: "20px", maxWidth: "5vw" }}
                    >
                      <Typography variant="subtitle2" sx={{ m: 0, p: 0 }}>
                        {formatMoney(row.amount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </CustomTable>
          </Grid>
        </Grid>
      )}
    </BottonModalDiag>
  );
};

export default CashRegisterDetail;
