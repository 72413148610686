import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import GenericTable from "../../../components/GenericTable";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import "../../../scss/adminBusiness.scss";
import AddBranch from "./addBranch";
import { useAuth } from "user-context-fran-dev";
import BranchManages from "../../../api/branchManages";
import { PaginationGeneric } from "react-generic-pagination";
import CircularProgress from "@mui/material/CircularProgress";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../../components/GenericEmptyTable";

const Branch = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [table, setTable] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: "",
    name: "",
    phone: "",
  });

  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateBranchList();
  }, [page]);

  const toggleDrawer = (anchor, open) => {
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const UpdateBranchList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getBranchs = new BranchManages();
      let response = await getBranchs.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);

      setLoading(false);
    })();
  };

  const pageSetter = (n) => {
    setPage(n);
    /* navigate(`page=${page}`) */
  };

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        m={"24px 0 40px"}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="title-card"
        >
          <strong>Sucursales</strong>
        </Typography>

        <Button
          variant="contained"
          sx={{ float: "right", color: "#fff", borderRadius: 60 }}
          onClick={() => {
            toggleDrawer("right1", true);
            setEdit(false);
          }}
        >
          Agregar sucursal
        </Button>
        <AddBranch
          openDrawer={openDrawer}
          toggleDrawer={toggleDrawer}
          UpdateBranchList={UpdateBranchList}
          close={() => toggleDrawer("right1", false)}
          edit={edit}
          data={data}
        />
      </Grid>

      <>
        <GenericTable titles={["Nombre", "Teléfono", "¿Vende en Línea?"]}>
          {loading && <GenericSkeletonTable />}
          {!loading && !table && <GenericEmptyTable />}
          {!loading &&
            table &&
            table.map((data) => (
              <TableRow
                key={data.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onDoubleClick={() => {
                  window.localStorage.setItem("menu", 9);
                  navigate(`detail/${data.id}`);
                }}
                hover
              >
                <TableCell align="left">
                  <Typography variant="subtitle1" gutterBottom component="div">
                    {data.name}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1" gutterBottom component="div">
                    {data.phone}
                  </Typography>
                </TableCell>

                <TableCell align="left" sx={{ width: "16%" }}>
                  <Typography
                    pl={"40px"}
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                  >
                    {data.is_active ? "si" : "no"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </GenericTable>

        <Grid item xs={12} mt={2}>
          <PaginationGeneric
            className="paginationStyle"
            page={page}
            total={totalpages}
            pageSetter={pageSetter}
          ></PaginationGeneric>
        </Grid>
      </>
    </>
  );
};

export default Branch;
