import React, { useEffect, useState } from 'react'
import { GenericDrawer } from '../../../components/keskiCreator/genericDrawer'
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Avatar, Box, Chip, Divider, Grid, Grid2, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import {
    STATUSTRANSFER,
    STATUSTRANSFERCOLOR,
} from "../../../utils/constants";
import { useAuth } from "user-context-fran-dev";
import { TransferService } from '../../../api/inventory/transfersService';
import { AdjustmentService } from '../../../api/inventory/adjustmentService';
import ScrapService from "../../../api/inventory/scrapService";

export default function DrawerDetails({ openDrawer, toggleDrawer, data, close, type, status, statusColor }) {
    const { logout, auth } = useAuth();
    const [dataOrder, setDataOrder] = useState();
    const order_number = {
        transfer: "transfer_order",
        scrap: "scrap_order",
        adjustment: "adjustment_order",
    };
    useEffect(() => {
        if (openDrawer?.right) {
            if (data?.id && type) {
                GetOrderData();
            }
        }
    }, [openDrawer]);

    const GetOrderData = async () => {
        try {
            const services = {
                transfer: new TransferService(),
                scrap: new ScrapService(),
                adjustment: new AdjustmentService(),
            };

            const service = services[type];

            if (!service) {
                console.error(`Error: Tipo de servicio "${type}" no válido.`);
                return;
            }

            let response = await service.getAllByIdAuth(data?.id, logout);

            if (!response || !response.data) {
                console.warn("Advertencia: Respuesta vacía o inválida.");
                return;
            }

            console.log("Orden obtenida:", response);
            setDataOrder(response.data);
        } catch (error) {
            console.error("Error al obtener datos de la orden:", error);
        }
    };
    return (
        <GenericDrawer
            open={openDrawer}
            toggleDrawer={toggleDrawer}
            anchor={"right"}
            drawerWidth="30%">
            <Box p={'16px'}>
                <Grid
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: "24px",
                    }}
                >
                    <Typography fontSize={"20px"} p={"8px 0"}>
                        <b>Vista previa</b>
                    </Typography>
                    <IconButton onClick={close}>
                        <CloseSharpIcon fontSize="small" />
                    </IconButton>
                </Grid>
                <Grid>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <h1>{dataOrder?.articles?.[0]?.[order_number[type]]}</h1>

                        <Chip
                            label={status[data.status]}
                            color={statusColor[data.status]}
                            variant="outlined"
                        />
                    </Stack>

                    <div>
                        {dayjs(dataOrder?.date_created).format("MMM DD, YYYY")}
                    </div>
                    {type === "transfer"
                        ? (
                            <>
                                <p>Transferir de: {dataOrder?.warehouse_destiny?.name}</p>
                                <p>Transferir a: {dataOrder?.warehouse_source?.description}</p>
                            </>
                        )
                        :
                        <>
                            <p>{dataOrder?.warehouse?.description}</p>
                            <p>{dataOrder?.justification || dataOrder?.reference}</p>
                        </>
                    }
                </Grid>
                <Divider sx={{ m: '16px 0' }} />
                <p>PRODUCTOS</p>
                {[...new Map(dataOrder?.articles?.map(item => [item?.model?.product?.id, item])).values()]
                    .map((item) => (
                        <Stack key={item?.model?.product?.id} direction="row" spacing={1} m="16px 0">
                            <Avatar>{item?.model?.product?.name?.[0]}</Avatar>
                            <div>
                                <p>{item?.model?.product?.name}</p>
                                <p>
                                    {item?.model?.name}, {item?.presentation?.name}
                                </p>
                            </div>
                        </Stack>
                    ))}


            </Box>
        </GenericDrawer>
    )
}
