import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  TableRow,
  TableCell,
  Link,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import { UserCode, RefreshCode } from "../../../api/posAdmin/discountsManages";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { useAuth } from "user-context-fran-dev";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../../components/GenericEmptyTable";
import BasicModal from "../../../components/GenericModal";
import { useNavigate } from "react-router-dom";
import GetUserList from "../../../api/userList";
import BasicTable, { CustomTable } from "../../../components/GenericTable";
import GenericSizeModal from "../../../components/GenericSizeModal";
import { TableModal } from "../../../components/GenericsModals";
import { PaginationGeneric } from "react-generic-pagination";

const DiscountsUsers = () => {
  const [open, setOpen] = useState(false);
  const [msg, sendNotification] = useNotification();
  const { logout } = useAuth();
  const [table, setTable] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetCodeUser();
  }, []);

  const GetCodeUser = async () => {
    setLoading(true);
    let irequestFilter = [];
    irequestFilter.push({ key: "is_deleted", value: false });
    let setDiscount = new UserCode();
    let response = await setDiscount.filter(irequestFilter, logout);
    setTable(response.data);
    setLoading(false);
  };

  return (
    <>
      {open && (
        <AddCodeUser open={open} setOpen={setOpen} update={GetCodeUser} />
      )}
      <Grid item xs={12} mt={"32px"}>
        <GenericHeaderTitle title="Código de descuento por usuario">
          <Link
            component="button"
            variant="subtitle1"
            onClick={() => {
              setOpen(true);
            }}
            underline="none"
            className="link"
            p="0px"
          >
            Crear y asignar
          </Link>
        </GenericHeaderTitle>
      </Grid>
      <Grid item xs={12} mt={"16px"}>
        {table ? (
          <Stack spacing={"8px"}>
            {table.map((row, index) => (
              <CodeUser row={row} key={index} update={GetCodeUser} />
            ))}
          </Stack>
        ) : (
          <CustomTable
            titles={[
              { title: "Nombre" },
              { title: "Correo" },
              { title: "Acción", align: "right" },
            ]}
          >
            {!table && loading && <GenericSkeletonTable />}
            {!table && !loading && <GenericEmptyTable />}
          </CustomTable>
        )}
      </Grid>
    </>
  );
};
const CodeUser = ({ row, update }) => {
  const [hidden, setHidden] = useState(true);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  /*  const [check, setCheck] = useState(row.is_active) */

  const handleChangeCheck = async () => {
    let setDiscount = new UserCode();
    let response = await setDiscount.editDataAuth(
      row.id,
      { is_active: !row.is_active },
      logout
    );
    update();
    sendNotification(UseerrorOrsuccess(response));
  };

  const handleChangeUpdate = async () => {
    let setRefresh = new RefreshCode();
    let response = await setRefresh.editDataAuth(row.id, logout);
    update();
    sendNotification(UseerrorOrsuccess(response));
  };

  const handleDelete = async () => {
    let setDiscount = new UserCode();
    let response = await setDiscount.editDataAuth(
      row.id,
      { is_deleted: true },
      logout
    );
    update();
    sendNotification(UseerrorOrsuccess(response));
  };

  return (
    <Paper sx={{ padding: "8px", display: "flex" }}>
      <Grid container>
        <Grid
          item
          xs={5}
          sm={3}
          md={2}
          sx={{ display: "flex", height: "100%", alignItems: "center" }}
        >
          <Switch
            sx={{ marginRight: "8px" }}
            checked={row.is_active}
            onChange={handleChangeCheck}
          />
          {hidden ? codeFormat(row.code) : row.code}
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", height: "100%", alignItems: "center" }}
        >
          <IconButton onClick={() => setHidden(!hidden)}>
            {hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Grid>

        <Grid item xs={3} sm={3} md={4}>
          <Grid container sx={{ height: "100%" }} alignItems="center">
            {row.owner.first_name}
          </Grid>
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Grid
            container
            sx={{ height: "100%" }}
            justifyContent="end"
            alignItems="center"
          >
            <Link
              component="button"
              variant="subtitle1"
              onClick={() => {
                handleChangeUpdate();
              }}
              underline="none"
              p="0px"
            >
              Actualizar
            </Link>
            <Link
              component="button"
              variant="subtitle1"
              onClick={() => {
                handleDelete();
              }}
              underline="none"
              p="0px"
              sx={{ marginLeft: "32px" }}
            >
              Eliminar
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const AddCodeUser = ({ open, setOpen, update }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [table, setTable] = useState(null);
  const totalPerPage = 10;
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const [msg, sendNotification] = useNotification();

  const handleSelect = async (data) => {
    let setDiscount = new UserCode();
    let response = await setDiscount.postDataAuth({ owner: data.id }, logout);
    update();
    sendNotification(UseerrorOrsuccess(response));
  };

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateUserList();
  }, [page]);

  const UpdateUserList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getUsers = new GetUserList();
      let response = await getUsers.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
    })();
  };
  const pageSetter = (n) => {
    setPage(n);
  };

  return (
    <TableModal
      open={open}
      setOpen={setOpen}
      title="Seleccionar usuario para generar código"
    >
      <Grid item xs={12}>
        {
          <>
            <CustomTable
              titles={[
                { title: "Nombre" },
                { title: "Correo" },
                { title: "Acción", align: "right" },
              ]}
            >
              {table &&
                table.map((row, index) => (
                  <Row key={index} row={row} select={handleSelect} />
                ))}
              {!table && loading && (
                <Grid container justifyContent="center" p={"24px"}>
                  <CircularProgress />
                </Grid>
              )}
              {!table && !loading && <GenericEmptyTable />}
            </CustomTable>
            <Grid item xs={12} mt={"16px"}>
              <PaginationGeneric
                className="paginationStyle"
                page={page}
                total={totalpages}
                pageSetter={pageSetter}
              ></PaginationGeneric>
            </Grid>
          </>
        }
      </Grid>
    </TableModal>
  );
};
const Row = ({ row, select }) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "5vw" }}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${row.first_name} ${row.last_name}`}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ minWidth: "20px", maxWidth: "150px" }}>
        <Typography
          ariant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.email}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: "20px" }}>
        <Link
          component="button"
          variant="subtitle1"
          onClick={() => {
            select(row);
          }}
          underline="none"
        >
          Seleccionar
        </Link>
      </TableCell>
    </TableRow>
  );
};

const codeFormat = (code) => {
  return `●●●●${code.substring(4, 6)}`;
};

export default DiscountsUsers;
