import React, { useState, useEffect } from "react";
import BasicModal from "./GenericModal";
import { TextField, Grid, Box, Typography, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initPaymentConditions,
  valPaymentConditions,
} from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import { Suppliers } from "../api/supplierService";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import CustomerService from "../api/customerService";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import { useSnackbar } from "notistack";
import PurchaseService from "../api/purchaseService";
import SalesService from "../api/salesService";
import { GenericDrawer } from "./keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const GenericPaymentConditions = ({
  setOpen,
  open,
  UpdateInfo,
  data,
  target,
  toggleDrawer,
  close,
}) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  var setPaymentCond = null;
  var updateSupplier = null;
  const { enqueueSnackbar } = useSnackbar();

  var response = "";

  const UpdatePaymentCond = async (id, formData, data) => {
    if (target === "sales") {
      updateSupplier = new SalesService();
    } else if (target === "purchase") {
      updateSupplier = new PurchaseService();
    } else if (target === "supplier") {
      updateSupplier = new Suppliers();
    } else if (target === "sales" || target === "client") {
      updateSupplier = new CustomerService();
    }

    if (target === "purchase") {
      if (parseFloat(data.total) < 0.01) {
        enqueueSnackbar("Falta agregar artículos", {
          variant: "Error",
        });
        return;
      }
      response = await updateSupplier.editDataAuth(id, formData, logout);
    } else if (
      target === "supplier" ||
      target === "client" /* || target === 'purchase' */
    ) {
      response = await updateSupplier.editDataAuth(id, formData, logout);

      sendNotification(UseerrorOrsuccess(response));
    } else {
      if (data.supplier?.id || data.client?.id) {
        response = await updateSupplier.editDataAuth(
          target == "purchase" ? data.supplier.id : data.id,
          formData,
          logout
        );
        sendNotification(UseerrorOrsuccess(response));
      } else {
        enqueueSnackbar(
          target == "purchase" ? "Falta proveedor" : "Falta cliente",
          {
            variant: "Error",
          }
        );
      }
    }

    UpdateInfo();
  };
  const SetPaymentCond = async (formData) => {
    if (target === "purchase" || target === "supplier") {
      setPaymentCond = new Suppliers();
    } else if (target === "sales" || target === "client") {
      setPaymentCond = new CustomerService();
    }
    response = await setPaymentCond.editDataAuth(
      data.supplier.id,
      formData,
      logout
    );
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response));
    } else {
      sendNotification(UseerrorOrsuccess(response));
    }
    UpdateInfo();
  };

  const formik = useFormik({
    initialValues: initPaymentConditions(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valPaymentConditions()),
    onSubmit: async (formData) => {
      setLoading(true);
      formik.resetForm();

      if (data) {
        UpdatePaymentCond(data.id, formData, data);
      } else {
        SetPaymentCond(formData);
      }
      setLoading(false);
      close();
    },
  });

  return (
    <GenericDrawer
      open={open}
      toggleDrawer={toggleDrawer}
      anchor={"right"}
      drawerWidth="30%"
    >
      <Box component={"form"} onSubmit={formik.handleSubmit} p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Condiciones de pago</b>
          </Typography>

          <IconButton onClick={close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid>
          <TextField
            fullWidth
            name="credit_days"
            label="Días de crédito"
            margin="dense"
            size="small"
            value={formik.values.credit_days}
            onChange={formik.handleChange}
            error={
              formik.touched.credit_days && Boolean(formik.errors.credit_days)
            }
            helperText={
              formik.touched.credit_days ? formik.errors.credit_days : ""
            }
          />
          {target != "purchase" && target != "sales" && (
            <TextField
              fullWidth
              label="Limite de Crédito"
              value={formik.values.credit_limit}
              onChange={formik.handleChange}
              margin="dense"
              size="small"
              name="credit_limit"
              id="formatted-credit_limit-input"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              variant="outlined"
              error={
                formik.touched.credit_limit &&
                Boolean(formik.errors.credit_limit)
              }
              helperText={
                formik.touched.credit_limit ? formik.errors.credit_limit : ""
              }
            />
          )}

          <TextField
            fullWidth
            name="discount"
            label="% descuento"
            margin="dense"
            size="small"
            value={formik.values.discount}
            onChange={formik.handleChange}
            error={formik.touched.discount && Boolean(formik.errors.discount)}
            helperText={formik.touched.discount ? formik.errors.discount : ""}
          />

          <Grid display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              sx={{ borderRadius: "60px", mt: 2 }}
            >
              Agregar
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </GenericDrawer>
  );
};

export default GenericPaymentConditions;
