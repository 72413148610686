import React, { useState } from "react";
import { CardHeader, Chip, Divider } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CurrencyFormat from "react-currency-format";
import { DrawerSupplier } from "../../../components/inventario/DrawerSupplier";
import OptionButton from "../../../components/inventario/optionButton";

const ClientTable = (props) => {
  const { data, updateInfo } = props;
  const items = ["business_name", "phone", "email",];
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        hover
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >

        {items.map((item, index) => (
          <TableCell
            key={index}
            align="left"
          >
            <Typography p={'16px 6px'} >
              {data[item]}
            </Typography>

          </TableCell>
        ))}
        <TableCell sx={{ maxWidth: '200px' }}>

          <Chip
            label={data.type === 1 ? "Empresa" : "Persona"}
            color="grey"
            variant='outlined'
            sx={{ p: '0 11px' }}
          />
        </TableCell>
        <TableCell >
          <OptionButton
            menu={22}
            id={data.id}
            openDrawer={() => setOpenDrawer({ right2: true })}
            UpdateInfo={updateInfo}
            type={"customer"}
          />
        </TableCell>

      </TableRow>
      <TableRow />


      <DrawerSupplier
        open={openDrawer}
        toggleDrawer={() => { }}
        id={data.id}
        close={() => setOpenDrawer({ right2: false })}
        type={"customer"}
      />
    </>
  );
};

export default ClientTable;
