import React from "react";
import { Box, Button, Grid, Link, Skeleton, Typography } from "@mui/material";
import Lottie from 'react-lottie';
import list_empty from "../assets/Json/list_empty.json"
import CircularProgress from "@mui/material/CircularProgress";

export function GenericEmptyTable({ width, height }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: list_empty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <tr>
        <th colspan="7" scope="colgroup">
          <>
            <Grid m={"32px 0"}>
              <Lottie
                options={defaultOptions}
                width={width ?? '200px'}
                height={height ?? '200px'} />
              <Typography
                color={"primary"}
                variant="h6"
                align='center'>
                <b> No hay datos para mostrar.</b>
              </Typography>
            </Grid>
          </>
        </th>
      </tr>
    </>

  );
}

export function GenericSkeletonTable({ colums }) {
  const rows = 6;
  const columns = colums ?? 5; // Number of columns

  return (
    <>
      <tr>
        <th colspan="7" scope="colgroup">


          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${columns}, 1fr)`,
              gap: "10px",
              padding: '16px'
            }}>
            {Array.from({ length: rows * columns }).map((_, index) => (
              <Skeleton key={index} animation="pulse" height={60} />
            ))}
          </div>
        </th>
      </tr>
    </>
  );
}
