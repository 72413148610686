import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import ArticlesListAdjustment from "../../../../components/inventario/tables/ArticlesListAdjustment";
import HeaderGeneric from "../../../../components/inventario/HeaderGeneric";
import {
  STATUSADJUSTMENT,
  STATUSADJUSTMENTCOLOR,
} from "../../../../utils/constants";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import {
  AdjustementStatusService,
  AdjustmentService,
} from "../../../../api/inventory/adjustmentService";
import SearchAddArticle from "./addAdjustmentArticles/searchAddArticle";

const AdjustmentDetails = ({ setReload }) => {
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { logout, auth } = useAuth();
  const [data, setData] = useState();
  //const [ reload, setReloadPage ] = useState(false)
  const getAdjustment = new AdjustmentService();
  const purchaseStatusServiceConfirm = new AdjustementStatusService(
    "adjustment_order/" + id + "/complete"
  );

  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);

  const reloadDataOforder = () => {
    (async () => {
      let response = await getAdjustment.getAllByIdAuth(id, logout);
      setData(response.data);
      setReload(true);
      setLoading(false);
    })();
  };

  useEffect(() => {
    window.localStorage.setItem("menu", 17);
    (async () => {
      let response = await getAdjustment.getAllByIdAuth(id, logout);
      //sendNotification(UseerrorOrsuccess(response))
      window.localStorage.setItem("menu", 17);

      setData(response.data);
    })();
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 5);
    };
  }, []); //reload

  const finishOrder = () => {
    (async () => {
      setLoading(true);
      let response = await purchaseStatusServiceConfirm.editDataAuthNid(
        null,
        {},
        logout
      );

      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
    })();
  };

  return (
    <>
      {data && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={11} className="details-view-header">
            <HeaderGeneric
              leftTitle={{
                status: STATUSADJUSTMENT[data.status],
                color: STATUSADJUSTMENTCOLOR[data.status],
              }} //titulo del header izquierdo
              //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
              titleButton={{
                tittle: "Finalizar",
                enable: data.articles ? true : false,
              }} // el boton a lado derecho de cancelar este solo es para el titulo
              handleButton={{ handleClick: finishOrder, loading: loading }} //la funcion del boton
              rightTitle="Ajuste de inventario"
              name={`${data?.user?.first_name} ${data?.user?.last_name}`}
              noOrder={data}
              date={data?.date_created}
              general={data?.warehouse?.name}
              reloadDataOforder={reloadDataOforder}
              steps={STATUSADJUSTMENT}
              type={"adjustment"}
            ></HeaderGeneric>
          </Grid>
          {data.status === 1 && (
            <Grid item xs={11} className="article-expandable-view">
              <SearchAddArticle
                type="adjustment_order"
                reloadDataOforder={reloadDataOforder}
                wh={data.warehouse}
                dataAdjustment={data}
              ></SearchAddArticle>
            </Grid>
          )}
          <Grid item xs={11} className="details-view">
            <ArticlesListAdjustment data={data}></ArticlesListAdjustment>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AdjustmentDetails;
