"use client";
import React, { useEffect } from "react";
import { useState } from "react";
import { Tabs, Tab, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Box, Container, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CurrencyFormat from "react-currency-format";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import CardMedia from "@mui/material/CardMedia";
import ContentBar from "../assets/contenToolBar.png";
import Grid from "@mui/material/Grid2";
import { BusinessPlans } from "../api/businessManages";
import { useNavigate } from "react-router-dom";

export function TapPlans(props) {
  const [value, setValue] = useState(1);
  const plans_services = new BusinessPlans();
  const [plans, setPlans] = useState([]);
  const [discount, setDiscount] = useState();

  async function PlansServices() {
    let response = await plans_services.getAll();

    setPlans(response);
    setDiscount(response?.data.discount);
  }

  useEffect(() => {
    PlansServices();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ justifyContent: "center", marginBottom: "30px" }}>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        textAlign="center"
        color="#60269E"
      >
        Elija el plan adecuado para su negocio
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        centered
        sx={{ p: "0px 0" }}
      >
        <Tab
          iconPosition="end"
          label="anual"
          {...a11yProps(0)}
          sx={{ mb: -1 }}
          icon={
            <Chip
              variant="filled"
              color="primary"
              sx={{
                fontSize: "14px",

                opacity: "40%",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              label={
                <Typography fontSize={"12px"}>Ahorra {discount}%</Typography>
              }
            />
          }
        />
        <Tab label="Mensual" {...a11yProps(1)} />
      </Tabs>
      <Grid p={"16px 0"} display={props.display}>
        <Typography textAlign={"center"} fontSize={"14px"}>
          Todos los planes incluye:
        </Typography>
        <CardMedia
          component={"image"}
          image={ContentBar.src}
          sx={{
            height: "100px",
            width: { sm: "800px", md: "850px" },
            mr: 1,
            alignItems: "center",
            m: "auto",
            display: { xs: "none", sm: props.display },
          }}
        />
      </Grid>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={3} flexDirection={{ md: "row", sm: "column" }}>
          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <CardsPlan
              title={plans?.data?.plans?.business_standard_year?.name}
              price={plans?.data?.plans?.business_standard_year?.amount}
              date={plans?.data?.plans?.business_standard_year?.repeat_unit}
              description="El punto de venta y tienda en línea más fácil y potente. Gestiona tu negocio con nuestras aplicaciones integradas."
              ActiveButton={true}
              Active={plans?.data?.plans?.business_standard_year?.is_active}
              id={plans?.data?.plans?.business_standard_year?.id}
              display={props.display}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <CardsPlan
              title={plans?.data?.plans?.business_plus_year?.name}
              price={plans?.data?.plans?.business_plus_year?.amount}
              date={plans?.data?.plans?.business_plus_year?.repeat_unit}
              description="Centraliza la gestión de tus sucursales y usuarios en un solo lugar. Obtén información instantánea para tomar decisiones estratégicas."
              chip={true}
              chiptitle="Recomendado"
              ActiveButton={true}
              Active={plans?.data?.plans?.business_plus_year?.is_active}
              id={plans?.data?.plans?.business_plus_year?.id}
              display={props.display}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <CardsPlan
              Active={false}
              title="Enterprise"
              description="Comunícate con nosotros para conocer los precios"
              descriptionPlan="Para dueños de negocios en expansión con múltiples almacenes, sucursales y usuarios: administra todas tus operaciones desde un solo lugar."
              id={"Enterprise"}
              ActiveButton={true}
              display={props.display}
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={3} flexDirection={"row"} marginBottom="30px">
          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <CardsPlan
              title={plans?.data?.plans?.business_standard_month?.name}
              price={plans?.data?.plans?.business_standard_month?.amount}
              date={plans?.data?.plans?.business_standard_month?.repeat_unit}
              id={plans?.data?.plans?.business_standard_month?.id}
              description="El punto de venta y tienda en línea más fácil y potente. Gestiona tu negocio con nuestras aplicaciones integradas."
              ActiveButton={true}
              Active={plans?.data?.plans?.business_standard_month?.is_active}
              display={props.display}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <CardsPlan
              title={plans?.data?.plans?.business_plus_month?.name}
              price={plans?.data?.plans?.business_plus_month?.amount}
              date={plans?.data?.plans?.business_plus_month?.repeat_unit}
              id={plans?.data?.plans?.business_plus_month?.id}
              description="Centraliza la gestión de tus sucursales y usuarios en un solo lugar. Obtén información instantánea para tomar decisiones estratégicas."
              ActiveButton={true}
              Active={plans?.data?.plans?.business_plus_month?.is_active}
              chip={true}
              chiptitle="Recomendado"
              display={props.display}
              discount={discount}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 4 }}>
            <CardsPlan
              Active={false}
              title="Enterprise"
              description="Comunícate con nosotros para conocer los precios"
              descriptionPlan="Para dueños de negocios en expansión con múltiples almacenes, sucursales y usuarios: administra todas tus operaciones desde un solo lugar."
              id={"Enterprise"}
              ActiveButton={true}
              display={props.display}
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}

export function CardsPlan(content) {
  const navigate = useNavigate();
  const ItemList = ({ name }) => {
    return (
      <ListItem sx={{ p: "5px 0" }}>
        <ListItemIcon>
          <TaskAltOutlinedIcon color={"primary"} />
        </ListItemIcon>
        <ListItemText
          primary={<Typography fontSize={"16px"}>{name}</Typography>}
        />
      </ListItem>
    );
  };

  return (
    <Card
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor:
          content.title === "Business Plus Anual" ||
          content.title === "Business Plus"
            ? "#ECE9FE"
            : "",
        minWidth: "250px",
        minHeight: "785px",
        alignItems: "center",
      }}
    >
      <Container sx={{ padding: "0px 16px 0px 16px" }}>
        <Box
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontSize: "16px", color: "#60269E", fontWeight: "bold" }}
          >
            {content.title}
          </Typography>
          {content.chip ? (
            <Chip
              sx={{
                fontSize: "14px",
                bgcolor: "#A190F8",
                color: "white",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              label={content.chiptitle}
            />
          ) : null}
        </Box>
        {content.title === "Enterprise" ? null : (
          <>
            <Box
              sx={{
                marginTop: "20px",

                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "36px", color: "#000000", fontWeight: "bold" }}
              >
                {content.price === "--" ? (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#605D5D",
                      fontWeight: "bold",
                    }}
                  >
                    No disponible por el momento
                  </Typography>
                ) : (
                  <CurrencyFormat
                    value={content.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                )}
              </Typography>
              <Box sx={{ marginLeft: "20px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#605D5D",
                    fontWeight: "bold",
                  }}
                >
                  /Por {content.date === 1 ? "año" : "mes"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#605D5D",
                    fontWeight: "bold",
                  }}
                >
                  Facturado {content.date === 1 ? "anual" : "mensual"}
                </Typography>
              </Box>
            </Box>
            <Typography
              color="#00A650"
              display={content.title === "Business Plus" ? "" : "none"}
              mb={"20px"}
            >
              <b>¡20% extra por tiempo limitado!</b>
            </Typography>
          </>
        )}

        <Box>
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontSize: "16px", color: "#605D5D" }}
          >
            {content.description}
          </Typography>
          {content.descriptionPlan ? (
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontSize: "16px", color: "#605D5D" }}
            >
              <br />
              {content.descriptionPlan}
            </Typography>
          ) : null}

          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
        </Box>
        {content.ActiveButton ? (
          <>
            <Box sx={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                disabled={content.active === false}
                sx={{
                  borderRadius: "32px",
                  background:
                    content.title === "Business Plus" ? "#A190F8" : "#F1EFEF",
                  color: content.title === "Business Plus" ? " " : "#605D5D",
                  mb: 2,
                  display: content.display,
                }}
                onClick={() => {
                  const url =
                    content.id === "Enterprise"
                      ? "https://keskinube.com"
                      : "https://keskinube.com";

                  window.open(url, "_blank");
                }}
              >
                <b>Actualizar ahora</b>
              </Button>
              <Typography
                variant="warning"
                sx={{
                  fontSize: "12px",
                  color: "#FF0000",
                  textAlign: "center",
                  marginTop: "10px",
                  display: content.active === false ? "" : "none",
                }}
              >
                No disponible por el momento
              </Typography>
            </Box>
          </>
        ) : null}
        {content.title === "Business Standard Anual" ||
        content.title === "Business Standard" ? (
          <>
            <ItemList name={"Dominio personalizado"} />
            <ItemList name={"Sitio seguro con certificados SSL"} />
            <ItemList name={"Espacio en la nube"} />
            <ItemList name={"Productos Ilimitados"} />
            <ItemList name={"Categoría de productos y filtros ilimitados"} />
            <ItemList name={"SEO automático"} />
            <ItemList name={"Pasarela de pagos (CLIP)"} />
            <ItemList name={"Plataforma de envíos (EnvioClick)"} />
            <ItemList name={"Almacén general más 1 sucursal"} />
            <ItemList name={"2 usuario (administrador más 1 staff)"} />
          </>
        ) : content.title === "Business Plus Anual" ||
          content.title === "Business Plus" ? (
          <>
            <ItemList name={"Base de datos con respaldo de seguridad"} />
            <ItemList name={"Almacén general más 4 sucursales"} />
            <ItemList name={"5 usuarios (administrador y 4 staff)"} />
          </>
        ) : content.title === "Enterprise" ? (
          <>
            <ItemList name={"1:1 onboarding"} />
            <ItemList name={"Almacen general y sucursales ilimitadas"} />
            <ItemList name={"Usuarios ilimitados"} />
          </>
        ) : null}
      </Container>
    </Card>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
