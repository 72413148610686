import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { GenericHeaderTitle } from "../../../components/GenericHeader";
import ImageSize from "../../../components/ImageSize";
import Container from "../../../layout/gridContainer";
import "../../../scss/posAdmin.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValTicket, valSchTicket } from "../../../utils/formHelper";
import { useReactToPrint } from "react-to-print";

const CustomTicket = ({ data, update, postData }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <GenericHeaderTitle title={"Personalización ticket de venta"} />
        </Grid>
        <Grid item xs={12} sm={6} md={5} mt={"16px"}>
          <PrinTicket data={data} ref={componentRef} />
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={0}></Grid>
        <Grid item xs={12} sm={6} md={6} p={"16px"}>
          <ConfigTicket
            data={data}
            update={update}
            postData={postData}
            handlePrint={handlePrint}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const Ticket = ({ data }) => {
  return (
    <Paper>
      <Grid container sx={{ padding: "16px" }}>
        <Grid item xs={12} mb="64px">
          {/* Header */}
          <Stack
            direction={"column"}
            /* pacing={"3px"} */
            alignItems="center"
            mb="16px"
          >
            <Typography> Keski Nube </Typography>
            <ImageSize img={""} weight={2} />
            {data.display_RFC_on_ticket && (
              <Typography> R.F.C. Meid345m3454 </Typography>
            )}
            {data.display_branch_name_on_ticket && (
              <Typography> Suc. Plaza Patria (01) </Typography>
            )}
            {data.display_branch_address_on_ticket && (
              <>
                <Typography> Av.Patria 456 </Typography>
                <Typography> Col las Américas C.P 45234 </Typography>
              </>
            )}
          </Stack>
          <Box className="divider" />
        </Grid>
        <Grid item xs={12}>
          {" "}
          {/* Titles table */}
          <Grid container>
            <Grid item xs={3}>
              Cant
            </Grid>
            <Grid item xs={6}>
              Artículo
            </Grid>
            <Grid item xs={3}>
              Total
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {" "}
          {/* Table body */}
          <Grid container>
            <Grid item xs={3}>
              1
            </Grid>
            <Grid item xs={5}>
              Tenis, Negro, 27 cm
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              $1002.00
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              1
            </Grid>
            <Grid item xs={5}>
              Tenis, Negro, 27 cm
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              $1002.00
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={"8px"}>
          {/* Totales */}
          <Grid container justifyContent="end">
            <Grid item xs={4} className="end-flex">
              Total M.N $
            </Grid>
            <Grid item xs={3} className="end-flex">
              262.34
            </Grid>
          </Grid>
          <Grid container justifyContent="end">
            <Grid item xs={4} className="end-flex">
              Efectivo$
            </Grid>
            <Grid item xs={3} className="end-flex">
              500.00
            </Grid>
          </Grid>
          <Grid container justifyContent="end" mt={"16px"}>
            <Grid item xs={4} className="end-flex">
              Cambio $
            </Grid>
            <Grid item xs={3} className="end-flex">
              237.10
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {" "}
          {/* Impuestos */}
          <Grid container>
            <Grid item xs={9}>
              <Typography>**Total de impuestos 16%</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>$0.00</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={"16px"}>
          {/* Footer */}
          <Stack
            direction={"column"}
            /* pacing={"3px"} */
            alignItems="center"
          >
            <Typography>Le atendió: </Typography>
            <Typography>28/07/2022 20:23 SUC. 01</Typography>
            <Typography>***Folio: 00000123***</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ConfigTicket = ({ data, update, postData, handlePrint }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initValTicket(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchTicket()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      postData(data.id, formData);
      update();
      setLoading(false);
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <ImageSize img={""} weight={4} />
            <Button sx={{ display: "block" }}> Cargar logo</Button>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                mb={"8px"}
                control={
                  <Switch
                    name="display_branch_name_on_ticket"
                    onChange={formik.handleChange}
                    checked={formik.values.display_branch_name_on_ticket}
                  />
                }
                label="Mostrar nombre de la sucursal"
              />
              <FormControlLabel
                mb={"8px"}
                control={
                  <Switch
                    name="display_RFC_on_ticket"
                    onChange={formik.handleChange}
                    checked={formik.values.display_RFC_on_ticket}
                  />
                }
                label="Mostrar RFC"
              />
              <FormControlLabel
                mb={"8px"}
                control={
                  <Switch
                    name="display_branch_address_on_ticket"
                    onChange={formik.handleChange}
                    checked={formik.values.display_branch_address_on_ticket}
                  />
                }
                label="Mostrar Dirección"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={"32px"}>
            {/* <TextField
              id="outlined-multiline-static"
              name="ticket_footnote"
              label="Líneas adicionales "
              multiline
              rows={4}
              value={formik.values.ticket_footnote}
              onChange={formik.handleChange}
            /> */}
          </Grid>
          <Grid item xs={9} mt={"16px"}>
            <Grid container direction="row">
              <Grid xs justifyContent={"end"}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                >
                  Actualizar
                </LoadingButton>
              </Grid>
              <Grid xs>
                <Button
                  variant="contained"
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Imprimir recibo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const PrinTicket = React.forwardRef((props, ref) => {
  const { data, cashAmount, creditAmount, change, number } = props;
  return (
    <>
      <style type="text/css" media="print">
        {
          "\
        @page { size: 80mm 80mm }\
        "
        }
      </style>
      <div ref={ref}>
        <Ticket data={data} />
      </div>
    </>
  );
});

export default CustomTicket;
