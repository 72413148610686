import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TableInfo from './tableInfo';
import CurrencyFormat from 'react-currency-format';
import { DateFormat } from '../../../components/DateFormat'
import { STATUSSALES, STATUSSALESCOLOR } from '../../../utils/constants'
import Chip from '@mui/material/Chip';
import { DrawerSupplier } from "../../../components/inventario/DrawerSupplier";
import OptionButton from "../../../components/inventario/optionButton";
import DrawerDetails from "../../../components/inventario/DrawerDetails";

const SalesTable = (props) => {
  const { data, UpdateInfo } = props
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const navigate = useNavigate();

  const beginDate = data.date_created

  return (
    <>

      <TableRow
        hover
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        style={{ height: 10 }}
      >
        <TableCell align="left" >
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle2"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.no_order}
              </Typography>
            }
            subheader={data.warehouse.name}

          />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.supplier?.business_name ?? "--"}
              </Typography>
            }

          />
        </TableCell>
        {/*ESTE ES PARA EL TIPO DE ORDEN */}
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {DateFormat({ beginDate })}
              </Typography>
            }
          />
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Chip
              label={STATUSSALES[data.status]}
              color={STATUSSALESCOLOR[data.status]}
              className="chip-with"
              variant="outlined" />
          </Typography>
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <CurrencyFormat
                  value={data?.total}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            }
          />
        </TableCell>
        <TableCell>
          <OptionButton
            menu={19}
            id={data.id}
            openDrawer={() => setOpenDrawer({ right: true })}
            UpdateInfo={UpdateInfo}
            type={"sales"}
          />
        </TableCell>
      </TableRow>

      <DrawerDetails
        open={openDrawer}
        toggleDrawer={() => { }}
        data={data}
        close={() => setOpenDrawer({ right: false })}
        type={"sales"}
      />
    </>
  );
}

export default SalesTable;