import React, { useEffect, useState } from "react";
import { Divider, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { OrderService, ItemsOrderService } from "../../api/pos/ordersService";
import { useAuth } from "user-context-fran-dev";
import {
  PresentationService,
  PresentationPos,
} from "../../api/pos/warehouseService";
import { useParams } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import BackspaceIcon from "@mui/icons-material/Backspace";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import CurrencyFormat from "react-currency-format";
import { GenericEmptyTable } from "./GenericEmptyTable";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";

export default function EditItem(props) {
  const data = props;
  const [loading, setLoading] = useState("");
  const [number, setNumber] = useState("");
  const [presentations, setPresentations] = useState("");
  const [activePresentation, setActivePresentation] = useState("");
  const [presentationSelected, setPresentationSelected] = useState("");
  const [modelPrice, setModelPrice] = useState("");
  const [total, setTotal] = useState("");
  const [msg, sendNotification] = useNotification();
  const [availableEdit, setAvailableEdit] = useState("");
  const model = data.data;
  let { id, cr } = useParams();
  const { logout, auth } = useAuth();

  useEffect(() => {
    setPresentationSelected(model.presentation.id);
    setActivePresentation(model.id);
    setModelPrice(model.presentation.price);

    updatePresentations();
    getAvailable();
    setNumber(model.qty);
  }, []);

  function handleAnswerChange(e) {
    let tmpNumber = e.target.value;
    setNumber(tmpNumber);
  }

  const handleChange = (data) => {
    if (data.id && data.available) {
      setPresentationSelected(data.id);
      setActivePresentation(data.id);
      setModelPrice(data.presentation.price);
      if (number) {
        setTotal(number * data.presentation.price);
      }
    }
  };

  const SumbitEditPresentation = () => {
    (async () => {
      let itemsService = new PresentationPos();
      let response = await itemsService.editDataAuth(
        model.id,
        {
          qty: Number(number),
          price: model.price,
          pos_order: data.orderData["0"].id,
          available: availableEdit,
        },
        logout
      );
      sendNotification(UseerrorOrsuccess(response));
      data.updateOrder();
      props.setOpen(false);
    })();
  };

  const getAvailable = () => {
    (async () => {
      let orderService = new PresentationService();
      let irequestFilter = [];
      irequestFilter.push({ key: "pos_item", value: model.id });
      let response = await orderService.filter(irequestFilter, logout);
      if (Number(response.status) < 300) {
        setAvailableEdit(response.data[0].id);
      } else {
      }
    })();
  };

  const updatePresentations = () => {
    (async () => {
      setLoading(true);
      let presentation = new PresentationService();
      let irequestFilter = [];
      irequestFilter.push({ key: "mdl", value: model.model.id });
      irequestFilter.push({ key: "wh", value: id }); //
      let response = await presentation.filter(irequestFilter, logout);
      if (response) {
        setPresentations(response.data);
      }
      setLoading(false);
    })();
  };

  const ButtonCal = (props) => {
    const event = () => {
      if (props.label === "Delete") {
        setNumber(number.slice(0, -1));
      } else if (props.label === "Clear") {
        setNumber("");
      } else {
        setNumber(number + props.label);
      }
    };
    return (
      <>
        {props.label === "Enter" && (
          <Button
            variant="contained"
            sx={{ width: "210px" }}
            color="success"
            onClick={() => {
              SumbitEditPresentation();
            }}
          >
            <KeyboardReturnIcon />
          </Button>
        )}
        {props.label === "Clear" && (
          <Button
            variant="outlined"
            onClick={() => {
              event();
            }}
          >
            {" "}
            <DeleteIcon />
          </Button>
        )}
        {props.label === "Delete" && (
          <Button
            variant="outlined"
            sx={{ width: "70px" }}
            color="error"
            onClick={() => {
              event();
            }}
          >
            <BackspaceIcon />
          </Button>
        )}
        {props.label === "." && (
          <Button
            color="primary"
            sx={{ width: "70px" }}
            onClick={() => {
              event();
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.label}
            </Typography>
          </Button>
        )}
        {props.label === "0" && (
          <Button
            color="primary"
            sx={{ width: "70px" }}
            onClick={() => {
              event();
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.label}
            </Typography>
          </Button>
        )}
        {props.label !== "Enter" &&
          props.label !== "Delete" &&
          props.label !== "Clear" &&
          props.label !== "0" &&
          props.label !== "." && (
            <Button
              key="one"
              sx={{ width: "70px" }}
              onClick={() => {
                event();
              }}
            >
              <Typography
                variant="h4"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {props.label}
              </Typography>
            </Button>
          )}
      </>
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="stretch"
    >
      <Grid xs={7}>
        <Grid
          containercd
          direction="column"
          justifyContent="flex-start"
          alignItems="start"
          sx={{ mb: "8px" }}
          xs
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="start"
          >
            <Grid maxHeight={"80px"}>
              <CardMedia
                component="img"
                alt="Imagen de producto"
                height="80"
                width="60"
                image={model.model?.image?.url ? model.model.image.url : "alt"}
              />
            </Grid>
            <Grid marginLeft={2} xs={6}>
              <Typography gutterBottom variant="h6" component="div">
                {model.model?.name}
              </Typography>
              <Typography
                sx={{ display: "flex" }}
                variant="body2"
                color="text.primary"
              >
                {model.model?.product.name}
              </Typography>
              <Typography
                sx={{ display: "flex" }}
                ocmponent="div"
                variant="body2"
                color="text.primary"
              >
                {model.model.product?.sku}
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="end"
              xs
              sx={{ marginRight: 3 }}
            >
              <Typography
                sx={{ fontSize: 22, textOverflow: "ellipsis", fontWeight: 600 }}
                color="text.primary"
                gutterBottom
              >
                {modelPrice ? (
                  <CurrencyFormat
                    value={modelPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                ) : (
                  ""
                )}
              </Typography>
              <Typography
                sx={{ fontSize: 24, textOverflow: "ellipsis", color: "white" }}
                color="text.secondary"
                gutterBottom
              ></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="start"
          alignItems="start"
          sx={{ overflow: "scroll" }}
          height={250}
        >
          <Grid container direction="row" sx={{ height: "210px", mb: "16px" }}>
            {presentations &&
              !loading &&
              presentations.map(
                (data, index) =>
                  model.presentation.id === data.presentation.id &&
                  data.available && (
                    <Grid key={index} xs marginTop={"3px"}>
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{
                          maxWidth: { md: 435, sm: 418 },
                          minWidth: 195,
                          height: 80,
                          marginRight: "5px !important",
                          marginBottom: 1,
                          padding: 1,
                          overflow: "hidden",
                          backgroundColor: "#642BA0",
                          color: "white",
                        }}
                      >
                        <CardContent sx={{ padding: "1px" }}>
                          <Grid container direction="row" justifyContent="flex">
                            <Grid xs={8}>
                              <Typography
                                gutterBottom
                                variant="h8"
                                component="div"
                                sx={{ textOverflow: "ellipsis" }}
                                noWrap
                              >
                                {data.presentation?.name
                                  ? data.presentation?.name
                                  : "Nombre De Producto"}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h8"
                                component="div"
                                sx={{ textOverflow: "ellipsis" }}
                                noWrap
                              >
                                {data?.barcode ? data?.barcode : ""}
                              </Typography>
                            </Grid>
                            <Grid xs={4} textAlign={"right"}>
                              <Typography
                                gutterBottom
                                variant="h8"
                                component="div"
                                sx={{ textOverflow: "ellipsis" }}
                                noWrap
                              >
                                <CurrencyFormat
                                  value={data.presentation?.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h8"
                                component="div"
                                sx={{ textOverflow: "ellipsis" }}
                                noWrap
                              >
                                Disp: {data.available ? data.available : "0"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
              )}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: { xs: 380, sm: 700, md: 700 }, marginTop: 2 }}
          >
            <div className="card-item">
              {presentations &&
                !loading &&
                presentations.map(
                  (data, index) =>
                    model.presentation.id == data.id &&
                    !data.available && (
                      <Card
                        key={index}
                        sx={{
                          maxWidth: 120,
                          minWidth: 195,
                          height: 140,
                          marginRight: 2,
                          padding: 1,
                          textOverflow: "ellipsis",
                          backgroundColor:
                            activePresentation == data.id && data.available
                              ? "rgb(21, 105, 169)"
                              : "",
                          color: activePresentation == data.id ? "white" : "",
                        }}
                        onClick={() => {
                          handleChange(data);
                        }}
                      >
                        <Badge
                          badgeContent={data.available ? data.available : "0"}
                          color={data.available ? "primary" : "error"}
                          max={999}
                        >
                          <CardContent sx={{ width: 160, padding: "1px" }}>
                            <Typography
                              gutterBottom
                              variant="h8"
                              component="div"
                              sx={{ textOverflow: "ellipsis" }}
                              noWrap
                            >
                              {data.presentation?.name
                                ? data.presentation?.name
                                : "Nombre De Producto"}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h8"
                              component="div"
                              sx={{ textOverflow: "ellipsis" }}
                              noWrap
                            >
                              {data.presentation?.price ? (
                                <CurrencyFormat
                                  value={data.presentation?.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              ) : (
                                "Nombre De Producto"
                              )}
                            </Typography>
                          </CardContent>
                        </Badge>
                      </Card>
                    )
                )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid xs={4} direction="column">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            id="outlined-basic"
            sx={{ width: 205, mb: 2, mt: 1 }}
            label="Cantidad"
            type="number"
            required
            value={number}
            onChange={handleAnswerChange}
            variant="outlined"
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"7"} />
              <ButtonCal label={"8"} />
              <ButtonCal label={"9"} />
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"4"} />
              <ButtonCal label={"5"} />
              <ButtonCal label={"6"} />
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"1"} />
              <ButtonCal label={"2"} />
              <ButtonCal label={"3"} />
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"."} />
              <ButtonCal label={"0"} />
              <ButtonCal label={"Delete"} />
              {/* <ButtonCal label={'Enter'}/> */}
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"Enter"} />
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
