import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import * as context from "user-context-fran-dev";
import { useFormik } from "formik";
import { IconButton, TextField, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import "../scss/loginRecover.css";
import { useAuth } from "user-context-fran-dev";
import { LoginApi } from "../api/userLogin";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import Footer from "../components/Footer";
import MainLogo from "../assets/new_main_logo.png";
import { useSnackbar } from "notistack";

export default function Login({ theme }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [msg, sendNotification] = useNotification();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleReturnBusiness = () => {
    localStorage.clear();
    // Navegar a la ruta search-business
    navigate("/search-business");
  };

  useEffect(() => {
    if (!context.getBaseUrl()) {
      navigate(`/search-business`);
    }
  }, []);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData) => {
      setLoading(true);
      const loginAuth = new LoginApi();

      const response = await loginAuth.postData(formData);

      if (response.data?.token) {
        if (response.data.user.is_staff || response.data.user.is_superuser) {
          if (
            response.data.user.groups.length === 0 &&
            response.data.user.is_superuser
          ) {
            navigate(`/business-config`);
          } else {
            navigate(`/home`);
          }
          login(response.data.token, response.data.user);
        } else {
          if (
            response.status === 200 &&
            response.data.user.is_staff === false
          ) {
            enqueueSnackbar("No tienes acceso al panel", {
              variant: "error",
            });
          } else {
            sendNotification(UseerrorOrsuccess(response));
          }
        }
      } else {
        sendNotification(UseerrorOrsuccess(response));
      }
      setLoading(false);
    },
  });

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Box className={`box-container`} /* img-${theme} */>
        <Grid
          elevation={12}
          //  sx={theme === 'light'?
          //  {bgcolor: 'rgba(255, 255, 255, 0.627)'}:
          //  {bgcolor: 'rgba(16, 16, 16, .9)'}}

          sx={{ bgcolor: { xs: "transparent", md: "rgba(0, 0, 0, 0)" } }}
          className="paperForm formSizeLogin"
        >
          <Grid item className="grid-container">
            <Grid item md={12}>
              <img
                src={MainLogo}
                style={{ width: "auto", height: "0px" }}
              ></img>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h4" className="title" color={"white"}>
                <strong>Bienvenido</strong>
              </Typography>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Grid item md={12}>
                <TextField
                  sx={{ backgroundColor: "white", borderRadius: "5px" }}
                  fullWidth
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                  label="Correo electrónico"
                  margin="normal"
                  variant="filled"
                  nputLabelProps={{
                    shrink:
                      formik.values.email.length > 0 || formik.touched.email,
                  }}
                  autoComplete="new-email"
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                  fullWidth
                  name="password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password ? formik.errors.password : ""
                  }
                  label="Contraseña"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "text.secondary" }} />
                          ) : (
                            <Visibility sx={{ color: "text.secondary" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={12} sx={{ marginTop: "15px" }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={loading}
                  variant="contained"
                  sx={{ color: "#fff", borderRadius: 60 }}
                >
                  Iniciar sesión
                </LoadingButton>
              </Grid>

              <Grid item md={12} sx={{ marginTop: "15px" }}>
                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={() => navigate("/recover-password")}
                >
                  <Typography variant="subtitle2" color={"white"}>
                    Recuperar contraseña
                  </Typography>
                </IconButton>
              </Grid>

              <Grid item md={12} sx={{ marginTop: "5px" }}>
                <IconButton
                  sx={{ borderRadius: "5px" }}
                  onClick={handleReturnBusiness}
                >
                  <Typography variant="subtitle2" color={"white"}>
                    Regresar a buscar mi negocio
                  </Typography>
                </IconButton>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

function initialValues() {
  return {
    email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email("Correo invalido").required("Required").trim(),
    password: Yup.string().required("Required"),
  };
}
