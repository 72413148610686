import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Paper } from "@mui/material";
import InventoryGenericDetailsView from "../../../../components/inventario/InventoryGenericDetailsView";
import { useParams } from "react-router-dom";
import PurchaseService, {
  PurchaseStatusService,
  SalesStatusService,
} from "../../../../api/purchaseService";
import { useAuth } from "user-context-fran-dev";
import ArticlesWithTotal from "../../../../components/inventario/tables/ArticlesWithTotal";
import HeaderGeneric from "../../../../components/inventario/HeaderGeneric";
import { ArticleExpandableView } from "../../../../components/inventario/ArticleExpandableView";
import { STATUS, STATUSCOLOR } from "../../../../utils/constants";

import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import {
  InventoryService,
  InventoryServiceSalesOrder,
} from "../../../../api/inventoryService";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Container from "../../../../layout/gridContainer";

const PurchasesDetails = ({ setReload }) => {
  const { id } = useParams();
  const { logout, auth } = useAuth();
  const [data, setData] = useState();
  const [reload, setReloadPage] = useState(false);
  const getPurchases = new PurchaseService();
  const purchaseStatusServiceConfirm = new PurchaseStatusService(
    "purchase_order/" + id + "/confirm"
  );
  const purchaseStatusServiceCancel = new PurchaseStatusService(
    "purchase_order/" + id + "/cancel"
  );
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const reloadDataOforder = () => {
    (async () => {
      let response = await getPurchases.getAllByIdAuth(id, logout);
      setData(response.data);
      setReload(true);
      setLoading(false);
    })();
  };

  useEffect(() => {
    (async () => {
      window.localStorage.setItem("menu", 11);
      let response = await getPurchases.getAllByIdAuth(id, logout);
      setData(response.data);
    })();
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 5);
    };
  }, [reload]);
  // Cancel order button---------------->
  const cancel = () => {
    (async () => {
      setLoading(true);
      let response = await purchaseStatusServiceCancel.editDataAuthNid(
        null,
        {},
        logout
      );

      if (response?.data) {
        sendNotification(UseerrorOrsuccess(response));
        reloadDataOforder();
      } else {
        sendNotification(UseerrorOrsuccess(response));
      }
    })();
  };

  const finishOrder = () => {
    (async () => {
      setLoading(true);
      let response = await purchaseStatusServiceConfirm.editDataAuthNid(
        null,
        {},
        logout
      );

      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
    })();
  };

  return (
    <>
      {data && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={11} className="details-view-header">
            <HeaderGeneric
              leftTitle={{
                status: STATUS[data.status],
                color: STATUSCOLOR[data.status],
              }} //titulo del header izquierdo
              cancelButton={{ handleClick: cancel, loading: loading }} //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
              print={{
                to: `/order?id=${data.id}&order_no=${data.no_order}&type=purchase`,
                disable: data.status == "1" ? "none" : "",
              }}
              titleButton={{
                tittle: "Continuar",
                enable: data.articles ? true : false,
              }} // el boton a lado derecho de cancelar este solo es para el titulo
              handleButton={{ handleClick: finishOrder, loading: loading }} //la funcion del boton
              rightTitle={data.type == 1 ? "Orden de compra" : "Cotización"}
              noOrder={data}
              date={data.date_created}
              reloadDataOforder={reloadDataOforder}
              general={data.warehouse.name}
              type={"purchases"}
            />
          </Grid>
          <Grid item xs={11} className="details-view">
            <InventoryGenericDetailsView
              name="Proveedor"
              pay="Condiciones de pago"
              data={data}
              type="purchase"
              reloadDataOforder={reloadDataOforder}
              status={data.status}
              wh={data}
            >
              <ArticlesWithTotal
                data={data}
                service={InventoryService}
                updateInfo={reloadDataOforder}
                type="purchase"
              />
            </InventoryGenericDetailsView>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PurchasesDetails;
