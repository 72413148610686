import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  TextField,
  Box,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { initValAddModel } from "../../../../utils/formHelper";
import { ProductModel } from "../../../../api/productModel";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Card } from "../articleData";
import Presentation from "../../../../api/catalogPresentation";

export function Simple(props) {
  return (
    <>
      <Card p title={"Imagen del producto"}>
        {props.productImage}
      </Card>
      <Card title={"Precio"}>{props.priceContent}</Card>
    </>
  );
}
export function Model(props) {
  const [showInput, setShowInput] = useState(false);
  const [addPresentation, setaddPresentation] = useState('');
  const [type, setType] = useState();
  const [expanded, setExpanded] = useState(false);
  const [editVariant, setEditVariant] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [variant, setVariant] = useState();
  const [modelName, setModelName] = useState("");
  const [presentationName, setPresentationName] = useState();

  const handleAcordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setVariant(null)
    console.log(variant);

  };

  const setPresentation = (id, data) => {
    (async () => {
      try {
        const service = new Presentation();
        const response = await service.editDataAuth(
          id,
          data,
          props.logout
        );
        props.getArticleInfo();
        if (response) {
          sendNotification(UseerrorOrsuccess(response));
        } else {
          sendNotification(
            UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    })();
  };

  const formik = useFormik({
    initialValues: initValAddModel(props.article),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData) => {
      formik.values.product = props.article.id;
      const service = new ProductModel();
      try {
        let response = service.postDataAuth(formData, props.logout);
        props.getArticleInfo();
        formik.resetForm();
        props.getArticleInfo();
        setShowInput(false);
      } catch (error) {
        console.error("Error al enviar los datos", error);
      }
    },
  });

  return (
    <>
      <Card p={"0px"} title={"Modelos"} fontSize='18px'>
        {props.article.model ? (
          <>
            {props.article?.model?.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleAcordion(index)}
                sx={{
                  "&.MuiAccordion-root": {
                    boxShadow: "none", // Elimina la sombra
                    borderBottom: "1px solid #ccc",
                  },
                  "&.Mui-expanded": {
                    margin: "0",
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography >{data.name}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails sx={{ bgcolor: "#fbfbfb" }}>
                  {
                    // Clone the productImage element and pass the article prop
                    React.cloneElement(props.productImage, {
                      article: data,
                    })
                  }

                  <Divider />
                  <Grid
                    p={" 8px 0"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    {React.cloneElement(props.state, {
                      article: data,
                      model: 'model'
                    })}

                  </Grid>
                  <Divider />
                  <Box display={props.variant ? "" : "none"}>
                    <Grid
                      p={" 16px 0"}
                      display={data.presentation ? "" : "none"}
                      alignItems={"center"}
                    >
                      <TextField
                        label={"Selecciona una variante"}
                        variant="outlined"
                        value={
                          variant || (addPresentation !== 'add' && data.presentation?.[0]?.id) || null
                        }
                        select
                        size="small"
                        sx={{ width: "47%", bgcolor: 'white' }}
                        onChange={(e) => {
                          const selectedId = e.target.value;
                          setVariant(selectedId);
                          setaddPresentation("")
                        }}
                      >
                        {data?.presentation?.map((data) => (
                          <MenuItem
                            key={data.id}
                            value={data.id}
                            onClick={() => setPresentationName(data.name)}
                          >
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Button
                        variant="text"
                        onClick={() => {
                          setaddPresentation('add');
                          setVariant(null)
                        }}
                        sx={{ m: "0 8px" }}
                      >
                        + Añadir variante
                      </Button>
                    </Grid>
                    <Divider />

                    <Stack
                      display={addPresentation === "add" ? "none" : "flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      direction={"row"}
                      p={"8px 0"}
                    >
                      <Grid display={data.presentation ? "flex" : "none"} alignItems={"center"} mr={8}>
                        <Typography fontSize={"18px"}>
                          <b>Variante</b>
                        </Typography>

                        {!editVariant ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography fontSize={'20px'} m={"0 8px"}>
                              {presentationName
                                ? presentationName
                                : data.presentation &&
                                  data.presentation.length > 0
                                  ? data.presentation[0].name
                                  : ""}
                            </Typography>
                            <EditOutlinedIcon
                              onClick={() => setEditVariant(true)}
                              sx={{ cursor: "pointer", m: '5px', fontSize: 20 }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              required
                              size="small"
                              label={"Editar variante"}
                              value={presentationName
                                ? presentationName
                                : data.presentation &&
                                  data.presentation.length > 0
                                  ? data.presentation[0].name
                                  : ""}
                              onChange={(e) =>
                                setPresentationName(e.target.value)
                              }
                              sx={{ m: "0 16px" }}
                            />
                            <CloseIcon
                              sx={{
                                fontSize: 30,
                                m: "0 8px",
                                cursor: "pointer",
                                color: "GrayText",
                                ":hover": { color: "red" },
                                display: data.presentation ? "" : "none",
                              }}
                              onClick={() => setEditVariant(false)}
                            />
                            <IconButton
                              color="primary"
                              type="submit"
                              onClick={() => {
                                setPresentation(variant ? variant
                                  : data.presentation &&
                                    data.presentation.length > 0
                                    ? data.presentation[0].id
                                    : "",
                                  { name: presentationName });
                                setEditVariant(false);
                              }}
                            >
                              <CheckCircleOutlineOutlinedIcon
                                sx={{ fontSize: 30 }}
                              />
                            </IconButton>
                          </div>
                        )}
                      </Grid>

                      <Grid display={data.presentation ? "" : "none"}>
                        {React.cloneElement(props.state, {
                          article: data.presentation,
                          SetProductInfo: setPresentation,
                          model: "presentation",
                          presentationId: variant ? variant
                            : data.presentation &&
                              data.presentation.length > 0
                              ? data.presentation[0].id
                              : "",
                        })}
                      </Grid>
                    </Stack>
                  </Box>
                  <Grid p={" 8px 0"}
                  >
                    {React.cloneElement(props.priceContent, {
                      presentation: data.presentation,
                      variantId: variant
                        ?? (data.presentation?.length > 0
                          ? data.presentation[0].id
                          : ""),
                      modelId: data.id,
                      setvariant: setVariant,
                      setPresentation: setPresentation,
                      setaddPresentation: setaddPresentation,
                      addPresentation: addPresentation,
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}

            <Grid
              sx={{
                display: showInput === "model" ? "flex" : "none",
                alignItems: "center",
                p: "8px 16px",
              }}
              component={"form"}
              onSubmit={formik.handleSubmit}
            >
              <TextField
                size="small"
                margin="dense"
                label={"Agregar Modelo"}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              <CloseIcon
                sx={{
                  fontSize: 30,
                  m: "0 8px",
                  cursor: "pointer",
                  color: "GrayText",
                  ":hover": { color: "red" },
                }}
                onClick={() => setShowInput(false)}
              />
              <IconButton color="primary" type="submit" on>
                <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Grid>

            <Button
              onClick={() => {
                setShowInput("model");
                setType("model");
              }}
              type="submit"
              variant="text"
              sx={{
                m: "8px",
                display: showInput ? "none" : "",
              }}
            >
              + Crear Modelo
            </Button>
          </>
        ) : (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1-header"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                    cursor: "default",
                  },
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  component={"form"}
                >
                  <TextField
                    size="small"
                    margin="dense"
                    label={"Agregar Modelo"}
                    name="name"
                    value={modelName}
                    onChange={(e) => setModelName(e.target.value)}
                    sx={{ mr: "8px" }}
                  />
                  <IconButton
                    color="primary"
                    type="submit"
                    onClick={() =>
                      props.productType(modelName)
                    }
                  >
                    <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Stack>
              </AccordionSummary>
            </Accordion>
          </>
        )}
      </Card>
    </>
  );
}
