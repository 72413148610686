import React from "react";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "user-context-fran-dev";
import { UseerrorOrsuccess, useNotification } from "../hooks/snackBarResponse";
import { TransferItemsService } from "../api/inventory/transfersService";

const ITEM_HEIGHT = 48;

export default function DropMenu({
  service,
  id,
  update,
  setOpenDrawer,
  setIdToEdit,
  row,
  setArticleId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const openAria = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Edit = (row) => {
    setIdToEdit(row);
    setOpenDrawer({ right1: true });
    if (setArticleId) {
      setArticleId(id);
    }
    handleClose();
  };

  const Delete = () => {
    (async () => {
      let serviceApi = new service /* TransferItemsService */();
      let response = await serviceApi.deleteAuth(id, logout);
      sendNotification(UseerrorOrsuccess(response));
      handleClose();
      update();
    })();
  };

  return (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openAria ? "long-menu" : undefined}
          aria-expanded={openAria ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={openAria}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              Delete();
            }}
          >
            Borrar
          </MenuItem>
          <MenuItem
            onClick={() => {
              Edit(row);
              update();
            }}
          >
            Editar
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
