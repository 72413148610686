import React, { useEffect, useState } from "react";
import { Divider, Grid, Skeleton, TextField } from "@mui/material";
import { Button } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import BackspaceIcon from "@mui/icons-material/Backspace";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { PresentationService } from "../../../api/pos/warehouseService";
import { useAuth } from "user-context-fran-dev";
import Typography from "@mui/material/Typography";
import { ItemsOrderService } from "../../../api/pos/ordersService";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import GenericEmptyTable from "../../../components/pos/GenericEmptyTable";
import { useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

export default function AddToPurchase({
  model,
  updateSalesList,
  setOpen,
  scanBarcode,
  deleteFilter,
}) {
  const [loading, setLoading] = useState("");
  const [number, setNumber] = useState("");
  const [total, setTotal] = useState("");
  const [modelPrice, setModelPrice] = useState("");
  const { logout, setReloadUser } = useAuth();
  const [presentations, setPresentations] = useState("");
  const [presentationSelected, setPresentationSelected] = useState("");
  const [activePresentation, setActivePresentation] = useState("");
  const [msg, sendNotification] = useNotification();
  const [modelSelected, setModelSelected] = useState("");
  const { id, cr } = useParams();

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        SumbitPresentation();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [number]);

  useEffect(() => {
    setNumber(1);
    if (scanBarcode === 1) {
      let presentationArray = [model];
      setPresentations(presentationArray);
      setPresentationSelected(model.presentation.id);
      setActivePresentation(model.id);
      setModelSelected(model);
      setModelPrice(model.presentation.price);
    } else {
      updatePresentations();
      setReloadUser(true);
    }
  }, []);

  useEffect(() => {
    setTotal(number * modelPrice);
  }, [number]);

  function handleAnswerChange(e) {
    let tmpNumber = e.target.value;
    setNumber(tmpNumber);
    if (presentationSelected) {
      setTotal(tmpNumber * modelPrice);
    }
  }

  const handleChange = (data) => {
    if (data.id && data.available) {
      setPresentationSelected(data.presentation.id);
      setActivePresentation(data.id);
      setModelPrice(data.presentation.price);
      setModelSelected(data);
      if (number) {
        setTotal(number * data.presentation.price);
      }
    }
  };

  const updatePresentations = () => {
    (async () => {
      setLoading(true);

      let presentation = new PresentationService();
      let irequestFilter = [];
      irequestFilter.push({ key: "mdl", value: model.model.id });
      irequestFilter.push({ key: "wh", value: id }); //
      let response = await presentation.filter(irequestFilter, logout);
      setPresentations(response.data);
      if (response) {
        setPresentationSelected(response.data["0"].presentation.id);
        setActivePresentation(response.data["0"].id);
        setModelSelected(response.data["0"]);
        setModelPrice(response.data["0"].presentation.price);
      }
      setLoading(false);
    })();
  };

  const SumbitPresentation = () => {
    (async () => {
      let response = null;

      let itemsService = new ItemsOrderService();
      if (scanBarcode === true && number !== 0) {
        response = await itemsService.postDataAuth(
          {
            qty: Number(number),
            price: model.presentation.price,
            discount: 0,
            available: model.id,
            presentation: model.presentation.id,
            cash_register: cr,
            model: model.model.id,
          },
          logout
        );
      } else {
        response = await itemsService.postDataAuth(
          {
            qty: Number(number),
            price: modelPrice,
            discount: 0,
            available: activePresentation,
            presentation: presentationSelected,
            cash_register: cr,
            model: model.model.id,
          },
          logout
        );
      }
      if (response) {
        sendNotification(UseerrorOrsuccess(response));
        updateSalesList();
        deleteFilter();
      }
      setOpen(false);
    })();
  };

  const ButtonCal = (props) => {
    const event = () => {
      if (props.label === "Delete") {
        setNumber(number.slice(0, -1));
      } else if (props.label === "Clear") {
        setNumber("");
      } else {
        setNumber(number + props.label);
      }
    };
    return (
      <>
        {props.label === "Enter" && (
          <Button
            variant="contained"
            disabled={!number || !presentationSelected || loading}
            type="submit"
            color="success"
            onClick={() => {
              SumbitPresentation();
            }}
            sx={{ width: "210px" }}
          >
            <KeyboardReturnIcon />
          </Button>
        )}
        {props.label === "Clear" && (
          <Button
            variant="outlined"
            onClick={() => {
              event();
            }}
          >
            {" "}
            <DeleteIcon />
          </Button>
        )}
        {props.label === "Delete" && (
          <Button
            variant="outlined"
            sx={{ width: "70px" }}
            color="error"
            onClick={() => {
              event();
            }}
          >
            <BackspaceIcon />
          </Button>
        )}
        {props.label === "." && (
          <Button
            color="primary"
            sx={{ width: "70px" }}
            onClick={() => {
              event();
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.label}
            </Typography>
          </Button>
        )}
        {props.label === "0" && (
          <Button
            color="primary"
            sx={{ width: "70px" }}
            onClick={() => {
              event();
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {props.label}
            </Typography>
          </Button>
        )}
        {props.label !== "Enter" &&
          props.label !== "Delete" &&
          props.label !== "Clear" &&
          props.label !== "0" &&
          props.label !== "." && (
            <Button
              key="one"
              sx={{ width: "70px" }}
              onClick={() => {
                event();
              }}
            >
              <Typography
                variant="h4"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {props.label}
              </Typography>
            </Button>
          )}
      </>
    );
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="stretch"
    >
      <Grid xs={7}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="start"
          sx={{ mb: "8px" }}
          xs
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="start"
          >
            <Grid maxHeight={"80px"}>
              <CardMedia
                component="img"
                alt="Imagen de producto"
                height="80"
                width="60"
                image={model.model?.image?.url ? model.model.image.url : "alt"}
              />
            </Grid>
            <Grid marginLeft={2} xs={6}>
              <Typography gutterBottom variant="h6" component="div">
                {model.model?.name}
              </Typography>
              <Typography
                sx={{ display: "flex" }}
                variant="body2"
                color="text.primary"
              >
                {model.model?.product.name}
              </Typography>
              <Typography
                sx={{ display: "flex" }}
                ocmponent="div"
                variant="body2"
                color="text.primary"
              >
                {model.model.product?.sku}
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="end"
              alignItems="end"
              xs={3}
            >
              <Grid xs>
                <Typography
                  sx={{
                    fontSize: 22,
                    textOverflow: "ellipsis",
                    fontWeight: 600,
                  }}
                  color="text.primary"
                  gutterBottom
                >
                  {modelPrice ? (
                    <CurrencyFormat
                      value={modelPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              <Grid xs={13}>
                {modelPrice && (
                  <Typography
                    component="div"
                    sx={{ textOverflow: "ellipsis" }}
                    noWrap
                  >
                    {modelSelected.presentation.name}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="start"
          alignItems="start"
          sx={{ overflow: "scroll" }}
          height={250}
        >
          <Grid container direction="row" sx={{ height: "210px", mb: "16px" }}>
            {presentations &&
              !loading &&
              presentations.map((data, index) => (
                <Grid key={index} xs marginTop={"3px"}>
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{
                      maxWidth: { md: 435, sm: 418 },
                      minWidth: 195,
                      height: 80,
                      marginRight: "5px !important",
                      marginBottom: 1,
                      padding: 1,
                      overflow: "hidden",
                      backgroundColor:
                        activePresentation === data.id && data.available
                          ? "#642BA0"
                          : "#c2c9d6",
                      color: activePresentation === data.id ? "white" : "",
                    }}
                    onClick={() => {
                      handleChange(data);
                    }}
                  >
                    <CardContent sx={{ padding: "1px" }}>
                      <Grid container direction="row" justifyContent="flex">
                        <Grid xs={8}>
                          <Typography
                            gutterBottom
                            variant="h8"
                            component="div"
                            sx={{ textOverflow: "ellipsis" }}
                            noWrap
                          >
                            {data.presentation?.name
                              ? data.presentation?.name
                              : "Nombre De Producto"}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h8"
                            component="div"
                            sx={{ textOverflow: "ellipsis" }}
                            noWrap
                          >
                            {data?.barcode ? data?.barcode : ""}
                          </Typography>
                        </Grid>
                        <Grid xs={4} textAlign={"right"}>
                          <Typography
                            gutterBottom
                            variant="h8"
                            component="div"
                            sx={{ textOverflow: "ellipsis" }}
                            noWrap
                          >
                            <CurrencyFormat
                              value={data.presentation?.price}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h8"
                            component="div"
                            sx={{ textOverflow: "ellipsis" }}
                            noWrap
                          >
                            Disp: {data.available ? data.available : "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
          {!presentations && !loading && (
            <Grid item className="add-product">
              <GenericEmptyTable
                msg={"No hay presentaciones para este producto"}
              />
            </Grid>
          )}
          {loading && (
            <Grid
              item
              className="add-product"
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              margin={1}
            >
              <Skeleton variant="rectangular" width={220} height={120} />

              {/* <CircularProgress /> */}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Divider orientation="vertical" flexItem />
      <Grid xs={3} direction="column">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            focused={true}
            name="email"
            sx={{ width: 135, mt: "20px", mb: "10px" }}
            label="Cantidad"
            type="number"
            required
            value={number}
            onChange={handleAnswerChange}
            variant="outlined"
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"7"} />
              <ButtonCal label={"8"} />
              <ButtonCal label={"9"} />
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"4"} />
              <ButtonCal label={"5"} />
              <ButtonCal label={"6"} />
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"1"} />
              <ButtonCal label={"2"} />
              <ButtonCal label={"3"} />
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"."} />
              <ButtonCal label={"0"} />
              <ButtonCal label={"Delete"} />
              {/* <ButtonCal label={'Enter'}/> */}
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 0,
              },
            }}
          >
            <ButtonGroup
              orientation="horizontal"
              aria-label="vertical contained button group"
              variant="outlined"
            >
              <ButtonCal label={"Enter"} />
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
