import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../GenericsModals";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Box,
  Button,
} from "@mui/material";
import { ImageCreator } from "../../api/landing/creatorManages";
import { useAuth } from "user-context-fran-dev";
import nofound from "../../assets/no_found.png";

const SIZETYPE = [
  {},
  { position: "relative", width: "320px", height: "180px", margin: "8px" }, //16/9  id 1
  { position: "relative", width: "180px", height: "320px", margin: "8px" }, //9:16  id 2
  { position: "relative", width: "320px", height: "180px", margin: "8px" }, //16/9  id 3
  { position: "relative", width: "150px", height: "150px", margin: "8px" }, //1:1   id 4
];

const Gallery = ({
  open,
  setOpen,
  title,
  setSelect,
  select,
  setImage,
  type = 1,
}) => {
  const [data, setData] = useState("");
  const { logout } = useAuth();

  const UpdateImage = async () => {
    let irequestFilter = [];
    let getDirec = new ImageCreator();
    let response = await getDirec.filter(irequestFilter, logout);
    setData(response.data);
  };

  useEffect(() => {
    UpdateImage();
  }, []);

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={title}
      size={"md"}
      actions={
        <Button
          onClick={() => {
            setImage(select);
          }}
        >
          Seleccionar{" "}
        </Button>
      }
    >
      <Grid container>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <RadioGroup
            sx={{ flexDirection: "row", justifyContent: "space-around" }}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="is_shipping_paid_by_client"
            value={select}
            onChange={(e) => {
              setSelect(e.target.value);
            }}
          >
            {data &&
              data.map((row, index) => (
                <Box key={index} sx={SIZETYPE[3]}>
                  <img
                    src={row?.url || nofound}
                    alt="no-found"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  ></img>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "1px",
                      left: "1px",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <FormControlLabel
                      value={row.id}
                      sx={{ margin: "8px" }}
                      control={<Radio />}
                    />
                  </Box>
                </Box>
              ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </CustomizedDialogs>
  );
};

export default Gallery;
