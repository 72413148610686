import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Container from "../../layout/gridContainer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import {
  TableProduction,
  TablePurchase,
  TableSales,
  TableTransfer,
} from "./warehouse/tableGeneric";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const InventarioWarehouse = () => {
  const purchase = [
    "# orden / Fecha",
    "Proveedor",
    "Estatus",
    "Artículos",
    "Total",
    "Ver",
  ];
  const sales = [
    "# orden / Fecha",
    "Cliente",
    "Estatus",
    "Artículos",
    "Total",
    "Ver",
  ];
  const [category, setCategory] = useState("");
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const tabs = ["order-purchase", "order-sale", "production", "transfers"]


  const totalPerPage = 10;


  useEffect(() => {
    if (!params.has("tab")) {
      params.set("tab", "order-purchase");
      setParams(params);
      setValue(0);
    } else {

      //poner un swithc para poner el tab correcto   "", ""
      switch (params.get("tab")) {
        case "order-purchase":
          setValue(0);
          break;
        case "order-sale":
          setValue(1);
          break;
        case "production":
          setValue(2);
          break;
        case "transfers":
          setValue(3);
          break;
      }

    }
  }, [params]);

  const handleChangesTab = (event, newValue) => {
    params.set("tab", tabs[newValue]);
    setParams(params);
    setValue(newValue);

  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Container>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems="center"
        m={'24px 0 8px'}>
        <Grid >
          <h2>Entradas y salidas</h2>
        </Grid>
        <Grid >
          <Button
            onClick={() => {
              window.localStorage.setItem("menu", 23);
              navigate("administration");
            }}
            fullWidth
            variant="contained"
            sx={{ borderRadius: 60 }}
          >
            Administrar almacén
          </Button>

        </Grid>
      </Grid>
      <p style={{ padding: '8px 0' }}>
        Registra la entrada de mercancía proveniente de una orden de compra. Gestiona la salida de productos para cumplir
        con las ventas realizadas. Mueve productos entre distintos almacenes o sucursales.
      </p>
      <Grid container spacing={2}>

        {/* <GenericSearch
          setLoading={setLoading}
          updateInfo={UpdateSupplierList}
          Service={CustomerService}
          setData={setTable}
          label={"Buscar por Nombre"}
        /> */}
        <Grid item xs={12} mt={2}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChangesTab} variant="fullWidth">
              <Tab icon={<EastIcon />} name="pedo" label="  Por recibir (compras)  " {...a11yProps(0)} />
              <Tab icon={<KeyboardBackspaceIcon />} name="gas" label="  Por entregar (ventas)  " {...a11yProps(1)} />
              <Tab icon={<PrecisionManufacturingIcon />} sx={{ display: 'none' }} disabled label="  Por producir  " {...a11yProps(2)} />
              <Tab icon={<MoveUpIcon />} label="  por transferir  " {...a11yProps(3)} />
            </Tabs>
          </Box>
          {/*<SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
                  >*/}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <TablePurchase />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <TableSales />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <TableProduction />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <TableTransfer />
          </TabPanel>
        </Grid>
      </Grid>
    </Container >
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default InventarioWarehouse;
