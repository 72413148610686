import React from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} {...props} />;
});

const HomeNotifications = ({ noty }) => {
  const TYPES = ["", "info", "warning", "error", "success"];
  return (
    <div style={{ paddingBottom: "32px" }}>
      {noty && (
        <Typography variant="h6" component="div" sx={{ color: "text.primary" }}>
          <Grid container justifyContent={{ xs: "center", md: "start" }}>
            <Grid>
              <strong>Acciones requeridas</strong>
            </Grid>
          </Grid>
        </Typography>
      )}
      <Box
        sx={{
          /* width: '100%', */ marginTop:
            "16px" /* height:"50vh ",*/ /* overflowY: "auto" */,
        }}
      >
        {noty &&
          noty.map((data, index) => {
            return (
              <Alert
                key={index}
                severity={TYPES[data.type]}
                sx={{ margin: "8px 0px 8px 0px" }}
              >
                {data.message}
              </Alert>
            );
          })}
      </Box>
    </div>
  );
};

export default HomeNotifications;
