import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericAddress from "../../../../components/GenericAddress";
import Container from "../../../../layout/gridContainer";
import GenericProfile, {
  ProfileForm,
} from "../../../../components/GenericProfile";
import CustomerService from "../../../../api/customerService";
import PaymentConditions, {
  PaymentConditionsForm,
} from "../../../../components/paymentConditions";
import Phonebook from "../../../../components/inventario/phonebook";
import Comments from "../../../../components/inventario/comments";
import { useAuth } from "user-context-fran-dev";
import { Card } from "../../../catalog/articleDetail/articleData";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Grid, Typography, Button, Stack } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import dayjs from "dayjs";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const CustomerDetails = (props) => {
  const { logout } = useAuth();
  const { id } = useParams();
  const [dataCustomer, setDataCustomer] = useState();

  useEffect(() => {
    GetInfoCustomer();
    return () => {
      window.localStorage.setItem("menu", 5);
    };
  }, []);

  const GetInfoCustomer = () => {
    (async () => {
      let getCustomer = new CustomerService();
      let response = await getCustomer.getAllByIdAuth(id, logout);

      setDataCustomer(response.data);
    })();
  };

  return (
    <>
      {dataCustomer && (
        <Container>
          <Grid item xs={12} sx={{ margin: '24px 0 ' }}>
            <Grid container spacing={0}>
              <Grid item xs={11}>
                <Typography
                  variant="h5"
                  gutterBottom
                  component="div"
                  className="title-card"
                  sx={{ paddingBottom: "0" }}
                >
                  <strong>Cliente</strong>
                </Typography>
              </Grid>

            </Grid>
          </Grid>

          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={8}>
              <Card title={"Perfil"} fontSize={"20px"} padding={"14px"}>
                <ProfileForm
                  data={dataCustomer}
                  UpdateInfo={GetInfoCustomer}
                  target={"client"}
                />
              </Card>
              <Card
                title={"Condiciones de pago"}
                fontSize={"20px"}
                padding={"14px"}
              >
                <PaymentConditionsForm
                  data={dataCustomer}
                  UpdateInfo={GetInfoCustomer}
                  target={"client"}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card title={"Estado"} display="flex" align="center">
                <div style={{ alignItems: "center", display: "flex" }}>
                  <CalendarMonthIcon />{" "}
                  <p style={{ padding: "0 8px" }}>Creado</p>
                </div>
                <strong>
                  {dayjs(dataCustomer.date_created).format("MM DD, YYYY")}
                </strong>
              </Card>
              <GenericAddress
                data={dataCustomer}
                UpdateInfo={GetInfoCustomer}
                target={"client"}
              />
              <Phonebook
                service={CustomerService}
                data={dataCustomer}
                UpdateInfo={GetInfoCustomer}
                target={"client"}
              />
              <Comments
                data={dataCustomer}
                UpdateInfo={GetInfoCustomer}
                target={"client"}
              />
            </Grid>
          </Stack>
        </Container>
      )}
    </>
  );
};
export default CustomerDetails;
