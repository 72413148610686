import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "../../scss/home.scss";
import { useAuth } from "user-context-fran-dev";
import GenericUserMenu from "../../components/GenericUserMenu";
import keskinubelogo from "../../assets/keskinube.png";

export default function InicioDash({ changeMode, mode }) {
  const { auth } = useAuth();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none" /* border: "2px solid cyan" */,
        }} /* className='home-nav' */
      >
        <Toolbar
          sx={{ display: "flex", justifyContent: "space-between", mb: "16px" }}
        >
          <Box
            component="img"
            src={"/img/keskinube_beta_color.png"}
            alt={`Logo Keskinube`}
            sx={{
              height: "auto",
              width: "160px",
              cursor: "pointer",
              marginLeft: "20px",
              maxWidth: "50%",
            }}
          />
          <div>
            <Typography sx={{ display: "inline", color: "text.primary" }}>
              <strong>{auth.user.first_name}</strong>
            </Typography>
            <GenericUserMenu
              changeMode={changeMode}
              mode={mode}
              color={{ light: "#000", dark: "#fff" }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
