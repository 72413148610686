import React, { useState } from "react";
import { IconButton, CardHeader } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import {
  STATUSTRANSFER,
  STATUSTRANSFERCOLOR,
} from "../../../../utils/constants";
import OptionButton from "../../../../components/inventario/optionButton";
import DrawerDetails from "../drawerDetails";

const TransferTable = (props) => {
  const { data } = props;
  const [openDrawer, setOpenDrawer] = useState({});

  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        hover
        onDoubleClick={() => {
          window.localStorage.setItem("menu", 24);
          navigate(`transfer/${data.id}`);
        }}
      >
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.warehouse_source.name}
              </Typography>
            }
          />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.warehouse_destiny.name}
              </Typography>
            }
          />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Dia text={data.date_created} />
              </Typography>
            }
          />
        </TableCell>
        <TableCell align="left">
          <CardHeader
            sx={{ m: 0, p: 0 }}
            title={
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Chip
                  label={STATUSTRANSFER[data.status]}
                  color={STATUSTRANSFERCOLOR[data.status]}
                  className="chip-with"
                  variant="outlined"
                />
              </Typography>
            }
          />
        </TableCell>
        <TableCell>
          <OptionButton
            menu={24}
            id={data.id}
            wh={true}
            openDrawer={() => setOpenDrawer({ right: true })}
            type={"trans"}
          />
        </TableCell>
      </TableRow>
      <DrawerDetails
        openDrawer={openDrawer}
        toggleDrawer={() => { }}
        data={data}
        close={() => setOpenDrawer(!openDrawer)}
        type={'transfer'}
        status={STATUSTRANSFER}
        statusColor={STATUSTRANSFERCOLOR}
      />
    </>
  );
};

const Dia = ({ text }) => {
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  return (
    <Typography variant="h7" component="div">
      {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
    </Typography>
  );
};

export default TransferTable;
