import axios from "axios";
/* import { BASE_PATH } from './utils/constants';
import { useSnackbar } from 'notistack'; */
/* const {logout, auth}= useAuth(); */

const fetchData = axios.create();

axios.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return err;
  }
);

axios.interceptors.response.use(
  (req) => {
    return req;
  },
  (err) => {
    if (err.response.status === 403) {
      window.location = "/error";
      return Promise.reject(err);
    }
    if (err.response.status === 401) {
      localStorage.removeItem("token");
      window.location = "/";
      return Promise.reject(err);
    }
    return err;
  }
);

export default fetchData;
