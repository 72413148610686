import React, { useEffect, useState } from "react";
import BasicModal from "../../../../components/GenericModal";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useAuth } from "user-context-fran-dev";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicTable from "../../../../components/GenericTable";
import Divider from "@mui/material/Divider";
import { ProductModel } from "../../../../api/productModel";
import CircleIcon from "@mui/icons-material/Circle";
import Collapse from "@mui/material/Collapse";
import { Button, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, Paper, Link, TextField } from "@mui/material";
import { RawRecipe } from "../../../../api/recipeManages";
import { GenericEmptyTable } from "../../../../components/GenericEmptyTable";
import ImageSize from "../../../../components/ImageSize";
import { Box } from "@mui/system";
import { TableModal } from "../../../../components/GenericsModals";
import { ProductModelService } from "../../../../api/productService";
import { initValAddRaw, valSchAddRaw } from "../../../../utils/formHelper";
import * as Yup from "yup";
import { useFormik } from "formik";

const ModalPresentation = ({
  open,
  setOpen,
  article,
  edit,
  GetRecipeGroup,
  data,
}) => {
  const [dataArticles, setDataArticles] = useState();
  const { logout, auth } = useAuth();

  const GetProductModel = (id) => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ key: "product", value: `${id}` });
      irequestFilter.push({ key: "is_active", value: `true` });
      let getProductModel = new ProductModelService();
      let response = await getProductModel.filter(irequestFilter, logout);

      setDataArticles(response.data);
    })();
  };

  const PostRaw = (presentation, model, quantity) => {
    (async () => {
      let formData = {
        user: auth.user.pk,
        presentation: presentation,
        model: model,
        recipe: data.id,
        quantity: quantity,
      };

      let postRaw = new RawRecipe();
      let response = await postRaw.postDataAuth(formData, logout);

      GetRecipeGroup();
      setOpen(false);
    })();
  };
  const PutRaw = (row, quantity) => {
    (async () => {
      let formData = {
        user: auth.user.pk,
        presentation: row.presentation.id,
        model: row.model.id,
        recipe: row.recipe,
        quantity: quantity,
      };

      let postRaw = new RawRecipe();
      let response = await postRaw.editDataAuth(row.id, formData, logout);

      GetRecipeGroup();
      setOpen(false);
    })();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!edit) {
      GetProductModel(article.id);
    } else {
      setDataArticles(article);
    }
  }, [article, open]);

  return (
    <BasicModal
      open={open}
      setOpen={setOpen}
      title={"Seleccionar presentación"}
    >
      <Grid container>
        {/*         <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Box sx={{ display: "flex" }}>
                <ImageSize
                  img={
                    edit
                      ? article.model.image
                        ? article.model.image.url
                        : ""
                      : article.main_model?.image
                      ? article.main_model.image.url
                      : ""
                  }
                  weight={2}
                />
                <Box ml="16px">
                  <h3>{edit ? article.model.product.name : article.name}</h3>
                  <h4>{edit ? article.model.product.sku : article.sku}</h4>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="end">
                {/* <h4>{edit? article.category.name : article.category.name}</h4> 
              </Grid>
              <Grid container justifyContent="end" mt="8px">
                {(edit ? article.presentation.is_active : article.is_active) ? (
                  <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                ) : (
                  <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={9}>
          <Grid container>
            <ImageSize
              img={
                edit
                  ? article.model.image
                    ? article.model.image.url
                    : ""
                  : article.main_model?.image
                  ? article.main_model.image.url
                  : ""
              }
              weight={2}
            />
            <Grid item ml={{ xs: "8px", md: "32px" }}>
              <h3>{edit ? article.model.product.name : article.name}</h3>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            justifyContent={"start"}
            alignItems={"end"}
            flexDirection="column"
            sx={{ height: "64px" }}
          >
            <h4>{edit ? article.model.product.sku : article.sku}</h4>
            {/* <h4> {edit? article.model.name  :article.model.name} </h4> */}
            <h4>
              {" "}
              {(edit ? article.presentation.is_active : article.is_active) ? (
                <Typography sx={{ color: "green", fontSize: 15 }}>
                  Activo
                </Typography>
              ) : (
                <Typography sx={{ color: "red", fontSize: 15 }}>
                  Desactivado
                </Typography>
              )}{" "}
            </h4>
          </Grid>
        </Grid>

        {/*         <Grid item xs={12}>
          <Divider
            sx={{
              height: "1px",
              borderColor: "text.primary",
              backgroundColor: "text.primary",
              marginTop: "8px",
            }}
          ></Divider> 
        </Grid> */}

        <Grid item xs={12}>
          <Grid container sx={{ maxHeight: "60vh", overflowX: "auto" }}>
            <Grid item xs={12}>
              <br />
              {!edit &&
                dataArticles &&
                dataArticles.map((row, index) => (
                  <Table table={row} key={index} func={PostRaw} />
                ))}
              {edit && dataArticles && (
                <TableEdit row={dataArticles} PutRaw={PutRaw} />
              )}

              {!dataArticles && <GenericEmptyTable />}

              {/* add Table */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BasicModal>
  );
};

const Table = ({ table, func }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Paper sx={{ marginTop: "8px", background: "background.default" }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: "8px 16px",
              cursor: "pointer",
              backgroundColor: "background.default",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Grid container sx={{ height: "100%" }} alignItems="center">
                  {table.is_active ? (
                    <CircleIcon
                      sx={{ color: "green", fontSize: 15, marginRight: "8px" }}
                    />
                  ) : (
                    <CircleIcon
                      sx={{ color: "red", fontSize: 15, marginRight: "8px" }}
                    />
                  )}
                  <Typography variant="h7" component="div">
                    {table.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  sx={{ height: "100%" }}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    sx={{ m: 0, p: 0 }}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {open ? (
                      <KeyboardArrowUpIcon
                        fontSize="small"
                        sx={{ color: "text.primary" }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        sx={{ color: "text.primary" }}
                      />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Collapse in={open} timeout={1000} unmountOnExit>
            <Grid item xs={12}>
              {table?.presentation && (
                <BasicTable
                  className="marginTable"
                  titles={[
                    "Presentación",
                    /* "Precio", */
                    "Estatus",
                    "Cantidad",
                    "Acción",
                  ]}
                >
                  {table.presentation.map((row, index) => (
                    <RowInfo row={row} key={index} func={func} data={table} />
                  ))}
                </BasicTable>
              )}
              {!table?.presentation && <GenericEmptyTable />}
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </>
  );
};

const RowInfo = ({ row, func, data }) => {
  const [quantity, seQuantity] = useState(0);
  const [error, setError] = useState("");

  const SetRaw = () => {
    /* let number = parseInt(quantity); */
    if (quantity !== null && quantity !== 0 && isFinite(quantity)) {
      func(row.id, data.id, quantity);
      setError("");
    } else {
      setError("Requerido");
    }
    /* func(row.id, data.id, quantity) */
  };
  const handleCantidad = (e) => {
    seQuantity(e.target.value);
  };

  const formik = useFormik({
    initialValues: initValAddRaw(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddRaw()),
    onSubmit: async (formData) => {
      func(row.id, data.id, formData.quantity);
    },
  });

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left">{row.name} </TableCell>
      {/* <TableCell align="left">{row.price}</TableCell> */}
      <TableCell align="left">
        {" "}
        {row.is_active ? (
          <CircleIcon sx={{ color: "green", fontSize: 15 }} />
        ) : (
          <CircleIcon sx={{ color: "red", fontSize: 15 }} />
        )}{" "}
      </TableCell>
      <TableCell align="left">
        {/*         <TextField
          fullWidth
          name="quantity"
          values={quantity}
          onChange={handleCantidad}
          error={error ? true : false}
          helperText={error ? error : ""}
          label="Cantidad"
          margin="dense"
          size="small"
          variant="outlined"
        /> */}
        <form onSubmit={formik.handleSubmit}>
          <TextField
            /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
            type="number"
            fullWidth
            label="Cantidad"
            size="small"
            name="quantity"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            helperText={formik.touched.quantity ? formik.errors.quantity : ""}
          />
        </form>
      </TableCell>
      <TableCell align="left">
        <form onSubmit={formik.handleSubmit}>
          <Button
            type="submit"
            underline="none"
            /* onClick={() => {
            SetRaw();
          }} */
          >
            <Typography variant="subtitle1" component="div">
              Seleccionar
            </Typography>
          </Button>
        </form>
      </TableCell>
    </TableRow>
  );
};

const TableEdit = ({ row, PutRaw }) => {
  const [quantity, seQuantity] = useState(row.quantity);
  const [error, setError] = useState("");
  const handleCantidad = (e) => {
    seQuantity(e.target.value);
  };

  const EditRaw = () => {
    /* let number = parseInt(quantity); */
    if (quantity != null && quantity != "" && isFinite(quantity)) {
      PutRaw(row, quantity);
    } else {
      setError("Requerido");
    }
  };
  const formik = useFormik({
    initialValues: initValAddRaw(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchAddRaw()),
    onSubmit: async (formData) => {
      PutRaw(row, formData.quantity);
    },
  });

  return (
    <>
      <Paper sx={{ marginTop: "8px" }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: "8px 16px",
              cursor: "pointer",
              backgroundColor: "background.default",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h7" component="div">
                  {row.model.name}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid item xs={12}>
            <BasicTable
              className="marginTable"
              titles={[
                "Presentación",
                "Precio",
                "Estatus",
                "Cantidad",
                "Acción",
              ]}
            >
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell align="left">{row.presentation.name} </TableCell>
                <TableCell align="left">{row.presentation.price}</TableCell>

                <TableCell align="left">
                  {row.presentation.is_active ? (
                    <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                  ) : (
                    <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                  )}
                </TableCell>

                <TableCell align="left">
                  {/*               <TextField
                    fullWidth
                    name="quantity"
                    value={quantity}
                    onChange={handleCantidad}
                    error={error ? true : false}
                    helperText={error ? error : ""}
                    label="Cantidad"
                    margin="dense"
                    size="small"
                    variant="outlined"
                  /> */}
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      /*     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  */
                      type="number"
                      fullWidth
                      label="Cantidad"
                      size="small"
                      name="quantity"
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.quantity &&
                        Boolean(formik.errors.quantity)
                      }
                      helperText={
                        formik.touched.quantity ? formik.errors.quantity : ""
                      }
                    />
                  </form>
                </TableCell>
                <TableCell align="left">
                  <form onSubmit={formik.handleSubmit}>
                    <Button
                      type="submit"
                      /*   onClick={() => {
                      EditRaw();
                    }} */
                    >
                      <Typography variant="subtitle1" component="div">
                        Seleccionar
                      </Typography>
                    </Button>
                  </form>
                </TableCell>
              </TableRow>
            </BasicTable>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ModalPresentation;
