import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import { TextField, Grid } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initAddBusiness, ValAddBusiness } from "../../utils/formHelper";
import BusinessManages from "../../api/businessManages";
import { useAuth } from "user-context-fran-dev";
import { useNavigate } from "react-router-dom";

const steps = ["Información", "Mis aplicaciones", "Listo"];

export default function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const { logout, auth } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initAddBusiness(),
    validationSchema: Yup.object(ValAddBusiness()),
    onSubmit: async (formData) => {},
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const setBussines = async () => {
    let setBussines = new BusinessManages();
    let response = await setBussines.postDataAuth(formik.values, logout);

    if (Number(response.status) < 300) {
      navigate(`/home`);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    const FIRST_STEP = 0;
    if (activeStep == FIRST_STEP) {
      formik.handleSubmit();

      if (Object.entries(formik.errors).length === 0 && formik.values.email) {
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      }
      return;
    }
    if (isLastStep()) {
      setBussines();

      return;
    }

    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" /* onClick={handleStep(index)} */>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Grid className="config-steps">
              {activeStep + 1 === 1 && formik && <Step1 formik={formik} />}
              {activeStep + 1 === 2 && <Step2 />}
              {activeStep + 1 === 3 && <Step3></Step3>}
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, color: "text.primary" }}
              >
                Atras
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={() => {
                  handleComplete();
                }}
                sx={{ mr: 1 }}
              >
                {isLastStep() ? " Completar y continuar " : " Siguiente"}
              </Button>
              {/* {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Completar y continuar'}
                  </Button>
                ))} */}
            </Box>
          </Fragment>
        )}
      </div>
    </Box>
  );
}
