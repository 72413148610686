import React, { useState, useEffect } from "react";
import Container from "../../layout/gridContainer";
import { Grid, Typography, Button } from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import { StoreSettings } from "../../api/store/storeSettings";
import { StoreWhVerify } from "../../api/store/storeOrders";
import { useNavigate } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import { GenericHeaderTitle } from "../../components/GenericHeader";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse.js";

const ID_DEFAULT = 1;

export const StoreStatus = () => {
  const { logout, auth } = useAuth();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [msg, sendNotification] = useNotification();

  const update = () => {
    (async () => {
      const settings = new StoreSettings();
      const response = await settings.getAllByIdAuth(ID_DEFAULT, logout);

      setData(response.data);
    })();
  };
  useEffect(() => {
    window.localStorage.setItem("menu", 4);
    update();
  }, []);

  const verifyWarehouse = () => {
    (async () => {
      const settings = new StoreWhVerify();
      const response = await settings.postDataAuth(null, logout);
      sendNotification(UseerrorOrsuccess(response));
      setData(response.data);
    })();
  };

  return (
    <Container>
      <Grid marginBottom={"32px"}>
        <GenericHeaderTitle
          title={
            <>
              <Typography variant="h6" component="div">
                <strong>Estado de mi página</strong>
              </Typography>
              <Typography variant="body1" mt="2px">
                Verifica que todo es listo para comenzar a vender en línea
              </Typography>
            </>
          }
        ></GenericHeaderTitle>

        {/*   <Box sx={{backgroundColor:'gray', padding: '16px'}}>
            <Typography variant="h6" component="div">
            <strong>Estado de mi página</strong>
            </Typography>
            <Typography variant="body1" mt="8px">
            Verifica que todo es listo para comenzar a vender en línea
            </Typography>
         </Box> */}
      </Grid>
      <Grid container direction="row" marginBottom={"32px"}>
        <Grid xs>
          <Typography variant="h6" component="div">
            <strong>
              Verifica tus artículos disponibles para venta en línea
            </strong>
          </Typography>
          <Typography variant="body1" mt="8px">
            Selecciona de tu catalogo los artículos que estarás mostrando en tu
            tienda en línea.
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/online-store/products?page=1`);
            }}
          >
            ir a verificar
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" marginBottom={"32px"}>
        <Grid item xs>
          <Typography variant="h6" component="div">
            <strong>Verifica las reglas de operación</strong>
          </Typography>
          <Typography variant="body1" mt="8px">
            Define como quieres que funcione tu tienda y asigna costos de envío.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="text"
            onClick={() => {
              window.localStorage.setItem("menu", 4);
              navigate(`/online-store/operation-rules`);
            }}
          >
            ir a verificar
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" marginBottom={"32px"}>
        <Grid item xs>
          <Typography variant="h6" component="div">
            <strong>
              {" "}
              {data && (
                <VerifiedIcon
                  sx={{
                    color:
                      data.is_warehouse_verified && data.is_warehouse_verified
                        ? "green"
                        : "gray",
                    marginRight: "16px",
                  }}
                />
              )}
              Verifica tus puntos de venta en línea
            </strong>
          </Typography>
          <Typography variant="body1" mt="8px">
            Selecciona los almacenes que estarán disponibles para venta en línea
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {data && (
            <Button
              disabled={data.is_warehouse_verified}
              variant="text"
              onClick={() => {
                verifyWarehouse();
              }}
            >
              Validar
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" marginBottom={"32px"}>
        <Grid item xs>
          <Typography variant="h6" component="div">
            <strong>
              {data && (
                <VerifiedIcon
                  sx={{
                    color:
                      data.envioclick_key_verified &&
                      data.envioclick_webhook_verified
                        ? "green"
                        : "gray",
                    marginRight: "16px",
                  }}
                />
              )}
              Plataforma de envíos
            </strong>
          </Typography>
          <Typography variant="body1" mt="8px">
            Actualiza la información de envioclick para habilitar los envíos.
          </Typography>
        </Grid>
        <Grid xs={2}>
          {data && (
            <Button
              disabled={data.envioclick_key_verified}
              onClick={() => {
                navigate("/online-store/shipments");
              }}
              variant="text"
            >
              ir a verificar
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" marginBottom={"32px"}>
        <Grid item xs>
          <Typography variant="h6" component="div">
            <strong>
              {data && (
                <VerifiedIcon
                  sx={{
                    color: data.clip_key_verified ? "green" : "gray",
                    marginRight: "16px",
                  }}
                />
              )}
              Pasarela de pagos
            </strong>
          </Typography>
          <Typography variant="body1" mt="8px">
            Actualiza la información de clip para habilitar los pagos en línea.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {data && (
            <Button
              disabled={data.clip_key_verified}
              onClick={() => {
                navigate("/online-store/payments");
              }}
              variant="text"
            >
              ir a verificar
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" marginBottom={"32px"}>
        <Grid item xs>
          <Typography variant="h6" component="div">
            <strong>Correo electrónico de salida</strong>
          </Typography>
          <Typography variant="body1" mt="8px">
            Actualiza la información del correo de salida para las
            notificaciones con tus clientes.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="text"
            onClick={() => {
              window.localStorage.setItem("menu", 1);
              navigate("/administration/settings");
            }}
          >
            ir a verificar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
