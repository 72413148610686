import {
  CardHeader,
  Grid,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { WhiteTable } from "../../components/GenericTable";
import { formatMoney, formatNumber } from "../../utils/functions";

const BodyOrder = ({ type, data }) => {
  if (type === "purchase" || type === "sales") {
    return (
      <>
        <BodySalesPurchase type={type} data={data} />
      </>
    );
  } else if (type === "order_store") {
    return (
      <>
        <BodyStoreOrder data={data} />
      </>
    );
  } else {
    return <></>;
  }
};
const BodySalesPurchase = ({ data, type }) => {
  const isPurchase = type === "purchase";

  return (
    <Grid container columnSpacing={"8px"}>
      <Grid item xs={6} mt={"16px"}>
        <Paper
          sx={{ padding: "8px 16px", backgroundColor: "#fff", color: "#000" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Typography variant="h6" component="div">
                  <strong>{isPurchase ? "Proveedor" : "Cliente"}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          sx={{ width: "98%", margin: "auto" }}
          rowSpacing={"8px"}
        >
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              <strong>
                {isPurchase
                  ? data?.supplier?.business_name
                  : data?.client?.business_name}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h7" component="div">
              <strong>
                {isPurchase ? data?.supplier?.rfc : data?.client?.rfc}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <LocalPhoneIcon sx={{ paddingRight: "8px" }} />
            <Typography variant="subtitle1" component="div">
              {isPurchase ? data?.supplier?.phone : data?.client?.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <EmailIcon sx={{ paddingRight: "8px" }} />
            <Typography variant="subtitle1" component="div">
              {isPurchase ? data?.supplier?.email : data?.client?.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} mt={"16px"}>
        <Paper
          sx={{ padding: "8px 16px", backgroundColor: "#fff", color: "#000" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Typography variant="h6" component="div">
                  <strong>Condiciones de pago</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          sx={{ width: "98%", margin: "auto" }}
          rowSpacing={"8px"}
        >
          <Grid
            item
            xs={7}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle1" component="div">
              Días de crédito:
            </Typography>
            <Typography variant="subtitle1" component="div">
              {data?.credit_days}
            </Typography>
          </Grid>

          <Grid
            item
            xs={7}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle1" component="div">
              límite de crédito:
            </Typography>
            <Typography variant="subtitle1" component="div">
              $
              {isPurchase
                ? data?.supplier?.credit_limit
                : data?.client?.credit_limit}
            </Typography>
          </Grid>

          <Grid
            item
            xs={7}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle1" component="div">
              Descuento:
            </Typography>
            <Typography variant="subtitle1" component="div">
              {data?.discount}%
            </Typography>
          </Grid>

          <Grid
            item
            xs={7}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle1" component="div">
              IVA:
            </Typography>
            <Typography variant="subtitle1" component="div">
              {data?.iva}%
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={"16px"} mb="32px">
        <Paper
          sx={{ padding: "8px 16px", backgroundColor: "#fff", color: "#000" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Typography variant="h6" component="div">
                  <strong>Artículos</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid container mt="16px">
          <Grid item xs={12}>
            {data?.articles && (
              <WhiteTable
                titles={
                  isPurchase
                    ? [
                        "Nombre / # parte ",
                        "Modelo / Presentación",
                        "Cantidad",
                        "Costo",
                        "Subtotal",
                      ]
                    : [
                        "Nombre / # parte ",
                        "Modelo / Presentación",
                        "Cantidad",
                        "Precio",
                        "Subtotal",
                      ]
                }
                sx={{ backgroundColor: "#fff" }}
              >
                {data?.articles.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    hover
                  >
                    <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                      <CardHeader
                        sx={{ m: 0, p: 0, color: "#000" }}
                        title={
                          <Typography
                            component="div"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data?.model?.product?.name}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            component="div"
                            variant="subtitile2"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {" "}
                            {data?.model?.product?.sku}
                          </Typography>
                        }
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                      <CardHeader
                        sx={{ m: 0, p: 0, color: "#000" }}
                        title={
                          <Typography
                            component="div"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data?.model?.name}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            component="div"
                            variant="subtitile2"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {" "}
                            {data?.presentation?.name}
                          </Typography>
                        }
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                      {data?.quantity}
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                      $
                      {isPurchase
                        ? formatNumber(data?.cost, 3)
                        : formatNumber(data?.price, 3)}
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: "2000px" }}>
                      ${formatNumber(data?.sub_total, 0)}
                    </TableCell>
                  </TableRow>
                ))}
              </WhiteTable>
            )}
          </Grid>

          <Grid item xs={12} mt="16px">
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h6" component="div">
                  Subtotal:
                </Typography>
                <Typography variant="h6" component="div">
                  ${formatNumber(data?.sub_total, 2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h6" component="div">
                  IVA:
                </Typography>
                <Typography variant="h6" component="div">
                  $0.0
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h6" component="div">
                  Total:
                </Typography>
                <Typography variant="h6" component="div">
                  ${formatNumber(data?.total, 2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BodyStoreOrder = ({ data }) => {
  return (
    <Grid container>
      {data?.cart?.cart_items && (
        <WhiteTable
          titles={
            data.status === 3
              ? [
                  "Cantidad",
                  "Articulo / modelo o presentacion",
                  "Inventario Disponible",
                  "Precio",
                  "Importe",
                  "Seleccionar sucursal",
                ]
              : data.status === 4
              ? [
                  "Estado de envió",
                  "Cantidad",
                  "Articulo / modelo o presentacion",
                  "Inventario Disponible",
                  "Precio",
                  "Importe",
                ]
              : [
                  "Imagen",
                  "Artículo / Modelo y presentación",
                  "Cantidad",
                  /*  "Inventario Disponible", */
                  "Precio",
                  "Importe",
                  "Sucursal de envió",
                ]
          }
        >
          {data?.cart?.cart_items.map((data, index) => {
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell
                  align="left"
                  sx={{
                    minWidth: "20px",
                    maxWidth: "15vw",
                    paddingLeft: "30px",
                  }}
                >
                  <img
                    src={data?.model?.image?.url}
                    alt={data?.model?.name}
                    style={{ maxWidth: "45px" }}
                  />
                </TableCell>

                <TableCell sx={{ maxWidth: "250px" }} align="left">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <CardHeader
                      sx={{ maring: 0, padding: 0 }}
                      title={
                        <Typography variant="subtitle2" component="div">
                          {data?.model?.product?.name}
                          {/* {data.available?.model?.product?.name} */}
                        </Typography>
                      }
                      subheader={
                        <Typography variant="subtitle2" component="div">
                          {data?.model?.name}, {data.presentation.name}
                          {/* {data.available?.model?.name}, {data.presentation.name}    */}
                        </Typography>
                      }
                    />
                    {/* {data.available?.model.product?.name}  / {data.available?.model?.name}, {data.presentation} */}
                  </Typography>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{
                    minWidth: "20px",
                    maxWidth: "15vw",
                    paddingLeft: "30px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data?.quantity}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formatMoney(data.presentation.price)}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formatMoney(data?.total)}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data?.inventory_transaction?.available[0]?.warehouse?.name}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </WhiteTable>
      )}

      <Grid container justifyContent="right" textAlign="right" marginTop="30px">
        <Grid item xs={2} color="gray">
          <Typography>Subtotal:</Typography>
          <Typography marginTop="10px"> Descuento:</Typography>
          <Typography marginTop="10px">Envio:</Typography>
          <Typography marginTop="10px" fontWeight="bold">
            Total:
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{formatMoney(data.sub_total)}</Typography>
          <Typography marginTop="10px">{data.discount}%</Typography>
          <Typography marginTop="10px">
            {" "}
            {formatMoney(data.shipping_cost)}
          </Typography>
          <Typography marginTop="10px">{formatMoney(data.total)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BodyOrder;
