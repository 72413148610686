import { BASE_PATH, BASE_PATH_DASH } from "../../utils/constants";
import { BaseService } from "base-service-fran-dev";
import axios from "axios";

export class DomainList extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH_DASH;
    const endPoint = "business/feature/allowed";
    super(baseUrl, endPoint);
  }
}

export async function DomainList2(endPoint, param) {
  try {
    var url = `${BASE_PATH_DASH}/${endPoint}`;
    url = url + "?";
    Object.values(param).forEach((element, index) => {
      url += element.key + "=" + element.value;
      if (index + 1 < param.length) {
        url += "&";
      }
    });
    const params = {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);
    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function DomainList3() {
  /*  const {auth } = useAuth(); */
  /* const token = auth.token; */

  const URL = `https://epic-archimedes.74-208-27-206.plesk.page/api/v1/business/feature/allowed?domain=test.74-208-117-143.plesk.page`;
  const res = axios({
    method: "GET",
    url: URL,
    headers: {
      "Content-Type": "application/json",
    },
  }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }
    console.error(error.config);
  });
  return res.json();
}
