import { BASE_PATH } from "../utils/constants";
import { BaseService } from "base-service-fran-dev";
import axios from "axios";
export default class GetUserList extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "auth/user_is_staff";
    super(baseUrl, endPoint);
  }
}

export class GetcustomersList extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "auth/user_is_not_staff";
    super(baseUrl, endPoint);
  }
}

export async function GetAllWhtParams234(endPoint, param) {
  try {
    const url = `${BASE_PATH}/endPoint`;
    param = url + "?";
    param.forEach((element, index) => {
      url += element.key + "=" + element.value;
      if (index + 1 < param.length) {
        url += "&";
      }
    });
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, params);
    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    return null;
  }
}

export function GetUsersTest4(token) {
  const res = axios
    .get(`${BASE_PATH}/auth/user_is_staff`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `jwt ${token}`,
      },
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
      }
      console.error(error.config);
    });
}

export async function GetUsersTest3(token, param) {
  try {
    const url = `${BASE_PATH}/auth/user_is_staff`;

    param.array.forEach((element) => {});
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `jwt ${token}`,
      },
    };
    const response = await fetch(url, params);
    const jsonData = await response.json();

    return jsonData;
  } catch (error) {
    console.error(error);
    return null;
  }
}
