import React, { useEffect, useState } from "react";
import HeaderGeneric from "../../../components/inventario/HeaderGeneric";
import Container from "../../../layout/gridContainer";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import PurchaseService from "../../../api/purchaseService";
import { Grid, Box } from "@mui/material";
import InventoryGenericDetailsView from "../../../components/inventario/InventoryGenericDetailsView";
import ArticlesWithoutTotal from "../../../components/inventario/tables/ArticlesWithoutTotal";
import PurchaseRecieved from "./purchaseRecieved";
import { STATUS, STATUSCOLOR } from "../../../utils/constants";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";

const InventoryWHPurchaseDetail = ({ setReload }) => {
  const { id } = useParams();
  const { logout } = useAuth();
  const [data, setData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const [modalInfo, setModalInfo] = useState("");

  const GetPurchaseDetail = () => {
    (async () => {
      let getPurchase = new PurchaseService();
      let response = await getPurchase.getAllByIdAuth(id, logout);

      setData(response.data);
    })();
  };
  useEffect(() => {
    GetPurchaseDetail();
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 5);
    };
  }, []);

  return (
    <>
      {data && (
        <Container>
          <Box sx={{ p: "20px", mb: "15px" }}>
            <Grid container>
              <Grid item xs={12} mb={3}>
                <HeaderGeneric
                  leftTitle={{
                    status: STATUS[data.status],
                    color: STATUSCOLOR[data.status],
                  }}
                  rightTitle={data.type == 1 ? "Orden de Compra" : "Cotización"}
                  name={`${data.user.first_name} ${data.user.last_name}`}
                  noOrder={data}
                  date={data.date_created}
                  general={data.warehouse.name}
                  type={'purchases'}
                />
              </Grid>
              <Grid item xs={12}>
                {modalInfo && (
                  <PurchaseRecieved
                    openDrawer={openDrawer}
                    toggleDrawer={() => { }}
                    close={() => setOpenDrawer({ right: false })}
                    data={modalInfo}
                    GetPurchaseDetail={GetPurchaseDetail}
                    article={data}
                  />
                )}
                <InventoryGenericDetailsView
                  name="Proveedor"
                  pay="Condiciones de Pago"
                  data={data}
                  type={"supplier"}
                >
                  {data && (
                    <ArticlesWithoutTotal
                      data={data}
                      setModalInfo={setModalInfo}
                      setOpenModal={() => setOpenDrawer({ right: true })}
                    ></ArticlesWithoutTotal>
                  )}
                  {!data && (
                    <GenericEmptyTable msg={"No hay datos en la tabla"} />
                  )}
                </InventoryGenericDetailsView>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
};

export default InventoryWHPurchaseDetail;
